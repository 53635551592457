import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  Tooltip,
  Paper,
  Checkbox,
  CardHeader,
  Avatar,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  darken,
  lighten,
  styled,
  Select,
  MenuItem,
  Pagination,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Slide,
  Radio,
  FormControlLabel,
} from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Tabs1 from '@mui/material/Tabs'
import Tab1 from '@mui/material/Tab'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { enqueueSnackbar } from 'notistack'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CardActionArea } from '@mui/material'
import SelectContentNew from './SelectContentNew'
import TinyMCEWidgetLearning from './TinyMCEWidget'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
/* import DeleteIcon from '@mui/icons-material/Delete' */
import SelectQuestion from './SelectQuestion'
import { red } from '@mui/material/colors'
import EmojiPicker, { EmojiStyle, Emoji } from 'emoji-picker-react'
import ReactPlayer from 'react-player'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import ListIcon from '@mui/icons-material/List'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { TransitionProps } from '@mui/material/transitions'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { Accordion } from 'react-bootstrap'
import Switch, { SwitchProps } from '@mui/material/Switch'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import './categorycss.css'

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}))

const AccordionMui = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  /* border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  }, */
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ height: 20 }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  /*  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)', */
  /*   flexDirection: 'row-reverse', */
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  /*   borderTop: '1px solid rgba(0, 0, 0, .125)', */
}))

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

function CreateActivity(props: any) {
  const {
    createActivityModal,
    setCreateActivityModal,
    selectedActivity,
    setFirst,
    first,
    editActivity,
    setSelectedActivity,
  } = props
  const LoginState = useSelector((state: any) => state.Login)

  const [activityName, setActivityName] = useState('')

  //#region activity type
  const [activityType, setActivityType] = useState(0)
  const [activityTypeHover, setActivityTypeHover] = useState(0)
  const [explanationArea, setExplanationArea] = useState("")
  function handleTypeHoverActivityName(item) {
    switch (item) {
      case 1:
        return "Learning Card"

      case 2:
        return "Deep Learn"

      case 3:
        return "Tenten"

      case 4:
        return "Recall"

      case 5:
        return "Peak"
      case 6:
        return "Quiz"
      case 7:
        return "Exam"
      default:
        return null
    }
  }
  useEffect(() => {
    if (activityTypeHover >= 0) {
      switch (activityTypeHover) {
        case 1:
          setExplanationArea("It is a short content which may contain training materials like text, picture, video and question. You can add as many pages as you wish into learning cards. Each learning card must have at least one question for validation of work. Learning Cards are a crutial part of microlearning.")
          break;
        case 2:
          setExplanationArea("Q&A game used for teaching the topic, deeply. You can select as many categories as you wish. You define the number of questions to be asked for each category. Questions are prepared randomly for each user. The order of the questions is also randomised. The answer options and their orders are randomised, too. Every user gets different question sets consisting of different options. Questions must be answered within their own time limit. After each answer, users are informed about their mistakes. If the user clicks the wrong answer, Mobixa displays the correct option, teaching the right information.")
          break;
        case 3:
          setExplanationArea("Short competition game. Just 10 questions to be asked from the selected category. Questions are prepared randomly for each user. The order of the questions is also randomised. The answer options and their orders are randomised, too. Every user gets different question sets consisting of different options. Questions must be answered within their own time limit. After each answer, users are informed about their mistakes. If the user clicks the wrong answer, Mobixa displays the correct option, teaching the right information.")
          break;
        case 4:
          setExplanationArea("Mid-sized competition game. 25 questions to be asked from the selected category. Questions are prepared randomly for each user. The order of the questions is also randomised. The answer options and their orders are randomised, too. Every user gets different question sets consisting of different options. Questions must be answered within their own time limit. After each answer, users are informed about their mistakes. If the user clicks the wrong answer, Mobixa displays the correct option, teaching the right information.")
          break;
        case 5:
          setExplanationArea(`Elimination game. Just one mistake and the user will be eliminated! The game continues until one user left. The target is to climb top of the leaderboard.
          The questions, question order and answer options are the same for all participants. Questions must be answered within their own time limit. 
          Number of questions to be asked is limited to the selected categories’ question capacity.`)
          break;
        case 6:
          setExplanationArea(`Preperation activity for a serious exam. You will decide the nr. of questions to be asked. You also select max of 10 categories for the quiz. For each user, the question generator selects random questions from the selected categories and limited with selected number of questions. The answer options and their orders are randomised. Every user gets different question sets consisting of different options. There is no feedback for the answers as this is a test activity.`)
          break;
        case 7:
          setExplanationArea("Serious exam. You will select each question one by one. You can use max 10 categories in an exam.  Each user answers the same question set but in different order. The answer options are also the same for all the participants. There is no feedback oportunity for the answers as this is a test activity.")
          break;
        default:
          setExplanationArea("")
          break;
      }
    }
  }, [activityTypeHover])

  //#endregion
  //#region Stepper start
  const [step, setStep] = useState(0)
  const [stepGame, setStepGame] = useState(0)
  const [stepQuiz, setStepQuiz] = useState(0)
  const [stepExam, setStepExam] = useState(0)
  const steps = ['Name', 'Category', 'Content', 'Preview']
  const stepsGame = ['Name', 'Category', 'Icon', 'Preview']
  const stepsQuiz = ['Name', 'Category', 'Duration', 'Preview']
  //#endregion Stepper end
  //#region category start
  const [loadingCategories, setLoadingCategories] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [customerCategory, setCustomerCategory] = useState([])
  const [mobixaCategory, setMobixaCategory] = useState([])
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)

  const [categoryTest, setCategoryTest] = useState<any>([])
  const [hover, setHover] = useState('')
  const [category, setCategory] = useState<any>(0)
  const [categoryNumber, setCategoryNumber] = useState<any>(0)
  const [categoryName, setCategoryName] = useState<any>('')

  const handleChangeTabMenu = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  async function getCategories() {
    setLoadingCategories(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getAccessibleCategories`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then((response: any) => {
        setLoadingCategories(false)
        // expected the setProducts to be filled with the return of this request
        setMobixaCategory(response.data.rootCategory)
        setCustomerCategory(response.data.customerCategory)
      })
      .catch((err) => {
        setLoadingCategories(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  //#endregion category end
  const [discardWarnModal, setDiscardWarnModal] = useState(false)
  const [journeyWarnModal, setJourneyWarnModal] = useState(false)

  useEffect(() => {
    if (journeyWarnModal) {
      getActivity()
    }
  }, [journeyWarnModal])

  const [savedChanges, setSavedChanges] = useState(false)

  //#region LearningCard Content start
  const scrollContainerRef = useRef<any>(null)
  const [addVideoHover, setAddVideoHover] = useState(false)

  const [page, setPage] = useState(0)
  const [learningCard, setLearningCard] = useState<any>([
    {
      question: false,
      mediaType: 0,
      mediaUrl: '',
      description: null,
      takeaway: '',
      questionId: 0,
      htmlDescription: null,
      pageNumber: 1,
      file: false,
    },
  ])

  const [deleteCardHover, setDeleteCardHover] = useState(-1)

  const handleMouseEnter = (index: number) => {
    setDeleteCardHover(index)
  }

  const handleMouseLeave = (index: number) => {
    setDeleteCardHover(index)
  }

  const [cardAdd, setCardAdd] = useState(false)

  const changeDescription = (index: number, value: string) => {
    const newCard = [...learningCard]
    newCard[index].htmlDescription = value
    newCard[index].description = value ? value?.replace(/<[^>]+>/g, '') : null
    setLearningCard(newCard)
  }
  const changeFile = (value: any, index: number, mediaType: number) => {
    const newCard = [...learningCard]
    newCard[index].file = value
    newCard[index].mediaUrl = ''
    newCard[index].mediaType = mediaType
    setLearningCard(newCard)
  }
  const changeMediaUrl = (value: string, index: number, mediaType: number) => {
    const newCard = [...learningCard]
    newCard[index].mediaUrl = value
    newCard[index].mediaType = mediaType
    setLearningCard(newCard)
  }
  const changeTakeaway = (value: string, index: number) => {
    const newCard = [...learningCard]
    newCard[index].takeaway = value
    setLearningCard(newCard)
  }

  const onAddBtnClick = () => {
    setLearningCard([
      ...learningCard,
      {
        question: false,
        mediaType: 0,
        mediaUrl: '',
        description: null,
        takeaway: '',
        questionId: 0,
        htmlDescription: null,
        pageNumber: learningCard.length + 1,
        file: '',
      },
    ])
    setCardAdd(false)
    setPage(learningCard.length - 1)
  }
  const onAddBtnClickQuestion = () => {
    setLearningCard([
      ...learningCard,
      {
        question: true,
        mediaType: 0,
        questionDescription: '',
        questionAnswers: '',
        pageNumber: learningCard.length + 1,
        questionId: 0,
      },
    ])
    setCardAdd(false)
    setPage(learningCard.length - 1)
  }
  const onRemoveBtnClick = (index: number) => {
    if (learningCard.length > 1) {
      const newCard = [...learningCard]
      newCard.splice(index, 1)

      setLearningCard(newCard)

      if (page !== 0) {
        setPage(page - 1)
      }
      if (page === 0 && learningCard.length > 1) {
        setPage(page + 1)
      }
    }
  }
  const [question, setQuestion] = useState<any>(-1)
  const changeQuestion = (question: any, description: string, index: number) => {
    if (question === -1) {
      setQuestionAnswers([])
    }

    const newCard = [...learningCard]
    newCard[index].questionId = question
    newCard[index].questionDescription = description
    setLearningCard(newCard)
    getQuestionAnswers(question, index)
  }

  useEffect(() => {
    // 3. veriyi ekledikten sonra otomatik kaydırma işlemi yapılır
    if (learningCard.length >= 3 && scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current
      scrollContainer.scrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth
    }
  }, [cardAdd])

  const [questionAnswers, setQuestionAnswers] = useState<any>([])

  async function getQuestionAnswers(questionId: number, index: number) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getQuestionById/${questionId}`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response) => {
        const newCard = [...learningCard]
        newCard[index].questionAnswers = response.data.questionAnswers
        setLearningCard(newCard)
      })
      .catch((err) => { })
  }

  //#region LearningCard Content end

  //#region Game Icon Start
  const [iconList, seticonList] = useState<any>([])
  const [selectedIcon, setSelectedIcon] = useState<any>({ mediaName: '', mediaUrl: '' })
  const [searchIcon, setSearchIcon] = useState<any>('')
  const [loadingFilter, setLoadingFilter] = useState<any>(false)
  const [loadingIcons, setLoadingIcons] = useState<any>(false)

  const filterIcons = iconList.filter((item: any) => {
    return item.mediaName.toLowerCase().includes(searchIcon.toLowerCase())
  })

  useEffect(() => {
    if (stepGame === 2) {
      handleGetIcons()
    }
  }, [stepGame])

  async function handleGetIcons() {
    setLoadingIcons(true)
    /* setSelectedIcon({mediaUrl: '', mediaName: ''}) */
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/getMobixaIcons`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response: any) => {
        seticonList(response.data)
        setLoadingIcons(false)
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
        setLoadingIcons(false)
      })
  }
  //#endregion Game Icon end

  //#region test start
  const [selectCategoryDataGrid, setSelectCategoryDataGrid] = useState<number>(0)
  const [timeLimitSuggestion, setTimeLimitSuggestion] = useState<any>(false)
  const [timeSuggestion, setTimeSuggestion] = useState<any>(0)
  const [timeLimitSuggestionHelperText, setTimeLimitSuggestionHelperText] = useState<any>(false)
  const [loadingSuggestion, setLoadingSuggestion] = useState(false)

  const [createTest, setCreateTest] = useState<any>({
    testType: 0,
    title: '',
    categoryList: [],
    timeLimit: 0,
  })
  const [repeatGame, setRepeatGame] = useState<number>(1)
  const [gameCategoryList, setGameCategoryList] = useState<any>({
    categoryList: [],
  })

  const [errorTestName, setErrorTestName] = useState(false)
  const [helperTextTestName, setHelperTextTestName] = useState('')

  useEffect(() => {
    if (stepExam === 2) {
      getSuggestionTime()
    }
    if (stepQuiz === 2) {
      getSuggestionTime()
    }
  }, [stepExam, stepQuiz])

  useEffect(() => {
    if (stepExam === 2 && createTest.categoryList && createTest.categoryList.length > 0) {
      getSuggestionTime()
    }
  }, [stepExam, createTest.categoryList])

  async function getSuggestionTime() {
    if (createTest.categoryList && createTest.categoryList.length > 0) {
      setLoadingSuggestion(true)
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/Game/categoryAvgSuggestionTime`,
          {
            ...createTest,
          },
          {
            headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
          }
        )
        .then((response: any) => {
          // API'den gelen öneriyi her zaman timeSuggestion'a kaydet
          setTimeSuggestion(response.data)
          
          // Eğer mevcut timeLimit 0 ise veya timeLimitSuggestion true ise, önerilen süreyi kullan
          if (createTest.timeLimit === 0 || timeLimitSuggestion) {
            setCreateTest((prevCreateTest: any) => ({
              ...prevCreateTest,
              timeLimit: response.data,
            }))
            // Önerilen süre kullanıldığında, timeLimitSuggestion'ı true yap
            setTimeLimitSuggestion(true)
          } else {
            setCreateTest((prevCreateTest: any) => ({
              ...prevCreateTest,
              timeLimit: createTest.timeLimit,
            }))
            // Aksi takdirde, mevcut değeri koru ve timeLimitSuggestion'ı false yap
            setTimeLimitSuggestion(false)
          }
          
          setLoadingSuggestion(false)
        })
        .catch((err) => {
          err.response?.data?.detail.errorMessage
            ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
            : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
          setLoadingSuggestion(false)
        })
    }
  }

  //#region datagrid modal
  const [dataGridModalOpen, setDataGridModalOpen] = useState(false)
  const [selectQuestionDetails, setSelectQuestionDetails] = useState([])
  const handleAddQuestionDetails = (
    categoryName: string,
    categoryId: number,
    questionDetails: any,
    mediaUrl: string,
    mediaType: number
  ) => {
    if (selectQuestionDetails.find((item: any) => item.categoryId === categoryId)) {
      setSelectQuestionDetails((prevDetails) => {
        // Önce güncellenmek istenen kategoriyi bulalım
        const updatedCategories = prevDetails.map((category) => {
          if (category.categoryId === categoryId) {
            return {
              ...category,
              categoryName: categoryName,
              questionDetails: questionDetails, // Yeni güncellenmiş soru detayları
              mediaUrl: mediaUrl,
              mediaType: mediaType,
            }
          }
          return category
        })

        return updatedCategories
      })
    } else {
      const filteredCategories = selectQuestionDetails.filter(
        (category: any) => category.categoryId !== 0
      )

      setSelectQuestionDetails(filteredCategories)
      setSelectQuestionDetails((prevDetails: any) => [
        ...prevDetails,
        {
          categoryName: categoryName,
          categoryId: categoryId,
          questionDetails: questionDetails,
        },
      ])
    }
  }
  const handleDeleteQuestionDetails = (categoryId: number) => {
    setSelectQuestionDetails((prevDetails) =>
      prevDetails.filter((category) => category.categoryId !== categoryId)
    )
  }

  const handleOpenDataGridModal = () => {
    setDataGridModalOpen(true)
  }
  const handleCloseDataGridModal = () => {
    setDataGridModalOpen(false)
  }
  //#endregion

  const handleDeleteCategory = (categoryId: number) => {
    handleDeleteQuestionDetails(categoryId)
    setCreateTest((prevCreateTest: any) => {
      const updatedCategoryList = prevCreateTest.categoryList.filter(
        (category: any) => category.categoryId !== categoryId
      )
      return {
        ...prevCreateTest,
        categoryList: updatedCategoryList,
      }
    })
  }
  const handleDeleteCategoryGame = (categoryId: number) => {
    console.log("categoryId : ",categoryId)
    setGameCategoryList((prevCreateTest: any) => {
      const updatedCategoryList = prevCreateTest.categoryList.filter(
        (category: any) => category.categoryId !== categoryId
      )
      return {
        ...prevCreateTest,
        categoryList: updatedCategoryList,
      }
    })
  }
  console.log(gameCategoryList)
  const handleAddCategoryGame = (categoryId: number, categoryName: string, questionCount: number) => {
    const newCategory = {
      categoryId: categoryId,
      categoryName: categoryName,
      /*  questionIdList: [], */
      selectedQuestionCount: 1,
      questionCount: questionCount,
    }

    setGameCategoryList((prevCreateTest: any) => ({
      ...prevCreateTest,
      categoryList: [...prevCreateTest.categoryList, newCategory],
    }))
  }
  const handleAddCategory = (categoryId: number, categoryName: string, questionCount: number) => {
    const newCategory = {
      categoryId: categoryId,
      categoryName: categoryName,
      questionIdList: [],
      selectedQuestionCount: 1,
      questionCount: questionCount,
    }

    setCreateTest((prevCreateTest: any) => ({
      ...prevCreateTest,
      categoryList: [...prevCreateTest.categoryList, newCategory],
    }))
  }

  const handleAddQuestion = (categoryId: number, selectedQuestion: any) => {
    setCreateTest((prevCreateTest: any) => ({
      ...prevCreateTest,
      categoryList: prevCreateTest.categoryList.map((item: any) => {
        if (
          item.categoryId ===
          createTest.categoryList.find((item: any) => item.categoryId === categoryId).categoryId
        ) {
          return {
            ...item,
            // Burada güncellemek istediğiniz alanları belirtin
            questionIdList: selectedQuestion, // Örnek bir alan ve değer
            selectedQuestionCount: selectedQuestion.length,
            // Diğer alanları aynı şekilde güncelleyebilirsiniz
          }
        }
        return item
      }),
    }))
    setSelectedQuestion([])
    /*   setCreateTest(createTest) */
  }
  function getMedia(item: any) {
    if (item.mediaType === 1) {
      if (item.mediaUrl) {
        return (
          <div className='p-1 d-flex text-center align-items-center '>
            <img src={item.mediaUrl} alt='' className='rounded' width='80' height='36' />
            <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
              <div> {item.description}</div>
            </Tooltip>
          </div>
        )
      } else {
        return (
          <div className='p-1 d-flex text-center align-items-center'>
            <img
              src={toAbsoluteUrl('/media/svg/image.svg')}
              alt=''
              className='rounded'
              width='80'
              height='36'
            />
            <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
              <div> {item.description}</div>
            </Tooltip>
          </div>
        )
      }
    } else if (item.mediaType === 4) {
      if (item.mediaUrl) {
        return (
          <div className='p-1 d-flex text-center align-items-center'>
            <Emoji unified={item.mediaUrl} emojiStyle={EmojiStyle.APPLE} size={50} />
            <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
              <div> {item.description}</div>
            </Tooltip>
          </div>
        )
      } else {
        return (
          <div className='p-1 d-flex text-center align-items-center'>
            <img
              src={toAbsoluteUrl('/media/svg/image.svg')}
              alt=''
              className='rounded'
              width='80'
              height='36'
            />
            <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
              <div> {item.description}</div>
            </Tooltip>
          </div>
        )
      }
    } else if (item.mediaType === 2) {
      if (item.mediaUrl) {
        return (
          <div className='p-1  d-flex text-center align-items-center '>
            <div
              style={{ transform: hoverList === item.mediaUrl ? 'scale(1.5)' : 'none' }}
              onMouseEnter={() => setHoverList(item.mediaUrl)}
              onMouseLeave={() => setHoverList(null)}
            >
              <ReactPlayer controls={true} url={item.mediaUrl} width='80' height='36' />
            </div>
            <Tooltip
              arrow
              title={item.description}
              style={{ marginLeft: hoverList === item.mediaUrl ? '15px' : '5px' }}
            >
              <div> {item.description}</div>
            </Tooltip>
          </div>
        )
      } else {
        return (
          <div className='p-1  d-flex text-center align-items-center'>
            <img
              src={toAbsoluteUrl('/media/svg/image.svg')}
              alt=''
              className='rounded'
              width='80'
              height='36'
            />
            <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
              <div> {item.description}</div>
            </Tooltip>
          </div>
        )
      }
    } else {
      return (
        <div className='p-1 d-flex text-center align-items-center'>
          <img
            src={toAbsoluteUrl('/media/svg/image.svg')}
            alt=''
            className='rounded'
            width='80'
            height='36'
          />
          <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
            <div> {item.description}</div>
          </Tooltip>
        </div>
      )
    }
  }

  const handleBlur = () => {
    // Minimum 3 karakter kontrolü
    if (createTest.title.length < 2) {
      setErrorTestName(true)
      setHelperTextTestName('Minimum 2 characters required')
    } else {
      setErrorTestName(false)
      setHelperTextTestName('')
    }
  }
  //#region final test start

  const [finalTestModalOpen, setFinalTestModalOpen] = useState(false)

  //#endregion

  //datagridQuestion Modal start
  const [selectedQuestion, setSelectedQuestion] = useState<any>([])

  const [rows, setRows] = useState<any>([])
  const [rowCount, setRowCount] = useState(0)
  const [loadingExport, setLoadingExport] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [queryOptions, setQueryOptions] = useState<any>({})
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })

  const [hoverList, setHoverList] = useState(null)
  {
    /* <Tooltip title={params.value}>
            <div> {params.value}</div>
          </Tooltip> */
  }

  const columns: any = [
    {
      field: 'description',
      headerName: 'Question',
      width: 450,
      renderCell: (params: any) => {
        const item = params.row
        if (item.mediaType === 1) {
          if (item.mediaUrl) {
            return (
              <div className='p-3 d-flex text-center align-items-center '>
                <img src={item.mediaUrl} alt='' className='rounded' width='50' height='50' />
                <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
                  <div> {item.description}</div>
                </Tooltip>
              </div>
            )
          } else {
            return (
              <div className='p-3 d-flex text-center align-items-center'>
                <img
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt=''
                  className='rounded'
                  width='50'
                  height='50'
                />
                <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
                  <div> {item.description}</div>
                </Tooltip>
              </div>
            )
          }
        } else if (item.mediaType === 4) {
          if (item.mediaUrl) {
            return (
              <div className='p-3 d-flex text-center align-items-center'>
                <Emoji unified={item.mediaUrl} emojiStyle={EmojiStyle.APPLE} size={50} />
                <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
                  <div> {item.description}</div>
                </Tooltip>
              </div>
            )
          } else {
            return (
              <div className='p-3 d-flex text-center align-items-center'>
                <img
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt=''
                  className='rounded'
                  width='50'
                  height='50'
                />
                <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
                  <div> {item.description}</div>
                </Tooltip>
              </div>
            )
          }
        } else if (item.mediaType === 2) {
          if (item.mediaUrl) {
            return (
              <div className='p-3  d-flex text-center align-items-center '>
                <div
                  style={{ transform: hoverList === item.mediaUrl ? 'scale(1.5)' : 'none' }}
                  onMouseEnter={() => setHoverList(item.mediaUrl)}
                  onMouseLeave={() => setHoverList(null)}
                >
                  <ReactPlayer controls={true} url={item.mediaUrl} height={'50px'} width={'50px'} />
                </div>
                <Tooltip
                  arrow
                  title={item.description}
                  style={{ marginLeft: hoverList === item.mediaUrl ? '15px' : '5px' }}
                >
                  <div> {item.description}</div>
                </Tooltip>
              </div>
            )
          } else {
            return (
              <div className='p-3  d-flex text-center align-items-center'>
                <img
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt=''
                  className='rounded'
                  width='50'
                  height='50'
                />
                <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
                  <div> {item.description}</div>
                </Tooltip>
              </div>
            )
          }
        } else {
          return (
            <div className='p-3 d-flex text-center align-items-center'>
              <img
                src={toAbsoluteUrl('/media/svg/image.svg')}
                alt=''
                className='rounded'
                width='50'
                height='50'
              />
              <Tooltip arrow title={item.description} style={{ marginLeft: '5px' }}>
                <div> {item.description}</div>
              </Tooltip>
            </div>
          )
        }
      },
    },
    {
      field: 'categoryId',
      headerName: 'CATEGORY',
      width: 130,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'owner',
      headerName: 'OWNER',
      width: 150,
    },
    {
      field: 'questionId',
      headerName: 'ID',
      width: 80,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value?.toFixed(0),
    },
    {
      field: 'createdDate',
      headerName: 'CREATED DATE',
      width: 150,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
  ]

  async function downloadExcel() {
    setLoadingExport(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/customer/getCustomerList?isExcel=1`,
        {
          queryOptions,
          columnVisibilityModel,
        },
        {
          responseType: 'blob',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const excelUrl = URL.createObjectURL(excelBlob)

        const link = document.createElement('a')
        link.href = excelUrl
        link.download = 'questionList.xlsx'
        link.click()
        URL.revokeObjectURL(excelUrl)
        setLoadingExport(false)
      })
      .catch((err) => {
        setLoadingExport(false)
      })
    //frontend excel download
    /*     const newData = rows.map((row: any) => {
      delete row.tableData
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
    //Buffer
    XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
    //Binary string
    XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
    //Download
    XLSX.writeFile(workBook, 'Veri Listesi.xlsx') */
  }
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
      return
    } */

    setQueryOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    /*    console.log('params: ', params)*/
    const newSelectionModel = [...selectedQuestion];
    const selectionIndex = newSelectionModel.indexOf(params.id);

    if (selectionIndex === -1) {
      // Eğer tıklanan hücrenin ID'si şu anda seçili değilse, seçime ekleyin
      newSelectionModel.push(params.id);
    } else {
      // Eğer tıklanan hücrenin ID'si zaten seçiliyse, seçimden kaldırın
      newSelectionModel.splice(selectionIndex, 1);
    }
    setSelectedQuestion(newSelectionModel)
  }

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectedQuestion(newSelectionModel)
  }

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }

  useEffect(() => {
    if (createActivityModal) {
      if (selectCategoryDataGrid !== 0) {
        getQuestionList()
      }
    }
  }, [queryOptions, paginationModel, columnVisibilityModel, selectCategoryDataGrid])

  async function getQuestionList() {
    setIsLoadingData(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/getQuestionListByCustomerId?page=${paginationModel.page + 1
        }&size=${paginationModel.pageSize}&categoryId=${selectCategoryDataGrid}`,
        {
          queryOptions,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const formattedRows = response.data.items.map((row: any) => ({
          questionId: row.questionId,
          description: row.description,
          owner: row.createdName,
          createdDate: row.createdDate,
          icon: row.mediaUrl,
          mediaType: row.mediaType,
          mediaUrl: row.mediaUrl,
          categoryId: row.categoryId
        }))
        setRows(formattedRows)
        setRowCount(response.data.total)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }


  useEffect(() => {
    if (finalTestModalOpen) {
      getQuestionListForEdit()
    }
  }, [finalTestModalOpen])

  const [loadingFinalTest, setLoadingFinalTest] = useState(false)

  async function getQuestionListForEdit() {
    setLoadingFinalTest(true)
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/Game/getExamQuestions?activityId=${selectedActivity}`,
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const updatedCategories = Object.keys(response.data).map(categoryName => {
          const questions = response.data[categoryName].map(question => {
            return {
              questionId: question.questionId,
              description: question.question,
              mediaUrl: question.mediaUrl, // Medya URL'si
              mediaType: question.mediaType, // Medya tipi
            };
          });

          return {
            categoryName: categoryName,
            questionDetails: questions
          };
        });
        setSelectQuestionDetails(updatedCategories)
        setLoadingFinalTest(false)
      })
      .catch((err) => {
        setLoadingFinalTest(false)
      })
  }

  //keepNonExistentRowsSelected içeriğinı sıfırlamak için kullanılıyor.
  const ReloadableDataGrid = ({ rows, columns }: any) => {
    const [reloadDataGridData, setReloadDataGridData] = useState(false)

    // useEffect içerisinde reloadDataGridData değeri değiştiğinde DataGrid'i unmount ediyoruz.
    useEffect(() => {
      setReloadDataGridData(!reloadDataGridData) // Tekrar unmount edilmemesi için false yapıyoruz.
      // DataGrid'i unmount etmek için ekstra bir işlem gerekmiyor, sadece reloadDataGridData değeri değiştiriyoruz.
    }, [selectCategoryDataGrid])

    return (
      <StyledDataGrid
        rows={rows || []}
        columns={columns || []}
        rowHeight={50}
        checkboxSelection
        keepNonExistentRowsSelected
        disableMultipleRowSelection={false}
        onCellClick={handleCellClick}
        rowSelectionModel={selectedQuestion}
        getRowId={(row) => row.questionId}
        disableRowSelectionOnClick
        sortingMode='server'
        onSortModelChange={handleSortModelChange}
        onRowSelectionModelChange={handleSelectionModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        filterMode='server'
        onFilterModelChange={onFilterChange}
        rowCount={rowCount}
        loading={isLoadingData}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode='server'
        pagination={true}
        getRowClassName={(params) =>
          `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
        }
        slots={{
          /*   toolbar: CustomToolbar, */
          pagination: CustomPagination,
        }}
        sx={{
          boxShadow: 3,
          '& .MuiDataGrid-cell:hover': {
            cursor: 'pointer',
          },
          '&	.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            color: '#A1A5B7',
            fontSize: 17,
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
      />
    )
  }
  //datagridQuestion Modal end
  /* console.log("categoryNumber: ", categoryNumber)
  console.log("category: ", category)
  console.log("mobixacategory : ", mobixaCategory)
  console.log("customerCategory: ", customerCategory) */
  useEffect(() => {
    if (0) {
      /*   setCategoryNumber */
    }
  }, [categoryNumber])

  //#endregion test end
  const [usedJourney, setUsedJourney] = useState(false)
  const [activityTypeName, setactivityTypeName] = useState('')
  useEffect(() => {
    if (usedJourney === false) {
      if (activityType === 0) {
        setactivityTypeName('')
      }
      if (activityType === 1) {
        setactivityTypeName('Learning Card')
        if (editActivity !== 0) {
          /* setStep(3) */
          setStep(0)
        }
      }
      if (activityType === 2) {
        setactivityTypeName('DeepLearn')
        if (editActivity !== 0) {
          /*  setStepGame(3) */
          setStepGame(0)
        }
      }
      if (activityType === 3) {
        setactivityTypeName('TenTen')
        if (editActivity !== 0) {
          /*   setStepGame(3) */
          setStepGame(0)
        }
      }
      if (activityType === 4) {
        setactivityTypeName('Recall')
        if (editActivity !== 0) {
          /* setStepGame(3) */
          setStepGame(0)
        }
      }
      if (activityType === 5) {
        setactivityTypeName('Peak')
        if (editActivity !== 0) {
          /* setStepGame(3) */
          setStepGame(0)
        }
      }
      if (activityType === 6) {
        setactivityTypeName('Quiz')
        setCreateTest({
          testType: 0,
          title: '',
          categoryList: [],
          timeLimit: 0,
        })
        if (editActivity !== 0) {
          /*    setStepQuiz(3) */
          setStepQuiz(0)
        }
      }
      if (activityType === 7) {
        setactivityTypeName('Exam')
        setCreateTest({
          testType: 1,
          title: '',
          categoryList: [],
          timeLimit: 0,
        })
        if (editActivity !== 0) {
          /*  setStepExam(3) */
          setStepExam(0)
        }
      }
    } else {
      if (activityType === 0) {
        setactivityTypeName('')
      }
      if (activityType === 1) {
        setactivityTypeName('Learning Card')
        if (editActivity !== 0) {
          /* setStep(3) */
          setStep(0)
          setJourneyWarnModal(true)
        }
      }
      if (activityType === 2) {
        setactivityTypeName('DeepLearn')
        if (editActivity !== 0) {
          setStepGame(3)
          setJourneyWarnModal(true)
          /* setStepGame(0) */
        }
      }
      if (activityType === 3) {
        setactivityTypeName('TenTen')
        if (editActivity !== 0) {
          setStepGame(3)
          setJourneyWarnModal(true)
          /* setStepGame(0) */
        }
      }
      if (activityType === 4) {
        setactivityTypeName('Recall')
        if (editActivity !== 0) {
          setStepGame(3)
          setJourneyWarnModal(true)
          /*  setStepGame(0) */
        }
      }
      if (activityType === 5) {
        setactivityTypeName('Peak')
        if (editActivity !== 0) {
          setJourneyWarnModal(true)
          setStepGame(3)
          /*  setStepGame(0) */
        }
      }
      if (activityType === 6) {
        setactivityTypeName('Quiz')
        setCreateTest({
          testType: 0,
          title: '',
          categoryList: [],
          timeLimit: 0,
        })
        if (editActivity !== 0) {
          setStepQuiz(3)
          setJourneyWarnModal(true)
          /* setStepQuiz(0) */
        }
      }
      if (activityType === 7) {
        setactivityTypeName('Exam')
        setCreateTest({
          testType: 1,
          title: '',
          categoryList: [],
          timeLimit: 0,
        })
        getActivity()
        if (editActivity !== 0) {
          setStepExam(2)
         // setJourneyWarnModal(true)
          /*  setStepExam(0) */
        }
      }
    }

  }, [activityType, usedJourney])

  useEffect(() => {
    if (step === 1) {
      getCategories()
    }
    if (stepGame === 1) {
      getCategories()
    }
    if (stepQuiz === 1) {
      getCategories()
    }
    if (stepExam === 1) {
      getCategories()
    }
  }, [step, stepGame, stepQuiz, stepExam])

  const hasContentPage = () => {
    return learningCard.some(
      (card) => !card.question && card.takeaway && (card.mediaUrl || card.htmlDescription)
    )
  }

  // En az bir soru sayfası olup olmadığını kontrol eden fonksiyon
  const hasQuestionPage = () => {
    return learningCard.some((card) => card.question && card.questionId)
  }

  function getActivityTypeAndSubType(activityType) {
    let type = null
    let subType = null

    switch (activityType) {
      case 1: // Learning Card
        type = 1
        subType = 0
        break
      case 2: // DeepLearn
        type = 1
        subType = 10
        break
      case 3: // TenTen
        type = 1
        subType = 11
        break
      case 4: // Recall
        type = 1
        subType = 12
        break
      case 5: // Peak
        type = 1
        subType = 13
        break
      case 6: // Quiz
        type = 2
        subType = 0
        break
      case 7: // Exam
        type = 2
        subType = 1
        break
      default:
        // varsayılan veya tanımlanmamış bir değer için
        type = null
        subType = null
    }

    return { type, subType }
  }
  const [timer, setTimer] = useState<any>(null)
  useEffect(() => {
    // Komponent yok edildiğinde zamanlayıcıyı temizle
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [timer])

  const [nameCheck, setNameCheck] = useState(false)
  const [nameCheckLoading, setNameCheckLoading] = useState(false)
  function checkActivityName(title: any, activityType: any) {
    const { type, subType } = getActivityTypeAndSubType(activityType)

    setNameCheckLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/activityNameCheck`,
        {
          title: title,
          activityTypeId: type,
          activitySubTypeId: subType,
        },
        {
          headers: {
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        }
      )
      .then(async function (response: any) {
        /*  enqueueSnackbar(`name successfully`, {
          variant: 'success',
        }) */
        setNameCheck(false)
        setNameCheckLoading(false)
      })
      .catch((err) => {
        setNameCheck(true)
        setNameCheckLoading(false)
        err.response?.data?.detail.errorMessage
          ? err.response?.data?.detail.errorMessage === 'ACTIVITY_NAME_IS_ALREADY'
            ? enqueueSnackbar(`Name exists. Choose another one`, {
              variant: 'error',
            })
            : enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  async function handleSaveLearningCard() {
    const form = new FormData()
    const learningCardAsString = JSON.stringify(learningCard)
    form.append('pages', `${learningCardAsString}`)
    learningCard.map((item: any, index: number) => {
      if (item.file)
        if (item.file === '') {
          return
        } else {
          form.append('files', item.file, `${index + 1}.png`)
        }
    })
    form.append('title', activityName)
    form.append('categoryId', category)

    /*    for (let [key, value] of form.entries()) {
      console.log('formname: ', key, 'data:', value)
    } */
    axios
      .post(`${process.env.REACT_APP_API_URL}/Game/createLearningCard`, form, {
        headers: {
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async function (response: any) {
        enqueueSnackbar(`Saved successfully`, {
          variant: 'success',
        })
        setFirst(!first)
        handleClose()
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? err.response?.data?.detail.errorMessage === 'ACTIVITY_NAME_IS_ALREADY'
            ? enqueueSnackbar(`Name exists. Choose another one`, {
              variant: 'error',
            })
            : enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  function handleSaveEditLearningCard() {
    const form = new FormData()
    const learningCardAsString = JSON.stringify(learningCard)
    form.append('pages', `${learningCardAsString}`)
    learningCard.map((item: any, index: number) => {
      if (item.file)
        if (item.file === '') {
          return
        } else {
          form.append('files', item.file, `${index + 1}.png`)
        }
    })
    form.append('activityId', selectedActivity)
    form.append('title', activityName)
    form.append('categoryId', category)
    axios
      .post(`${process.env.REACT_APP_API_URL}/Game/editLearningCard`, form, {
        headers: {
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async function (response: any) {
        enqueueSnackbar(`Saved successfully`, {
          variant: 'success',
        })
        setFirst(!first)
        handleClose()
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? err.response?.data?.detail.errorMessage === 'ACTIVITY_NAME_IS_ALREADY'
            ? enqueueSnackbar(`Name exists. Choose another one`, {
              variant: 'error',
            })
            : enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  useEffect(() => {
    if (selectedActivity !== 0) {
      getActivity()
    }
  }, [selectedActivity, createActivityModal])

  const [editCategory, setEditCategory] = useState<any>(null)

  async function getActivity() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/getActivity/${selectedActivity}`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response: any) => {
        if (response.data?.usedJourney) {
          setUsedJourney(response.data.usedJourney)
        }
        if (response.data?.categoryQuestionCount) {
          setCategoryNumber(response.data.categoryQuestionCount)
        }
        if (response.data.learningCardTitle) {
          setActivityName(response.data.learningCardTitle)
        }
        if (response.data.learningCardCategoryName) {
          setCategoryName(response.data.learningCardCategoryName)
        }
        if (response.data.learningCardCategoryId) {
          setCategory(response.data.learningCardCategoryId)
          setEditCategory(response.data.learningCardCategoryId)
        }
        if (response.data.gameTitle) {
          setActivityName(response.data.gameTitle)
        }
        if (response.data.gameCategoryName) {
          setCategoryName(response.data.gameCategoryName)
        }
        if (response.data.gameCategoryId) {
          setCategory(response.data.gameCategoryId)
        }
        if (response.data.gameIcon) {
          setSelectedIcon({
            mediaName: response.data.gameIcon.iconName,
            mediaUrl: response.data.gameIcon.iconUrl,
          })
        }
        if (response.data.gameCategoryList) {
          setGameCategoryList((prevCreateTest: any) => ({
            categoryList: response.data.gameCategoryList,
          }));
        }
        if (response.data.test) {
          setCreateTest(response.data.test)
          setTimeSuggestion(response.data.test.timeLimit)
          setUsedJourney(response.data.test.usedJourney)
        }
        if (response.data.learningCardPages) {
          const updatedLearningCardPages = response.data.learningCardPages?.map((page: any) => {
            // İlgili learningCard öğesini learningCard verisinden al
            if (page.questionId === null || page.questionId === 0) {
              return {
                ...page,
                question: false,
                mediaType: page.mediaType,
                mediaUrl: page.mediaUrl,
                description: page.description,
                learningCardId: page.learningCardId,
                takeaway: page.takeaway,
                questionId: page.questionId,
                htmlDescription: page.htmlDescription,
                pageNumber: page.pageNumber,
                file: false,
              }
            } else {
              return {
                ...page,
                question: true,
                questionDescription: page.questionDescription,
                questionAnswers: page.questionAnswers,
                learningCardId: page.learningCardId,
                mediaType: 0,
                pageNumber: page.pageNumber,
                questionId: page.questionId,
              }
            }
          })
          setLearningCard(updatedLearningCardPages)
        }

      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }
  function handleClearLearningCard() {
    setLearningCard([
      {
        question: false,
        mediaType: 0,
        mediaUrl: '',
        description: '',
        takeaway: '',
        questionId: 0,
        htmlDescription: null,
        pageNumber: 1,
        file: false,
      },
    ])
    setTabValue(0)
    setStep(0)
    setCategoryName('')
    setCategory(0)
    setActivityName('')
    setActivityType(0)
    setNameCheck(false)
    setNameCheckLoading(false)
    setCreateActivityModal(false)
  }
  function handleClearGame() {
    setTabValue(0)
    setStepGame(0)
    setCategoryName('')
    setGameCategoryList({
      categoryList: []
    })
    setRepeatGame(1)
    setSelectedIcon('')
    setActivityName('')
    setCategory(0)
    setActivityType(0)
    setNameCheck(false)
    setNameCheckLoading(false)
    setCreateActivityModal(false)
  }
  function handleClearTest() {
    setTabValue(0)
    setCreateTest({
      testType: 0,
      title: '',
      categoryList: [],
      timeLimit: 0,
    })
    setUsedJourney(false)
    setStepExam(0)
    setStepQuiz(0)
    setActivityType(0)
    setNameCheck(false)
    setNameCheckLoading(false)
    setErrorTestName(false)
    setHelperTextTestName('')
    setCreateActivityModal(false)
    setSelectQuestionDetails([
      {
        categoryName: '',
        categoryId: 0,
        questionDetails: [],
        mediaUrl: '',
        mediaType: 0,
      },
    ])
  }
  console.log("used : ",usedJourney)
  const [gameLoading, setGameLoading] = useState(false)
  function handleSaveGame() {
    let gameTypeId
    switch (activityTypeName) {
      case 'DeepLearn':
        gameTypeId = 10
        break
      case 'TenTen':
        gameTypeId = 11
        break
      case 'Recall':
        gameTypeId = 12
        break
      case 'Peak':
        gameTypeId = 13
        break
    }
    setGameLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/createGame`,
        // '{\n  "gameType": 0,\n  "activityId": 0\n}',
        {
          gameType: gameTypeId,
          categoryId: category,
          title: activityName,
          icon: selectedIcon.mediaUrl,
          categoryList: gameCategoryList.categoryList,
          /*  repeat: repeatGame */
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async function (response: any) {
        setFirst(!first)
        enqueueSnackbar(`Saved successfully`, {
          variant: 'success',
        })
        setGameLoading(false)
        handleClose()
      })
      .catch((err) => {
        setGameLoading(false)
        err.response?.data?.detail.errorMessage
          ? err.response?.data?.detail.errorMessage === 'ACTIVITY_NAME_IS_ALREADY'
            ? enqueueSnackbar(`Name exists. Choose another one`, {
              variant: 'error',
            })
            : enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }
  function handleSaveEditGame() {
    let gameTypeId
    switch (activityTypeName) {
      case 'DeepLearn':
        gameTypeId = 10
        break
      case 'TenTen':
        gameTypeId = 11
        break
      case 'Recall':
        gameTypeId = 12
        break
      case 'Peak':
        gameTypeId = 13
        break
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/editGame/${selectedActivity}`,
        // '{\n  "activityId": 59,\n  "title": "test deneme",\n  "icon": "string",\n  "categoryId": 10,\n  "statusId": 1\n}',
        {
          gameType: gameTypeId,
          categoryId: category,
          title: activityName,
          icon: selectedIcon.mediaUrl,
          categoryList: gameCategoryList.categoryList,
          /*  repeat: repeatGame */
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        }
      )
      .then(async function (response: any) {
        enqueueSnackbar(`Saved successfully`, {
          variant: 'success',
        })
        setFirst(!first)
        handleClose()
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? err.response?.data?.detail.errorMessage === 'ACTIVITY_NAME_IS_ALREADY'
            ? enqueueSnackbar(`Name exists. Choose another one`, {
              variant: 'error',
            })
            : enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  const [loadingSave, setLoadingSave] = useState(false)

  async function handleSaveCreateTest() {
    setLoadingSave(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/createTest`,
        {
          ...createTest,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response: any) => {
        enqueueSnackbar(`Saved successfully  `, { variant: 'success' })

        setLoadingSave(false)
        setFirst(!first)
        handleClose()
      })
      .catch((err) => {
        if (err.response?.data?.detail.errorMessage) {
          err.response?.data?.detail.errorMessage === 'ACTIVITY_NAME_IS_ALREADY'
            ? enqueueSnackbar(`Name exists. Choose another one`, {
              variant: 'error',
            })
            : enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
        } else if (err.response !== undefined) {
          enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
        }
        setLoadingSave(false)
      })
  }
  const [duplicateLoading, setDuplicateLoading] = useState(false)
  const HandleSaveDublicate = () => {
    setDuplicateLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/Game/duplicateActivity/${selectedActivity}`, '', {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        setDuplicateLoading(false)
        setJourneyWarnModal(false)
        setTimeout(() => {
          handleClose()
        }, 500)
        setFirst(!first)
        enqueueSnackbar(`Successfully duplicated`, { variant: 'success' })
      })
      .catch((err) => {
        setDuplicateLoading(false)
        if (err.response?.data?.detail.errorMessage) {
          enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
        } else if (err.response !== undefined) {
          enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
        }
      })
  }

  async function handleEditCreateTest() {
    setLoadingSave(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/editTest/${selectedActivity}`,
        {
          ...createTest,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response: any) => {
        enqueueSnackbar(`Saved successfully  `, { variant: 'success' })
        setLoadingSave(false)
        setFirst(!first)
        handleClose()
      })
      .catch((err) => {
        if (err.response?.data?.detail.errorMessage) {
          err.response?.data?.detail.errorMessage === 'CANT EDIT ACTIVITY BECAUSE IN JOURNEY'
            ? setJourneyWarnModal(true)
            : err.response?.data?.detail.errorMessage === 'ACTIVITY_NAME_IS_ALREADY'
              ? enqueueSnackbar(`Name exists. Choose another one`, {
                variant: 'error',
              })
              : enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
                variant: 'error',
              })
        } else if (err.response !== undefined) {
          enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
        }
        setLoadingSave(false)
      })
  }

  useEffect(() => {
    //edit learning card categoryChange == question delete
    if (category !== 0 && editActivity === 0) {
      if (editCategory !== category) {
        const filteredLearningCard = learningCard.filter((card: any) => !card.question)
        setLearningCard(filteredLearningCard)
      }
    }
  }, [category])

  useEffect(() => {
    if (editActivity !== 0) {
      setActivityType(editActivity)
    }
  }, [editActivity])

  const handleClose = () => {
    handleClearLearningCard()
    handleClearGame()
    handleClearTest()
    setSelectedActivity(0)
    setCreateActivityModal(false)
  }

  /*  console.log('activityType: ', activityType)
  console.log('activityName:: ', activityName)
  console.log('category : ', category)

   console.log('learningcard : ', learningCard)
  console.log('questionAnswers: ', questionAnswers)
  console.log('activityTypeName: ', activityTypeName)
  console.log('categoryTest: ', categoryTest)
  console.log('createTest: ', createTest) */

  return (
    <div>
      <Dialog
        open={createActivityModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth='xl'
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle
          style={{
            fontSize: '26px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          Select Activity{' '}
          <Typography
            display={activityTypeName === '' ? 'none' : 'block'}
            marginLeft={1}
            fontSize={'26px'}
          >
            - {activityTypeName}
          </Typography>{' '}
          <IconButton
            edge='end'
            onClick={handleClose}
            style={{ position: 'absolute', right: 15 }}
            aria-label='close'
            size='large'
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }} minHeight={520} padding={1.5}>
            {
              //activityType start
            }
            {
              selectedActivity === 0 ?
                <Grid container display={activityType === 0 ? '' : 'none'} justifyContent={'center'}>
                  {/* <Paper elevation={5} style={{ width: '520px', height: 'auto', padding: 5 }}> */}
                  <Grid xs={6} container rowGap={'15px'} minWidth={'520px'}>
                    <Grid xs={5} display={'flex'} justifyContent={'center'}>
                      <Box
                        padding={0}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                          borderRadius: '10px',
                          width: '170px',
                          height: '145px',
                          background: '#5997fe',
                          color: 'white',
                          /*  boxShadow: 'inset 3px 0px 10px #8E8E8E',
                        opacity: '0.7', */
                        }}
                      >
                        <Typography
                          variant='h6'
                          component='div'
                          fontWeight={'bold'}
                          sx={{ fontSize: '14px' }}
                        >
                          EDUCATE
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <div
                        onMouseEnter={() => setActivityTypeHover(1)}
                        onMouseLeave={() => setActivityTypeHover(0)}
                        onClick={() => {
                          setActivityType(1)
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '5px',
                          padding: 5,
                          width: '130px',
                          /*  height: '55px', */
                          background: activityTypeHover === 1 ? '#5997fe' : '#D5D5D5',
                          color: 'white',
                          boxShadow: 'inset 3px 0px 10px #8E8E8E',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography
                          variant='h6'
                          textAlign={'center'}
                          display={'flex'}
                          component='div'
                          sx={{ fontSize: '13px' }}
                        >
                          Learning Card
                        </Typography>
                      </div>
                      {/*  <div
                    style={{
                      position: 'absolute',
                      marginLeft: '-97px',
                      transform: 'rotate(0deg)',
                      marginTop: '-2px',
                      border: activityTypeHover === 1 ? '1px solid black' : '1px solid #D5D5D5',
                      width: '94px',
                    }}
                  /> */}
                    </Grid>
                    <Grid xs={5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <Box
                        padding={0}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                          borderRadius: '10px',
                          width: '170px',
                          height: '145px',
                          background: '#d042f3',
                          color: 'white',
                          /*   boxShadow: 'inset 3px 0px 10px #8E8E8E',
                        opacity: '0.7', */
                        }}
                      >
                        <Typography
                          variant='h6'
                          fontWeight={'bold'}
                          component='div'
                          sx={{ fontSize: '14px' }}
                        >
                          MOTİVATE
                        </Typography>
                      </Box>
                      {/*  <div
                    style={{
                      position: 'absolute',
                      marginLeft: '170px',
                      transform: 'rotate(-25deg)',
                      marginTop: '-89px',
                      border: activityTypeHover === 2 ? '1px solid black' : '1px solid #D5D5D5',
                      width: '110px',
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      marginLeft: '170px',
                      transform: 'rotate(-10deg)',
                      marginRight: '-42px',
                      marginTop: '-25px',
                      border: activityTypeHover === 3 ? '1px solid black' : '1px solid #D5D5D5',
                      width: '130px',
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      marginLeft: '170px',
                      transform: 'rotate(6deg)',
                      marginRight: '-42px',
                      marginTop: '40px',
                      border: activityTypeHover === 4 ? '1px solid black' : '1px solid #D5D5D5',
                      width: '130px',
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      marginLeft: '170px',
                      transform: 'rotate(22deg)',
                      marginRight: '6px',
                      marginTop: '103px',
                      border: activityTypeHover === 5 ? '1px solid black' : '1px solid #D5D5D5',
                      width: '115px',
                    }}
                  /> */}
                    </Grid>
                    <Grid
                      xs={6}
                      rowGap={'5px'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}
                    >
                      <div
                        onMouseEnter={() => setActivityTypeHover(2)}
                        onMouseLeave={() => setActivityTypeHover(0)}
                        onClick={() => {
                          setActivityType(2)
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '5px',
                          padding: 5,
                          width: '130px',
                          /*  height: '55px', */
                          background: activityTypeHover === 2 ? '#d042f3' : '#D5D5D5',
                          color: 'white',
                          boxShadow: 'inset 3px 0px 10px #8E8E8E',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant='h6' component='div' sx={{ fontSize: '13px' }}>
                          DeepLearn
                        </Typography>
                      </div>
                      {/*  <div
                        onMouseEnter={() => setActivityTypeHover(3)}
                        onMouseLeave={() => setActivityTypeHover(0)}
                        onClick={() => {
                          setActivityType(3)
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '5px',
                          padding: 5,
                          width: '130px',
                          background: activityTypeHover === 3 ? '#d042f3' : '#D5D5D5',
                          color: 'white',
                          boxShadow: 'inset 3px 0px 10px #8E8E8E',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant='h6' component='div' sx={{ fontSize: '13px' }}>
                          Tenten
                        </Typography>
                      </div> */}
                      {/* <div
                        onMouseEnter={() => setActivityTypeHover(4)}
                        onMouseLeave={() => setActivityTypeHover(0)}
                        onClick={() => {
                          setActivityType(4)
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '5px',
                          padding: 5,
                          width: '130px',
                          background: activityTypeHover === 4 ? '#d042f3' : '#D5D5D5',
                          color: 'white',
                          boxShadow: 'inset 3px 0px 10px #8E8E8E',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant='h6' component='div' sx={{ fontSize: '13px' }}>
                          Recall
                        </Typography>
                      </div> */}
                      <div
                        onMouseEnter={() => setActivityTypeHover(5)}
                        onMouseLeave={() => setActivityTypeHover(0)}
                        onClick={() => {
                          setActivityType(5)
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '5px',
                          padding: 5,
                          width: '130px',
                          /*  height: '55px', */
                          background: activityTypeHover === 5 ? '#d042f3' : '#D5D5D5',
                          color: 'white',
                          boxShadow: 'inset 3px 0px 10px #8E8E8E',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant='h6' component='div' sx={{ fontSize: '13px' }}>
                          Peak
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '10px',
                          width: '170px',
                          height: '145px',
                          background: '#1de503',
                          color: 'white',
                          /*    boxShadow: 'inset 3px 0px 10px #8E8E8E',
                        opacity: '0.7', */
                        }}
                      >
                        <Typography
                          variant='h6'
                          fontWeight={'bold'}
                          component='div'
                          sx={{ fontSize: '14px' }}
                        >
                          EVALUATE
                        </Typography>
                      </div>
                      {/*  <div
                    style={{
                      position: 'absolute',
                      marginLeft: '170px',
                      transform: 'rotate(-9deg)',
                      marginRight: '-1px',
                      marginTop: '-34px',
                      border: activityTypeHover === 6 ? '1px solid black' : '1px solid #D5D5D5',
                      width: '94px',
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      marginLeft: '170px',
                      transform: 'rotate(10deg)',
                      marginRight: '6px',
                      marginTop: '45px',
                      border: activityTypeHover === 7 ? '1px solid black' : '1px solid #D5D5D5',
                      width: '94px',
                    }}
                  /> */}
                    </Grid>
                    <Grid
                      xs={6}
                      rowGap={'5px'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}
                    >
                      <div
                        onMouseEnter={() => setActivityTypeHover(6)}
                        onMouseLeave={() => setActivityTypeHover(0)}
                        onClick={() => {
                          setActivityType(6)
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '5px',
                          padding: 5,
                          width: '130px',
                          /*  height: '55px', */
                          background: activityTypeHover === 6 ? '#1de503' : '#D5D5D5',
                          color: 'white',
                          boxShadow: 'inset 3px 0px 10px #8E8E8E',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant='h6' component='div' sx={{ fontSize: '13px' }}>
                          Quiz
                        </Typography>
                      </div>
                      <div
                        onMouseEnter={() => setActivityTypeHover(7)}
                        onMouseLeave={() => setActivityTypeHover(0)}
                        onClick={() => {
                          setActivityType(7)
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '5px',
                          borderRadius: '5px',
                          padding: 5,
                          width: '130px',
                          /*  height: '55px', */
                          background: activityTypeHover === 7 ? '#1de503' : '#D5D5D5',
                          color: 'white',
                          boxShadow: 'inset 3px 0px 10px #8E8E8E',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant='h6' component='div' sx={{ fontSize: '13px' }}>
                          Exam
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  {/*  </Paper> */}
                  {/* <Paper
                    elevation={5}
                    style={{ marginLeft: 45, width: '520px', height: 'auto', padding: 5 }}
                  > */}
                  <Grid xs={6} container justifyContent={'center'} alignItems={"center"} minWidth={'520px'}>
                    {/*  <TextField
                        id='outlined-basic'
                        label='Explanation Area'
                        variant='outlined'
                        fullWidth
                        autoFocus
                        disabled={true}
                        style={{ marginTop: '20px', width: '70%' }}
                      /> */}
                    {
                      explanationArea.length > 0 &&
                      <Box width={400} minHeight={400} height={"auto"} border={"1px solid #D5D5D5"} borderRadius={"10px"} padding={1} >
                        <Typography fontSize={18} fontWeight={"bold"}>{handleTypeHoverActivityName(activityTypeHover)}</Typography>
                        <Typography fontSize={16}>{explanationArea}</Typography>
                      </Box>
                    }
                  </Grid>
                  {/*   </Paper> */}
                  {
                    //footer
                  }
                  <Grid xs={12} marginTop={2}>
                    <Divider variant='middle' sx={{ borderTop: '1px solid black' }} />
                    <Box
                      display={'flex'}
                      padding={0}
                      marginTop={'10px'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      sx={{ width: '100%' }}
                    >
                      <Button
                        variant='contained'
                        color='inherit'
                        sx={{ marginRight: '20px', marginLeft: '9%' }}
                        onClick={() => handleClose()}
                      >
                        DISCARD
                      </Button>
                    </Box>
                  </Grid>
                </Grid> :
                null
            }

            {
              //activityType end
            }
            {
              //learningcard
            }
            <Grid container display={activityType === 1 ? 'block' : 'none'}>
              <Grid xs={12} minHeight={'450px'}>
                <Box sx={{ width: '100%', paddingLeft: "5%", paddingRight: "5%" }}>
                  <Stepper activeStep={step} /* alternativeLabel */>
                    {steps.map((label) => (
                      <Step
                        key={label}
                        sx={{
                          '& .MuiStepIcon-root': {
                            fontSize: '25px',
                            color: '#9E9E9E', // Varsayılan renk
                            fontWeight: 'bold',
                          },
                          '& .Mui-active, & .Mui-completed': {
                            color: '#9c27b0 !important',
                          },
                        }}
                      >
                        <StepLabel
                          sx={{
                            '& .MuiStepLabel-label': {
                              fontSize: '15px', // Label'ların font boyutunu ayarlayın
                            },
                            '& .Mui-active': {
                              fontWeight: 'bold',
                            },
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                {
                  //activityName start
                }
                <Grid
                  xs={12}
                  container
                  justifyItems={'center'}
                  textAlign={'center'}
                  marginTop={10}
                  display={step === 0 ? 'block' : 'none'}
                >
                  <Box sx={{ width: '100%' }}>
                    <Typography variant='h6' color={'#8E8E8E'}>
                      Give a name for your activity.
                      <br /> You can prefer to choose topic of the activity as name.
                    </Typography>
                    <TextField
                      id='outlined-basic'
                      label='Enter a name'
                      placeholder='Min 2 - Max 24 character'
                      variant='outlined'
                      value={activityName}
                      onChange={(e) => {
                        if (e.target.value.length <= 24) {
                          setNameCheck(false)
                          setActivityName(e.target.value)
                          if (timer) clearTimeout(timer)
                          // 2 saniye sonra kontrolü tetikle
                          const newTimer = setTimeout(
                            () => checkActivityName(e.target.value, activityType),
                            2000
                          )
                          setTimer(newTimer)
                        }
                      }}
                      fullWidth
                      style={{ marginTop: '20px', width: '300px' }}
                      error={nameCheck}
                      helperText={nameCheck ? 'Name exists. Choose another one' : ''}
                      InputProps={{
                        endAdornment: nameCheckLoading && (
                          <InputAdornment position='end'>
                            <CircularProgress size={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                {
                  //activityName end
                }
                {
                  //activitySelectCategory start
                }
                {step === 1 && (
                  <Grid
                    xs={12}
                    container
                    justifyItems={'center'}
                    textAlign={'center'}
                    marginTop={1}
                  /* display={step === 1 ? '' : 'none'} */
                  >
                    {loadingCategories && (
                      <Box
                        sx={{ width: '100%' }}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <CircularProgress size={'30px'} color='primary' />
                      </Box>
                    )}
                    {loadingCategories === false && (
                      <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs1
                            value={tabValue}
                            onChange={handleChangeTabMenu}
                            aria-label='basic tabs example'
                            sx={{
                              marginTop: 2,
                              minHeight: '40px', // Tabs bileşeninin min yüksekliğini ayarlayın
                              '& .MuiTabs-flexContainer': {
                                minHeight: '40px',
                              },
                              '& .MuiTab-root': {
                                minHeight: '30px', // Her bir Tab bileşeninin min yüksekliğini ayarlayın
                                padding: '3px 6px', // Daha dar bir görünüm için padding'i azaltın
                                fontSize: '15px', // İsteğe bağlı olarak font boyutunu ayarlayın
                              },
                            }}
                          >
                            <Tab1
                              icon={tabValue === 0 && <CheckCircleIcon />}
                              iconPosition='start'
                              label={customerProfile?.customerName + ' Category '}
                              {...a11yProps(0)}
                            />
                            <Tab1
                              icon={tabValue === 1 && <CheckCircleIcon />}
                              iconPosition='start'
                              sx={{ marginLeft: '5px' }}
                              label=' Mobixa Category '
                              {...a11yProps(1)}
                            />
                          </Tabs1>
                        </Box>
                        <CustomTabPanel value={tabValue} index={0}>
                          <div
                            className='d-flex '
                            style={{
                              overflowY: 'scroll',
                              overflowX: 'scroll',
                              minHeight: '600px',
                              height: '600px',
                            }}
                          >
                            {customerCategory?.map((data: any) => (
                              <>
                                <div>
                                  {
                                    //1.katman
                                  }
                                  <AccordionMui
                                    style={{
                                      marginRight: 20,
                                      borderRadius: '10px 10px 5px 5px',
                                      border: '1px solid #d5d5d5',
                                      minWidth: '300px',
                                    }}
                                    defaultExpanded={true}
                                  >
                                    <AccordionSummary
                                      /* expandIcon={
                                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                                } */
                                      style={{
                                        background: '#D5D5D5',
                                        borderRadius: '10px 10px 0px 0px',
                                        padding: '0px 10px 0px 0px',
                                        minHeight: '60px',
                                      }}
                                    >
                                      <div
                                        className='d-flex '
                                        style={{ alignItems: 'center', height: 20 }}
                                      >
                                        <Radio
                                          checked={category == data.categoryId}
                                          onClick={(e: any) => {
                                            setCategory(e.target.value)
                                            setCategoryName(data.name)
                                            e.stopPropagation()
                                          }}
                                          disabled={data.questionCount < 1}
                                          value={data.categoryId}
                                          name='category'
                                          color='success'
                                          /* size='small' */
                                          sx={{
                                            '& .MuiSvgIcon-root': {
                                              fontSize: '22px',
                                            },
                                            '&.Mui-checked': {
                                              color: '#53b658',
                                            },
                                          }}
                                        />
                                        <div
                                          style={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            fontSize: '12px',
                                            marginLeft: '-4px',
                                            textAlign: "start"
                                          }}
                                        >
                                          {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                            data.name.slice(1)}
                                        </div>
                                        <span
                                          title='Number of awailable question'
                                          className='text-dark  ms-2'
                                          style={{
                                            fontSize: '10px',
                                            color: '#8e8e8e',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          ({data.questionCount})
                                        </span>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '3px' }}>
                                      {
                                        //2. katman
                                      }

                                      {data?.children?.map((child: any) =>
                                        child.children.length > 0 ? (
                                          <>
                                            <AccordionMui
                                              style={{
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                                boxShadow: 'none',
                                                borderRadius: '10px 10px 0px 0px',
                                              }}
                                              defaultExpanded={true}
                                            >
                                              <AccordionSummary
                                                sx={{
                                                  margin: '0px!important',
                                                  '.MuiAccordionSummary-content': {
                                                    // AccordionSummary'nin içeriği için stil
                                                    marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                                  },
                                                  '&:hover': {
                                                    backgroundColor: '#e7e7e7', // Hover durumu için arka plan rengi
                                                  },
                                                }}
                                                style={{
                                                  borderRadius: '10px 10px 0px 0px',
                                                  padding: '0px 5px 0px 0px',
                                                  minHeight: '35px',
                                                  marginLeft: '0px!important',
                                                }}
                                              >
                                                <div
                                                  className='d-flex'
                                                  style={{
                                                    alignItems: 'center',
                                                    justifyItems: 'start',
                                                    height: 20,
                                                  }}
                                                >
                                                  <Radio
                                                    checked={category == child.categoryId}
                                                    onClick={(e: any) => {
                                                      setCategory(e.target.value)
                                                      setCategoryName(child.name)
                                                      e.stopPropagation()
                                                    }}
                                                    disabled={child.questionCount < 1}
                                                    value={child.categoryId}
                                                    name='category'
                                                    color='success'
                                                    sx={{
                                                      '& .MuiSvgIcon-root': {
                                                        fontSize: '22px',
                                                      },
                                                      '&.Mui-checked': {
                                                        color: '#53b658',
                                                      },
                                                    }}
                                                  />
                                                  <span
                                                    className={'text-gray-800 d-flex'}
                                                    style={{
                                                      fontWeight: 'bold',
                                                      cursor: 'pointer',
                                                      fontSize: '12px',
                                                      marginLeft: '-4px',
                                                      textAlign: "start"
                                                    }}
                                                  >
                                                    {child.name.charAt(0).toUpperCase() +
                                                      child.name.slice(1)}
                                                  </span>
                                                  <span
                                                    title='Number of awailable question'
                                                    className='text-dark text-muted ms-2'
                                                    style={{
                                                      fontSize: '10px',
                                                      color: '#8e8e8e',
                                                      fontWeight: 'normal',
                                                    }}
                                                  >
                                                    ({child.questionCount})
                                                  </span>
                                                </div>
                                              </AccordionSummary>
                                              <AccordionDetails style={{ padding: '3px' }}>
                                                {child?.children?.map((child: any) => (
                                                  <div id={child.categoryId} className='ms-0 mt-3 '>
                                                    <div
                                                      className='d-flex div-element-class'
                                                      style={{
                                                        alignItems: 'center',
                                                        height: 35,
                                                        paddingLeft: 32,
                                                      }}
                                                    >
                                                      <Radio
                                                        checked={category == child.categoryId}
                                                        onClick={(e: any) => {
                                                          setCategory(e.target.value)
                                                          setCategoryName(child.name)
                                                        }}
                                                        disabled={child.questionCount < 1}
                                                        value={child.categoryId}
                                                        name='category'
                                                        color='success'
                                                        /* size='small' */
                                                        sx={{
                                                          width: '30px',
                                                          height: '30px',
                                                          '& .MuiSvgIcon-root': {
                                                            fontSize: '16px',
                                                          },
                                                          '&.Mui-checked': {
                                                            color: '#53b658',
                                                          },
                                                        }}
                                                      />
                                                      <a
                                                        className={'text-gray-800 d-flex '}
                                                        style={{
                                                          cursor: 'pointer',
                                                          fontSize: '12px',
                                                          alignItems: 'center',
                                                          maxWidth: '210px',
                                                          marginLeft: '-4px',
                                                          textAlign: "start"
                                                        }}
                                                      >
                                                        {child.name
                                                          .charAt(0)
                                                          .toUpperCase()
                                                          .replace('🅜', '') + child.name.slice(1)}
                                                      </a>

                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          height: '20px',
                                                        }}
                                                      >
                                                        <span
                                                          title='Number of awailable question'
                                                          className='ms-2'
                                                          style={{
                                                            color: '#8e8e8e',
                                                            fontSize: '10px',
                                                          }}
                                                        >
                                                          {`(${child.questionCount})`}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))}
                                              </AccordionDetails>
                                            </AccordionMui>
                                          </>
                                        ) : null
                                      )}
                                      {
                                        // 3. katman
                                      }

                                      {data?.children?.map((child: any) =>
                                        child.children.length > 0 ? null : (
                                          <div id={child.categoryId} className='ms-0 mt-3 '>
                                            <div
                                              className='d-flex border-1 div-element-class'
                                              style={{
                                                alignItems: 'center',
                                                height: 35,
                                                paddingLeft: 4,
                                              }}
                                            >
                                              <Radio
                                                checked={category == child.categoryId}
                                                onClick={(e: any) => {
                                                  setCategory(e.target.value)
                                                  setCategoryName(child.name)
                                                }}
                                                value={child.categoryId}
                                                disabled={child.questionCount < 1}
                                                name='category'
                                                color='success'
                                                /* size='small' */
                                                sx={{
                                                  '& .MuiSvgIcon-root': {
                                                    fontSize: '22px',
                                                  },
                                                  '&.Mui-checked': {
                                                    color: '#53b658',
                                                  },
                                                }}
                                              />
                                              <a
                                                className={'text-gray-800 d-flex'}
                                                style={{
                                                  cursor: 'pointer',
                                                  fontSize: '12px',
                                                  maxWidth: '210px',
                                                  marginLeft: '-4px',
                                                  textAlign: "start"
                                                }}
                                              >
                                                {child.name.charAt(0).toUpperCase() +
                                                  child.name.slice(1)}
                                              </a>

                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  height: '20px',
                                                }}
                                              >
                                                <span
                                                  title='Number of awailable question'
                                                  className='ms-2'
                                                  style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                >
                                                  {`(${child.questionCount})`}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </AccordionDetails>
                                  </AccordionMui>
                                </div>
                              </>
                            ))}
                          </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                          <div
                            className='d-flex '
                            style={{
                              overflowY: 'scroll',
                              overflowX: 'scroll',
                              minHeight: '600px',
                              height: '600px',
                            }}
                          >
                            {mobixaCategory?.map((data: any) => (
                              <>
                                <div>
                                  {
                                    //1.katman
                                  }
                                  <AccordionMui
                                    style={{
                                      marginRight: 20,
                                      borderRadius: '10px 10px 5px 5px',
                                      border: '1px solid #d5d5d5',
                                      minWidth: '300px',
                                    }}
                                    defaultExpanded={true}
                                  >
                                    <AccordionSummary
                                      /* expandIcon={
                                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                                } */
                                      style={{
                                        background: '#D5D5D5',
                                        borderRadius: '10px 10px 0px 0px',
                                        padding: '0px 10px 0px 0px',
                                        minHeight: '60px',
                                      }}
                                    >
                                      <div
                                        className='d-flex '
                                        style={{ alignItems: 'center', height: 20 }}
                                      >
                                        <Radio
                                          checked={category == data.categoryId}
                                          onClick={(e: any) => {
                                            setCategory(e.target.value)
                                            setCategoryName(data.name)
                                            e.stopPropagation()
                                          }}
                                          disabled={data.questionCount < 1}
                                          value={data.categoryId}
                                          name='category'
                                          color='success'
                                          /* size='small' */
                                          sx={{
                                            '& .MuiSvgIcon-root': {
                                              fontSize: '22px',
                                            },
                                            '&.Mui-checked': {
                                              color: '#53b658',
                                            },
                                          }}
                                        />
                                        <div
                                          style={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            fontSize: '12px',
                                            marginLeft: '-4px',
                                            textAlign: "start"
                                          }}
                                        >
                                          {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                            data.name.slice(1)}
                                        </div>
                                        <span
                                          title='Number of awailable question'
                                          className='text-dark  ms-2'
                                          style={{
                                            fontSize: '10px',
                                            color: '#8e8e8e',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          ({data.questionCount})
                                        </span>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '3px' }}>
                                      {
                                        //2. katman
                                      }

                                      {data?.children?.map((child: any) =>
                                        child.children.length > 0 ? (
                                          <>
                                            <AccordionMui
                                              style={{
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                                boxShadow: 'none',
                                                borderRadius: '10px 10px 0px 0px',
                                              }}
                                              defaultExpanded={true}
                                            >
                                              <AccordionSummary
                                                sx={{
                                                  margin: '0px!important',
                                                  '.MuiAccordionSummary-content': {
                                                    // AccordionSummary'nin içeriği için stil
                                                    marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                                  },
                                                  '&:hover': {
                                                    backgroundColor: '#e7e7e7', // Hover durumu için arka plan rengi
                                                  },
                                                }}
                                                style={{
                                                  borderRadius: '10px 10px 0px 0px',
                                                  padding: '0px 5px 0px 0px',
                                                  minHeight: '35px',
                                                  marginLeft: '0px!important',
                                                }}
                                              >
                                                <div
                                                  className='d-flex'
                                                  style={{
                                                    alignItems: 'center',
                                                    justifyItems: 'start',
                                                    height: 20,
                                                  }}
                                                >
                                                  <Radio
                                                    checked={category == child.categoryId}
                                                    onClick={(e: any) => {
                                                      setCategory(e.target.value)
                                                      setCategoryName(child.name)
                                                      e.stopPropagation()
                                                    }}
                                                    disabled={child.questionCount < 1}
                                                    value={child.categoryId}
                                                    name='category'
                                                    color='success'
                                                    sx={{
                                                      '& .MuiSvgIcon-root': {
                                                        fontSize: '22px',
                                                      },
                                                      '&.Mui-checked': {
                                                        color: '#53b658',
                                                      },
                                                    }}
                                                  />
                                                  <span
                                                    className={'text-gray-800 d-flex'}
                                                    style={{
                                                      fontWeight: 'bold',
                                                      cursor: 'pointer',
                                                      fontSize: '12px',
                                                      marginLeft: '-4px',
                                                      textAlign: "start"
                                                    }}
                                                  >
                                                    {child.name.charAt(0).toUpperCase() +
                                                      child.name.slice(1)}
                                                  </span>
                                                  <span
                                                    title='Number of awailable question'
                                                    className='text-dark text-muted ms-2'
                                                    style={{
                                                      fontSize: '10px',
                                                      color: '#8e8e8e',
                                                      fontWeight: 'normal',
                                                    }}
                                                  >
                                                    ({child.questionCount})
                                                  </span>
                                                </div>
                                              </AccordionSummary>
                                              <AccordionDetails style={{ padding: '3px' }}>
                                                {child?.children?.map((child: any) => (
                                                  <div id={child.categoryId} className='ms-0 mt-3 '>
                                                    <div
                                                      className='d-flex div-element-class'
                                                      style={{
                                                        alignItems: 'center',
                                                        height: 35,
                                                        paddingLeft: 32,
                                                      }}
                                                    >
                                                      <Radio
                                                        checked={category == child.categoryId}
                                                        onClick={(e: any) => {
                                                          setCategory(e.target.value)
                                                          setCategoryName(child.name)
                                                        }}
                                                        disabled={child.questionCount < 1}
                                                        value={child.categoryId}
                                                        name='category'
                                                        color='success'
                                                        /* size='small' */
                                                        sx={{
                                                          width: '30px',
                                                          height: '30px',
                                                          '& .MuiSvgIcon-root': {
                                                            fontSize: '16px',
                                                          },
                                                          '&.Mui-checked': {
                                                            color: '#53b658',
                                                          },
                                                        }}
                                                      />
                                                      <a
                                                        className={'text-gray-800 d-flex '}
                                                        style={{
                                                          cursor: 'pointer',
                                                          fontSize: '12px',
                                                          alignItems: 'center',
                                                          maxWidth: '210px',
                                                          marginLeft: '-4px',
                                                          textAlign: "start"
                                                        }}
                                                      >
                                                        {child.name
                                                          .charAt(0)
                                                          .toUpperCase()
                                                          .replace('🅜', '') + child.name.slice(1)}
                                                      </a>

                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          height: '20px',
                                                        }}
                                                      >
                                                        <span
                                                          title='Number of awailable question'
                                                          className='ms-2'
                                                          style={{
                                                            color: '#8e8e8e',
                                                            fontSize: '10px',
                                                          }}
                                                        >
                                                          {`(${child.questionCount})`}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))}
                                              </AccordionDetails>
                                            </AccordionMui>
                                          </>
                                        ) : null
                                      )}
                                      {
                                        // 3. katman
                                      }

                                      {data?.children?.map((child: any) =>
                                        child.children.length > 0 ? null : (
                                          <div id={child.categoryId} className='ms-0 mt-3 '>
                                            <div
                                              className='d-flex border-1 div-element-class'
                                              style={{
                                                alignItems: 'center',
                                                height: 35,
                                                paddingLeft: 4,
                                              }}
                                            >
                                              <Radio
                                                checked={category == child.categoryId}
                                                onClick={(e: any) => {
                                                  setCategory(e.target.value)
                                                  setCategoryName(child.name)
                                                }}
                                                value={child.categoryId}
                                                disabled={child.questionCount < 1}
                                                name='category'
                                                color='success'
                                                /* size='small' */
                                                sx={{
                                                  '& .MuiSvgIcon-root': {
                                                    fontSize: '22px',
                                                  },
                                                  '&.Mui-checked': {
                                                    color: '#53b658',
                                                  },
                                                }}
                                              />
                                              <a
                                                className={'text-gray-800 d-flex'}
                                                style={{
                                                  cursor: 'pointer',
                                                  fontSize: '12px',
                                                  maxWidth: '210px',
                                                  marginLeft: '-4px',
                                                  textAlign: "start"
                                                }}
                                              >
                                                {child.name.charAt(0).toUpperCase() +
                                                  child.name.slice(1)}
                                              </a>

                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  height: '20px',
                                                }}
                                              >
                                                <span
                                                  title='Number of awailable question'
                                                  className='ms-2'
                                                  style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                >
                                                  {`(${child.questionCount})`}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </AccordionDetails>
                                  </AccordionMui>
                                </div>
                              </>
                            ))}
                          </div>
                        </CustomTabPanel>
                      </Box>
                    )}
                  </Grid>
                )}

                {
                  //activitySelectCategory end
                }
                {
                  //activityLearningCardContent start
                }
                {step === 2 && (
                  <Grid
                    xs={12}
                    ref={scrollContainerRef}
                    container
                    justifyItems={'center'}
                    textAlign={'center'}
                    marginTop={3}
                    padding={1}
                    marginBottom={2}
                    columnGap={2}
                    sx={{ overflowX: 'auto', width: '100%', display: 'flex' }}
                  >
                    <Box display={step === 2 ? 'flex' : 'none'} columnGap={2}>
                      {learningCard?.map((item: any, index: number) =>
                        item.question === false ? (
                          <Card
                            sx={{
                              width: 354,
                              height: 606,
                              border: '4px solid black',
                              borderRadius: '15px',
                            }}
                          >
                            <CardActionArea>
                              <Box
                                height={'197px'}
                                width={350}
                                padding={0}
                                sx={{ objectFit: 'contain' }}
                              >
                                <SelectContentNew
                                  changeFile={changeFile}
                                  changeMediaUrl={changeMediaUrl}
                                  iconDisabled={true}
                                  mediaUrl={item.mediaUrl}
                                  mediaType={item.mediaType}
                                  index={index}
                                />
                              </Box>
                              {/* <CardMedia
                            component='img'
                            height='140'
                            image='/static/images/cards/contemplative-reptile.jpg'
                            alt='green iguana'
                          /> */}
                            </CardActionArea>
                            <CardContent style={{ marginTop: 5, padding: 0 }}>
                              <Box padding={1}>
                                <TextField
                                  fullWidth
                                  variant='outlined'
                                  label='Takeaway Sentence'
                                  placeholder='140 character max.'
                                  value={item.takeaway}
                                  onChange={(e: any) => changeTakeaway(e.target.value, index)}
                                  rows={3}
                                  multiline
                                  inputProps={{ maxLength: 140, style: { fontFamily: 'atma-font' } }}
                                />
                              </Box>
                              <div style={{ height: '280px', width: '350px', padding: '5px' }}>
                                <TinyMCEWidgetLearning
                                  index={/* index */ index}
                                  changeDescription={changeDescription}
                                  html={item.htmlDescription}
                                />
                              </div>
                            </CardContent>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <Tooltip
                                arrow
                                title='Delete Card'
                                placement='top'
                                onMouseEnter={() => {
                                  handleMouseEnter(index)
                                }}
                                onMouseLeave={() => {
                                  handleMouseLeave(-1)
                                }}
                                style={{
                                  background: '#FF0000DE',
                                  marginTop: '-10px',
                                  marginRight: '-5px',
                                  opacity: deleteCardHover === index ? '1' : '0.5',
                                }}
                              >
                                <IconButton
                                  aria-label='deleteCard'
                                  /*   onClick={() => setCardAdd(true)} */
                                  onClick={() => onRemoveBtnClick(index)}
                                  size='small'
                                  sx={{ background: 'white', marginRight: '7px' }}
                                >
                                  <DeleteForeverRoundedIcon
                                    fontSize='small'
                                    sx={{ color: 'white' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'start' }}>
                              <Tooltip
                                arrow
                                title='Page Number'
                                placement='top'
                                style={{
                                  background: '#FF0000DE',
                                  marginTop: '-25px',
                                  /* marginRight: '-5px', */
                                  opacity: '0.5',
                                  width: '26.25px',
                                  height: '26.25px',
                                }}
                              >
                                <IconButton
                                  aria-label='pageNumber'
                                  disabled={true}
                                  size='small'
                                  sx={{ marginRight: '7px', color: 'white!important' }}
                                >
                                  {index + 1}
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Card>
                        ) : (
                          //questionCard
                          <Card
                            sx={{
                              width: 354,
                              height: 606,
                              border: '4px solid black',
                              borderRadius: '15px',
                            }}
                          >
                            <Box
                              height={'588px'}
                              width={350}
                              padding={1}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                rowGap: 2,
                              }}
                            >
                              <Paper
                                sx={{ display: 'flex', height: '190px', padding: 0, width: '100%' }}
                              >
                                <SelectQuestion
                                  customer={undefined}
                                  changeQuestion={changeQuestion}
                                  index={index}
                                  category={category}
                                  learningCard={learningCard}
                                  questionDescription={item.questionDescription}
                                  createQuestionId={item.questionId}
                                />
                              </Paper>
                              {item.questionAnswers?.length > 0 && (
                                <Paper sx={{ display: 'flex', padding: 0, width: '100%' }}>
                                  <Accordion
                                    style={{ width: '100%', display: 'block' }}
                                    defaultActiveKey='1'
                                  >
                                    <Accordion.Item eventKey='0'>
                                      <Accordion.Header className='custom-header'>
                                        Answers
                                      </Accordion.Header>
                                      <Accordion.Body
                                        style={{
                                          maxHeight: '305px',
                                          overflow: 'auto',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          rowGap: '10px',
                                        }}
                                      >
                                        {item.questionId !== null &&
                                          item.questionAnswers.map((item: any, index: number) => (
                                            <Paper
                                              key={index}
                                              elevation={2}
                                              sx={{
                                                borderRadius: '10px',
                                                width: '98%',
                                                height: '59px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'start',
                                              }}
                                            >
                                              <Checkbox
                                                disabled={true}
                                                checked={item.isCorrect === true ? true : false}
                                              />
                                              <Typography fontFamily={'atma-font'}>
                                                {item.description}
                                              </Typography>
                                            </Paper>
                                          ))}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </Paper>
                              )}
                            </Box>

                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <Tooltip
                                arrow
                                title='Delete Card'
                                placement='top'
                                key={index}
                                onMouseEnter={() => {
                                  handleMouseEnter(index)
                                }}
                                onMouseLeave={() => {
                                  handleMouseLeave(-1)
                                }}
                                style={{
                                  background: '#FF0000DE',
                                  marginTop: '-10px',
                                  marginRight: '-5px',
                                  opacity: deleteCardHover === index ? '1' : '0.5',
                                }}
                              >
                                <IconButton
                                  aria-label='deleteCard'
                                  /*   onClick={() => setCardAdd(true)} */
                                  onClick={() => onRemoveBtnClick(index)}
                                  size='small'
                                  sx={{ background: 'white', marginRight: '7px' }}
                                >
                                  <DeleteForeverRoundedIcon
                                    fontSize='small'
                                    sx={{ color: 'white' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'start' }}>
                              <Tooltip
                                arrow
                                title='Page Number'
                                placement='top'
                                style={{
                                  background: '#FF0000DE',
                                  marginTop: '-25px',
                                  /* marginRight: '-5px', */
                                  opacity: '0.5',
                                  width: '26.25px',
                                  height: '26.25px',
                                }}
                              >
                                <IconButton
                                  aria-label='pageNumber'
                                  disabled={true}
                                  size='small'
                                  sx={{ marginRight: '7px', color: 'white!important' }}
                                >
                                  {index + 1}
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Card>
                        )
                      )}
                      <Box
                        padding={0}
                        display={'flex'}
                        alignSelf={'center'}
                        flexDirection={'column'}
                      >
                        <div>
                          <Tooltip
                            arrow
                            title=''
                            placement='top'
                            onMouseEnter={() => {
                              setAddVideoHover(true)
                            }}
                            onMouseLeave={() => {
                              setAddVideoHover(false)
                            }}
                            style={{ background: addVideoHover ? '#D5D5D5 ' : 'white' }}
                          >
                            <IconButton
                              onClick={() => setCardAdd(!cardAdd)}
                              size='small'
                              sx={{ background: 'white', marginRight: '7px' }}
                            >
                              <AddCircleOutlineIcon
                                sx={{ fontSize: '30px', color: addVideoHover ? 'black' : ' ' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <Box
                          padding={0}
                          display={cardAdd ? 'flex' : 'none'}
                          alignSelf={'center'}
                          flexDirection={'column'}
                        >
                          <Button
                            variant='contained'
                            size='small'
                            sx={{ textTransform: 'none', background: '#8E8E8E' }}
                            onClick={() => onAddBtnClick()}
                          >
                            <Typography style={{ whiteSpace: 'nowrap' }}>Content Page</Typography>
                          </Button>
                          <Button
                            variant='contained'
                            size='small'
                            sx={{ textTransform: 'none', background: '#8E8E8E', marginTop: '10px' }}
                            onClick={() => onAddBtnClickQuestion()}
                          >
                            <Typography style={{ whiteSpace: 'nowrap' }}>Question Page</Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )}

                {
                  //activityLearningCardContent end
                }
                {
                  //activityLearningCardPreview start
                }
                <Grid
                  xs={12}
                  container
                  justifyContent={'center'}
                  textAlign={'center'}
                  marginTop={3}
                  marginBottom={2}
                  padding={1}
                  display={step === 3 ? '' : 'none'}
                  columnGap={2}
                  sx={{ overflowX: 'auto', width: '100%', display: 'flex' }}
                >
                  <Box display={step === 3 ? 'flex' : 'none'} columnGap={1} padding={0.8}>
                    {learningCard?.map((item: any, index: number) =>
                      item.question === false ? (
                        <Box padding={0.8} sx={{ borderRadius: '10px', background: 'black' }}>
                          <img
                            alt='phoneHeader'
                            src={toAbsoluteUrl('/media/stock/phoneHeader.png')}
                            width={'300px'}
                            height={'30px'}
                          />
                          <Card sx={{ width: 350, height: 600 }}>
                            {/*  <Box
                              height={'197px'}
                              width={350}
                              padding={0}
                              sx={{objectFit: 'contain'}}
                            >
                            </Box> */}
                            <CardContent style={{ marginTop: 5, padding: 0, width: '100%' }}>
                              <CardHeader
                                style={{ margin: 0, padding: '5px' }}
                                avatar={
                                  <Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
                                    {activityName.charAt(0).toUpperCase()}
                                  </Avatar>
                                }
                                title={activityName}
                                subheader={categoryName}
                              />
                              {item.mediaType === 4 ? (
                                <Emoji
                                  unified={item.mediaUrl}
                                  emojiStyle={EmojiStyle.APPLE}
                                  size={150}
                                />
                              ) : item.mediaType === 2 ? (
                                <ReactPlayer
                                  controls={true}
                                  url={item.mediaUrl}
                                  height={'150px'}
                                  width={'100%'}
                                />
                              ) : item.mediaUrl === '' ? (
                                <div style={{ height: '150px', width: '100%' }}></div>
                              ) : (
                                <img height='150' src={item.mediaUrl} width={'100%'} />
                              )}
                              <Box padding={1} width={'95%'} height={100} overflow={'auto'}>
                                <div
                                  style={{
                                    overflowWrap: 'break-word',
                                    wordWrap: 'break-word',
                                    hyphens: 'auto',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  {item.takeaway}
                                </div>
                              </Box>

                              <div style={{ height: '276px', width: '350px', padding: '5px' }}>
                                {item.description && (
                                  /*   <Paper
                                    key={index}
                                    elevation={2}
                                    sx={{
                                      borderRadius: '10px',
                                      width: '98%',
                                      height: '59px',
                                      display: 'flex',
                                      justifyContent: 'start',
                                    }}
                                  > */
                                  <Accordion
                                    style={{ width: '100%', display: 'block' }}
                                    defaultActiveKey='1'
                                  >
                                    <Accordion.Item eventKey='0'>
                                      <Accordion.Header className='custom-header'>
                                        Click to see details
                                      </Accordion.Header>
                                      <Accordion.Body
                                        style={{
                                          maxHeight: '370px',
                                          overflow: 'auto',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          rowGap: '10px',
                                          overflowWrap: 'break-word',
                                          wordWrap: 'break-word',
                                          hyphens: 'auto',
                                          whiteSpace: 'pre-wrap',
                                        }}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{ __html: item.htmlDescription }}
                                        ></div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                  /*  </Paper> */
                                )}
                              </div>
                            </CardContent>

                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <Tooltip
                                arrow
                                title='Page Number'
                                placement='top'
                                style={{
                                  background: '#FF0000DE',
                                  marginTop: '-3px',
                                  marginRight: '-5px',
                                  opacity: '0.5',
                                  width: '26.25px',
                                  height: '26.25px',
                                }}
                              >
                                <IconButton
                                  aria-label='pageNumber'
                                  disabled={true}
                                  size='small'
                                  sx={{ marginRight: '7px', color: 'white!important' }}
                                >
                                  {index + 1}
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Card>
                        </Box>
                      ) : (
                        //questionCard
                        <Box padding={0.8} sx={{ borderRadius: '10px', background: 'black' }}>
                          <img
                            alt='phoneHeader'
                            src={toAbsoluteUrl('/media/stock/phoneHeader.png')}
                            width={'300px'}
                            height={'30px'}
                          />
                          <Card sx={{ width: 350, height: 600 }}>
                            <Box
                              height={'580px'}
                              width={350}
                              padding={1}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                rowGap: 2,
                              }}
                            >
                              <Paper
                                sx={{ display: 'flex', height: '150px', padding: 0, width: '100%' }}
                              >
                                <div
                                  style={{
                                    width: '100%',
                                    background: 'transparent',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography color={'#ADB5BD'}>
                                    {item.questionDescription}
                                  </Typography>
                                </div>
                                {/*   <SelectQuestion
                                customer={undefined}
                                changeQuestion={changeQuestion}
                                index={index}
                                category={category}
                              /> */}
                              </Paper>
                              {item.questionAnswers?.length > 0 && (
                                <Box
                                  style={{
                                    maxHeight: '370px',
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '10px',
                                    width: '100%',
                                  }}
                                  padding={1}
                                >
                                  {item.questionAnswers.map((item: any, index: number) => (
                                    <Paper
                                      key={index}
                                      elevation={2}
                                      sx={{
                                        borderRadius: '10px',
                                        width: '98%',
                                        height: '59px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                      }}
                                    >
                                      <Checkbox
                                        disabled={true}
                                        checked={item.isCorrect === true ? true : false}
                                      />
                                      <Typography>{item.description}</Typography>
                                    </Paper>
                                  ))}
                                </Box>
                              )}
                            </Box>

                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <Tooltip
                                arrow
                                title='Page Number'
                                placement='top'
                                style={{
                                  background: '#FF0000DE',
                                  marginTop: '-2px',
                                  marginRight: '-5px',
                                  opacity: '0.5',
                                  width: '26.25px',
                                  height: '26.25px',
                                }}
                              >
                                <IconButton
                                  aria-label='pageNumber'
                                  disabled={true}
                                  size='small'
                                  sx={{ marginRight: '7px', color: 'white!important' }}
                                >
                                  {index + 1}
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Card>
                        </Box>
                      )
                    )}
                  </Box>
                </Grid>
                {
                  //activityLearningCardPreview end
                }
              </Grid>

              <Grid xs={12}>
                <Divider variant='middle' sx={{ borderTop: '1px solid black' }} />
                <Box
                  display={'flex'}
                  padding={0}
                  marginTop={'10px'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ width: '100%' }}
                >
                  <Button
                    variant='contained'
                    color='inherit'
                    sx={{ marginRight: '20px', marginLeft: '9%' }}
                    onClick={() => setDiscardWarnModal(true)}
                  >
                    Discard
                  </Button>
                  <div style={{ marginRight: '6%' }}>
                    <Button
                      variant='contained'
                      color='inherit'
                      sx={{ marginRight: '20px' }}
                      disabled={usedJourney}
                      onClick={() =>
                        step !== 0 ? setStep(step - 1) : editActivity === 0 && setActivityType(0)
                      }
                    >
                      BACK
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ marginRight: '40px' }}
                      disabled={
                        usedJourney ? true :
                          step === 0
                            ? activityName.length < 2 || nameCheck
                            : step === 1
                              ? category == 0
                              : step === 2
                                ? !hasContentPage() ||
                                !hasQuestionPage() ||
                                !learningCard.every((card: any) => {
                                  if (card.question) {
                                    return card.questionId !== 0 && card.questionId !== null
                                  } else {
                                    if (card.mediaUrl !== '') {
                                      return card.takeaway !== ''
                                    } else {
                                      return card.htmlDescription !== '' && card.takeaway !== ''
                                    }
                                  }
                                })
                                : false
                      }
                      onClick={() => (
                        step < 3 && setStep(step + 1),
                        step === 3 && selectedActivity === 0
                          ? handleSaveLearningCard()
                          : step === 3 && handleSaveEditLearningCard()
                      )}
                    >
                      {selectedActivity === 0
                        ? step === 3
                          ? 'Save'
                          : 'NEXT'
                        : step === 3
                          ? 'EDIT'
                          : 'NEXT'}
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Grid>
            {
              //Game
            }
            <Grid container display={activityType >= 2 && activityType <= 5 ? 'block' : 'none'}>
              <Grid xs={12} minHeight={'450px'}>
                <Box sx={{ width: '100%', paddingLeft: "5%", paddingRight: "5%" }}>
                  <Stepper activeStep={stepGame} /* alternativeLabel */>
                    {stepsGame.map((label) => (
                      <Step
                        key={label}
                        sx={{
                          '& .MuiStepIcon-root': {
                            fontSize: '25px',
                            color: '#9E9E9E', // Varsayılan renk
                            fontWeight: 'bold',
                          },
                          '& .Mui-active, & .Mui-completed': {
                            color: '#9c27b0 !important',
                          },
                        }}
                      >
                        <StepLabel
                          sx={{
                            '& .MuiStepLabel-label': {
                              fontSize: '15px', // Label'ların font boyutunu ayarlayın
                            },
                            '& .Mui-active': {
                              fontWeight: 'bold',
                            },
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                {
                  //activityName start
                }
                <Grid
                  xs={12}
                  container
                  justifyItems={'center'}
                  textAlign={'center'}
                  marginTop={10}
                  display={stepGame === 0 ? 'block' : 'none'}
                >
                  <Box sx={{ width: '100%' }}>
                    <Typography variant='h6' color={'#8E8E8E'}>
                      Give a name for your activity.
                      <br /> You can prefer to choose topic of the activity as name.
                    </Typography>
                    <TextField
                      id='outlined-basic'
                      label='Enter a name'
                      placeholder='Min 2 - Max 24 character'
                      variant='outlined'
                      value={activityName}
                      onChange={(e) => {
                        if (e.target.value.length <= 24) {
                          setNameCheck(false)
                          setActivityName(e.target.value)
                          if (timer) clearTimeout(timer)
                          // 2 saniye sonra kontrolü tetikle
                          const newTimer = setTimeout(
                            () => checkActivityName(activityName, activityType),
                            2000
                          )
                          setTimer(newTimer)
                        }
                      }}
                      fullWidth
                      style={{ marginTop: '20px', width: '300px' }}
                      error={nameCheck}
                      helperText={nameCheck ? 'Name exists. Choose another one' : ''}
                      InputProps={{
                        endAdornment: nameCheckLoading && (
                          <InputAdornment position='end'>
                            <CircularProgress size={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                {
                  //activityName end
                }
                {
                  //activitySelectCategory start
                }
                {stepGame === 1 && (
                  <Grid
                    xs={12}
                    container
                    justifyItems={'center'}
                    textAlign={'center'}
                    marginTop={1}
                  /* display={stepGame === 1 ? '' : 'none'} */
                  >
                    {
                      //categories
                    }
                    <Grid xs={8}>
                      {loadingCategories && (
                        <Box
                          sx={{ width: '100%' }}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <CircularProgress size={'30px'} color='primary' />
                        </Box>
                      )}
                      {loadingCategories === false && (
                        <Box sx={{ width: '100%' }}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs1
                              value={tabValue}
                              onChange={handleChangeTabMenu}
                              aria-label='basic tabs example'
                              /* centered */
                              sx={{
                                marginTop: 2,
                                minHeight: '40px', // Tabs bileşeninin min yüksekliğini ayarlayın
                                '& .MuiTabs-flexContainer': {
                                  minHeight: '40px',
                                },
                                '& .MuiTab-root': {
                                  minHeight: '30px', // Her bir Tab bileşeninin min yüksekliğini ayarlayın
                                  padding: '3px 6px', // Daha dar bir görünüm için padding'i azaltın
                                  fontSize: '15px', // İsteğe bağlı olarak font boyutunu ayarlayın
                                },
                              }}
                            >
                              <Tab1
                                icon={tabValue === 0 && <CheckCircleIcon />}
                                iconPosition='start'
                                label={customerProfile?.customerName + ' Category '}
                                {...a11yProps(0)}
                              />
                              <Tab1
                                icon={tabValue === 1 && <CheckCircleIcon />}
                                iconPosition='start'
                                sx={{ marginLeft: '5px' }}
                                label=' Mobixa Category '
                                {...a11yProps(1)}
                              />
                            </Tabs1>
                          </Box>
                          <CustomTabPanel value={tabValue} index={0}>
                            {/* <Box width={'100%'} marginTop={-2} marginBottom={1}>
                              {' '}
                              <Typography fontSize={14} fontWeight={'bold'}>
                                Select Max 5 categories
                              </Typography>
                            </Box> */}
                            <div
                              className=' d-flex card-body pt-0'
                              style={{
                                overflowY: 'scroll',
                                overflowX: 'scroll',
                                minHeight: '500px',
                                height: '500px',
                              }}
                            >
                              {customerCategory?.map((data: any) => (
                                <>
                                  <div>
                                    {
                                      //1.katman
                                    }
                                    <AccordionMui
                                      style={{
                                        marginRight: 20,
                                        borderRadius: '10px 10px 5px 5px',
                                        border: '1px solid #d5d5d5',
                                        minWidth: '300px',
                                      }}
                                      defaultExpanded={true}
                                    >
                                      <AccordionSummary
                                        style={{
                                          background: '#D5D5D5',
                                          borderRadius: '10px 10px 0px 0px',
                                          padding: '0px 10px 0px 0px',
                                          minHeight: '60px',
                                        }}
                                      >
                                        <div
                                          className='d-flex '
                                          style={{ alignItems: 'center', height: 20 }}
                                        >
                                          <div>
                                            <FormControlLabel
                                              control={
                                                <Android12Switch
                                                  defaultChecked
                                                  color='secondary'
                                                  size='medium'
                                                  value={data.categoryId}
                                                  disabled={
                                                    data.questionCount === 0
                                                      ? true
                                                      : gameCategoryList.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                        ? false
                                                        : gameCategoryList.categoryList.length <= 9
                                                          ? false
                                                          : /* true */false
                                                  }
                                                  checked={gameCategoryList.categoryList?.some(
                                                    (category: any) =>
                                                      category.categoryId === data.categoryId
                                                  )}
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (
                                                      !gameCategoryList.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                    ) {
                                                    /*   if (gameCategoryList.categoryList.length <= 9) { */
                                                        handleAddCategoryGame(
                                                          data.categoryId,
                                                          data.name,
                                                          data.questionCount
                                                        )
                                                     /*  } */
                                                    } else {
                                                      handleDeleteCategoryGame(data.categoryId)
                                                    }
                                                    e.preventDefault()
                                                  }}
                                                />
                                              }
                                              label=''
                                            />
                                          </div>

                                          <div
                                            style={{
                                              fontWeight: 'bold',
                                              cursor: 'pointer',
                                              fontSize: '12px',
                                              marginLeft: '-4px',
                                              textAlign: "start"
                                            }}
                                          >
                                            {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                              data.name.slice(1)}
                                          </div>
                                          <span
                                            title='Number of awailable question'
                                            className='text-dark  ms-2'
                                            style={{
                                              fontSize: '10px',
                                              color: '#8e8e8e',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            ({data.questionCount})
                                          </span>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails style={{ padding: '3px' }}>
                                        {
                                          //2. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? (
                                            <>
                                              <AccordionMui
                                                style={{
                                                  marginTop: '5px',
                                                  marginBottom: '5px',
                                                  boxShadow: 'none',
                                                  borderRadius: '10px 10px 0px 0px',
                                                }}
                                                defaultExpanded={true}
                                              >
                                                <AccordionSummary
                                                  sx={{
                                                    margin: '0px!important',
                                                    '.MuiAccordionSummary-content': {
                                                      // AccordionSummary'nin içeriği için stil
                                                      marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                                    },
                                                    '&:hover': {
                                                      backgroundColor: '#e7e7e7', // Hover durumu için arka plan rengi
                                                    },
                                                  }}
                                                  style={{
                                                    borderRadius: '10px 10px 0px 0px',
                                                    padding: '0px 5px 0px 0px',
                                                    minHeight: '35px',
                                                    marginLeft: '0px!important',
                                                  }}
                                                >
                                                  <div
                                                    className='d-flex'
                                                    style={{
                                                      alignItems: 'center',
                                                      justifyItems: 'start',
                                                      height: 20,
                                                    }}
                                                  >
                                                    <div>
                                                      <FormControlLabel
                                                        control={
                                                          <Android12Switch
                                                            defaultChecked
                                                            color='secondary'
                                                            size='medium'
                                                            value={child.categoryId}
                                                            disabled={
                                                              child.questionCount === 0
                                                                ? true
                                                                : gameCategoryList.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                                  ? false
                                                                  : gameCategoryList.categoryList.length <= 9
                                                                    ? false
                                                                    : /* true */ false
                                                            }
                                                            checked={gameCategoryList.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )}
                                                            onClick={(e) => {
                                                              if (
                                                                !gameCategoryList.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                              ) {
                                                              /*   if (
                                                                  gameCategoryList.categoryList.length <= 9
                                                                ) { */
                                                                  handleAddCategoryGame(
                                                                    child.categoryId,
                                                                    child.name,
                                                                    child.questionCount
                                                                  )
                                                                /* } */
                                                              } else {
                                                                handleDeleteCategoryGame(
                                                                  child.categoryId
                                                                )
                                                              }
                                                              e.stopPropagation()
                                                            }}
                                                          />
                                                        }
                                                        label=''
                                                      />
                                                    </div>
                                                    <span
                                                      className={'text-gray-800 d-flex'}
                                                      style={{
                                                        fontWeight: 'bold',
                                                        cursor: 'pointer',
                                                        fontSize: '12px',
                                                        marginLeft: '-4px',
                                                        textAlign: "start"
                                                      }}
                                                    >
                                                      {child.name.charAt(0).toUpperCase() +
                                                        child.name.slice(1)}
                                                    </span>
                                                    <span
                                                      title='Number of awailable question'
                                                      className='text-dark text-muted ms-2'
                                                      style={{
                                                        fontSize: '10px',
                                                        color: '#8e8e8e',
                                                        fontWeight: 'normal',
                                                      }}
                                                    >
                                                      ({child.questionCount})
                                                    </span>
                                                  </div>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ padding: '3px' }}>
                                                  {child?.children?.map((child: any) => (
                                                    <div
                                                      id={child.categoryId}
                                                      className='ms-0 mt-3 '
                                                    >
                                                      <div
                                                        className='d-flex div-element-class'
                                                        style={{
                                                          alignItems: 'center',
                                                          height: 35,
                                                          paddingLeft: 32,
                                                        }}
                                                      >
                                                        <div>
                                                          <FormControlLabel
                                                            control={
                                                              <Android12Switch
                                                                defaultChecked
                                                                color='secondary'
                                                                size='medium'
                                                                value={child.categoryId}
                                                                disabled={
                                                                  child.questionCount === 0
                                                                    ? true
                                                                    : gameCategoryList.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                      ? false
                                                                      : gameCategoryList.categoryList.length <= 9
                                                                        ? false
                                                                        : /* true */false
                                                                }
                                                                checked={gameCategoryList.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )}
                                                                onClick={(e) => {
                                                                  if (
                                                                    !gameCategoryList.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                  ) {
                                                                   /*  if (
                                                                      gameCategoryList.categoryList
                                                                        .length <= 9
                                                                    ) { */
                                                                      handleAddCategoryGame(
                                                                        child.categoryId,
                                                                        child.name,
                                                                        child.questionCount
                                                                      )
                                                                 /*    } */
                                                                  } else {
                                                                    handleDeleteCategoryGame(
                                                                      child.categoryId
                                                                    )
                                                                  }
                                                                  e.stopPropagation()
                                                                }}
                                                              />
                                                            }
                                                            label=''
                                                          />

                                                        </div>
                                                        <a
                                                          className={'text-gray-800 d-flex '}
                                                          style={{
                                                            cursor: 'pointer',
                                                            fontSize: '12px',
                                                            alignItems: 'center',
                                                            maxWidth: '210px',
                                                            marginLeft: '-4px',
                                                            textAlign: "start"
                                                          }}
                                                        >
                                                          {child.name
                                                            .charAt(0)
                                                            .toUpperCase()
                                                            .replace('🅜', '') + child.name.slice(1)}
                                                        </a>

                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '20px',
                                                          }}
                                                        >
                                                          <span
                                                            title='Number of awailable question'
                                                            className='ms-2'
                                                            style={{
                                                              color: '#8e8e8e',
                                                              fontSize: '10px',
                                                            }}
                                                          >
                                                            {`(${child.questionCount})`}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </AccordionDetails>
                                              </AccordionMui>
                                            </>
                                          ) : null
                                        )}
                                        {
                                          // 3. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? null : (
                                            <div id={child.categoryId} className='ms-0 mt-3 '>
                                              <div
                                                className='d-flex border-1 div-element-class'
                                                style={{
                                                  alignItems: 'center',
                                                  height: 35,
                                                  paddingLeft: 4,
                                                }}
                                              >
                                                <div>
                                                  <FormControlLabel
                                                    control={
                                                      <Android12Switch
                                                        defaultChecked
                                                        checked={gameCategoryList.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )}
                                                        disabled={
                                                          child.questionCount === 0
                                                            ? true
                                                            : gameCategoryList.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                              ? false
                                                              : gameCategoryList.categoryList.length <= 9
                                                                ? false
                                                                : /* true */false
                                                        }
                                                        value={child.categoryId}
                                                        color='secondary'
                                                        onClick={(e) => {
                                                          if (
                                                            !gameCategoryList.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                          ) {
                                                           /*  if (
                                                              gameCategoryList.categoryList.length <= 9
                                                            ) { */
                                                              handleAddCategoryGame(
                                                                child.categoryId,
                                                                child.name,
                                                                child.questionCount
                                                              )
                                                         /*    } */
                                                          } else {
                                                            handleDeleteCategoryGame(child.categoryId)
                                                          }
                                                          e.stopPropagation()
                                                        }}
                                                        size='medium'
                                                      />
                                                    }
                                                    label=''
                                                  />

                                                </div>
                                                <a
                                                  className={'text-gray-800 d-flex'}
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: '12px',
                                                    maxWidth: '210px',
                                                    marginLeft: '-4px',
                                                    textAlign: "start"
                                                  }}
                                                >
                                                  {child.name.charAt(0).toUpperCase() +
                                                    child.name.slice(1)}
                                                </a>

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '20px',
                                                  }}
                                                >
                                                  <span
                                                    title='Number of awailable question'
                                                    className='ms-2'
                                                    style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                  >
                                                    {`(${child.questionCount})`}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </AccordionDetails>
                                    </AccordionMui>
                                  </div>
                                </>
                              ))}
                            </div>
                          </CustomTabPanel>
                          <CustomTabPanel value={tabValue} index={1}>
                            {/*  <Box width={'100%'} marginTop={-2} marginBottom={1}>
                              {' '}
                              <Typography fontSize={14} fontWeight={'bold'}>
                                Select Max 5 categories
                              </Typography>
                            </Box> */}
                            <div
                              className=' d-flex card-body pt-0'
                              style={{
                                overflowY: 'scroll',
                                overflowX: 'scroll',
                                minHeight: '500px',
                                height: '500px',
                              }}
                            >
                              {mobixaCategory?.map((data: any) => (
                                <>
                                  <div>
                                    {
                                      //1.katman
                                    }
                                    <AccordionMui
                                      style={{
                                        marginRight: 20,
                                        borderRadius: '10px 10px 5px 5px',
                                        border: '1px solid #d5d5d5',
                                        minWidth: '300px',
                                      }}
                                      defaultExpanded={true}
                                    >
                                      <AccordionSummary
                                        /* expandIcon={
                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                } */
                                        style={{
                                          background: '#D5D5D5',
                                          borderRadius: '10px 10px 0px 0px',
                                          padding: '0px 10px 0px 0px',
                                          minHeight: '60px',
                                        }}
                                      >
                                        <div
                                          className='d-flex '
                                          style={{ alignItems: 'center', height: 20 }}
                                        >
                                          <div>
                                            <FormControlLabel
                                              control={
                                                <Android12Switch
                                                  defaultChecked
                                                  color='secondary'
                                                  size='medium'
                                                  value={data.categoryId}
                                                  disabled={
                                                    data.questionCount === 0
                                                      ? true
                                                      : gameCategoryList.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                        ? false
                                                        : gameCategoryList.categoryList.length <= 9
                                                          ? false
                                                          : /* true */false
                                                  }
                                                  checked={gameCategoryList.categoryList?.some(
                                                    (category: any) =>
                                                      category.categoryId === data.categoryId
                                                  )}
                                                  onClick={(e) => {
                                                    e.stopPropagation()

                                                    if (
                                                      !gameCategoryList.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                    ) {
                                                      /* if (gameCategoryList.categoryList.length <= 9) { */
                                                        handleAddCategoryGame(
                                                          data.categoryId,
                                                          data.name,
                                                          data.questionCount
                                                        )
                                                    /*   } */
                                                    } else {
                                                      handleDeleteCategoryGame(data.categoryId)
                                                    }
                                                    e.preventDefault()
                                                  }}
                                                />
                                              }
                                              label=''
                                            />

                                          </div>

                                          <div
                                            style={{
                                              fontWeight: 'bold',
                                              cursor: 'pointer',
                                              fontSize: '12px',
                                              marginLeft: '-4px',
                                              textAlign: "start"
                                            }}
                                          >
                                            {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                              data.name.slice(1)}
                                          </div>
                                          <span
                                            title='Number of awailable question'
                                            className='text-dark  ms-2'
                                            style={{
                                              fontSize: '10px',
                                              color: '#8e8e8e',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            ({data.questionCount})
                                          </span>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails style={{ padding: '3px' }}>
                                        {
                                          //2. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? (
                                            <>
                                              <AccordionMui
                                                style={{
                                                  marginTop: '5px',
                                                  marginBottom: '5px',
                                                  boxShadow: 'none',
                                                  borderRadius: '10px 10px 0px 0px',
                                                }}
                                                defaultExpanded={true}
                                              >
                                                <AccordionSummary
                                                  sx={{
                                                    margin: '0px!important',
                                                    '.MuiAccordionSummary-content': {
                                                      // AccordionSummary'nin içeriği için stil
                                                      marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                                    },
                                                    '&:hover': {
                                                      backgroundColor: '#e7e7e7', // Hover durumu için arka plan rengi
                                                    },
                                                  }}
                                                  style={{
                                                    borderRadius: '10px 10px 0px 0px',
                                                    padding: '0px 5px 0px 0px',
                                                    minHeight: '35px',
                                                    marginLeft: '0px!important',
                                                  }}
                                                >
                                                  <div
                                                    className='d-flex'
                                                    style={{
                                                      alignItems: 'center',
                                                      justifyItems: 'start',
                                                      height: 20,
                                                    }}
                                                  >
                                                    <div>
                                                      <FormControlLabel
                                                        control={
                                                          <Android12Switch
                                                            defaultChecked
                                                            color='secondary'
                                                            size='medium'
                                                            value={child.categoryId}
                                                            disabled={
                                                              child.questionCount === 0
                                                                ? true
                                                                : gameCategoryList.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                                  ? false
                                                                  : gameCategoryList.categoryList.length <= 9
                                                                    ? false
                                                                    : /* true */false
                                                            }
                                                            checked={gameCategoryList.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )}
                                                            onClick={(e) => {
                                                              if (
                                                                !gameCategoryList.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                              ) {
                                                               /*  if (
                                                                  gameCategoryList.categoryList.length <= 9
                                                                ) { */
                                                                  handleAddCategoryGame(
                                                                    child.categoryId,
                                                                    child.name,
                                                                    child.questionCount
                                                                  )
                                                               /*  } */
                                                              } else {
                                                                handleDeleteCategoryGame(
                                                                  child.categoryId
                                                                )
                                                              }
                                                              e.stopPropagation()
                                                            }}
                                                          />
                                                        }
                                                        label=''
                                                      />
                                                    </div>
                                                    <span
                                                      className={'text-gray-800 d-flex'}
                                                      style={{
                                                        fontWeight: 'bold',
                                                        cursor: 'pointer',
                                                        fontSize: '12px',
                                                        marginLeft: '-4px',
                                                        textAlign: "start"
                                                      }}
                                                    >
                                                      {child.name.charAt(0).toUpperCase() +
                                                        child.name.slice(1)}
                                                    </span>
                                                    <span
                                                      title='Number of awailable question'
                                                      className='text-dark text-muted ms-2'
                                                      style={{
                                                        fontSize: '10px',
                                                        color: '#8e8e8e',
                                                        fontWeight: 'normal',
                                                      }}
                                                    >
                                                      ({child.questionCount})
                                                    </span>
                                                  </div>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ padding: '3px' }}>
                                                  {child?.children?.map((child: any) => (
                                                    <div
                                                      id={child.categoryId}
                                                      className='ms-0 mt-3 '
                                                    >
                                                      <div
                                                        className='d-flex div-element-class'
                                                        style={{
                                                          alignItems: 'center',
                                                          height: 35,
                                                          paddingLeft: 32,
                                                        }}
                                                      >
                                                        <div>
                                                          <FormControlLabel
                                                            control={
                                                              <Android12Switch
                                                                defaultChecked
                                                                color='secondary'
                                                                size='medium'
                                                                value={child.categoryId}
                                                                disabled={
                                                                  child.questionCount === 0
                                                                    ? true
                                                                    : gameCategoryList.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                      ? false
                                                                      : gameCategoryList.categoryList.length <= 9
                                                                        ? false
                                                                        : /* true */false
                                                                }
                                                                checked={gameCategoryList.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )}
                                                                onClick={(e) => {
                                                                  if (
                                                                    !gameCategoryList.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                  ) {
                                                                    /* if (
                                                                      gameCategoryList.categoryList
                                                                        .length <= 9
                                                                    ) { */
                                                                      handleAddCategoryGame(
                                                                        child.categoryId,
                                                                        child.name,
                                                                        child.questionCount
                                                                      )
                                                                   /*  } */
                                                                  } else {
                                                                    handleDeleteCategoryGame(
                                                                      child.categoryId
                                                                    )
                                                                  }
                                                                  e.stopPropagation()
                                                                }}
                                                              />
                                                            }
                                                            label=''
                                                          />

                                                        </div>
                                                        <a
                                                          className={'text-gray-800 d-flex '}
                                                          style={{
                                                            cursor: 'pointer',
                                                            fontSize: '12px',
                                                            alignItems: 'center',
                                                            maxWidth: '210px',
                                                            marginLeft: '-4px',
                                                            textAlign: "start"
                                                          }}
                                                        >
                                                          {child.name
                                                            .charAt(0)
                                                            .toUpperCase()
                                                            .replace('🅜', '') + child.name.slice(1)}
                                                        </a>

                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '20px',
                                                          }}
                                                        >
                                                          <span
                                                            title='Number of awailable question'
                                                            className='ms-2'
                                                            style={{
                                                              color: '#8e8e8e',
                                                              fontSize: '10px',
                                                            }}
                                                          >
                                                            {`(${child.questionCount})`}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </AccordionDetails>
                                              </AccordionMui>
                                            </>
                                          ) : null
                                        )}
                                        {
                                          // 3. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? null : (
                                            <div id={child.categoryId} className='ms-0 mt-3 '>
                                              <div
                                                className='d-flex border-1 div-element-class'
                                                style={{
                                                  alignItems: 'center',
                                                  height: 35,
                                                  paddingLeft: 4,
                                                }}
                                              >
                                                <div>
                                                  <FormControlLabel
                                                    control={
                                                      <Android12Switch
                                                        defaultChecked
                                                        checked={gameCategoryList.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )}
                                                        disabled={
                                                          child.questionCount === 0
                                                            ? true
                                                            : gameCategoryList.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                              ? false
                                                              : gameCategoryList.categoryList.length <= 9
                                                                ? false
                                                                : /* true */false
                                                        }
                                                        value={child.categoryId}
                                                        color='secondary'
                                                        onClick={(e) => {
                                                          if (
                                                            !gameCategoryList.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                          ) {
                                                            /*   if (
                                                                gameCategoryList.categoryList.length <= 9
                                                              ) { */
                                                            handleAddCategoryGame(
                                                              child.categoryId,
                                                              child.name,
                                                              child.questionCount
                                                            )
                                                            /*  } */
                                                          } else {
                                                            handleDeleteCategoryGame(child.categoryId)
                                                          }
                                                          e.stopPropagation()
                                                        }}
                                                        size='medium'
                                                      />
                                                    }
                                                    label=''
                                                  />

                                                </div>
                                                <a
                                                  className={'text-gray-800 d-flex'}
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: '12px',
                                                    maxWidth: '210px',
                                                    marginLeft: '-4px',
                                                    textAlign: "start"
                                                  }}
                                                >
                                                  {child.name.charAt(0).toUpperCase() +
                                                    child.name.slice(1)}
                                                </a>

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '20px',
                                                  }}
                                                >
                                                  <span
                                                    title='Number of awailable question'
                                                    className='ms-2'
                                                    style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                  >
                                                    {`(${child.questionCount})`}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </AccordionDetails>
                                    </AccordionMui>
                                  </div>
                                </>
                              ))}
                            </div>
                          </CustomTabPanel>
                        </Box>
                      )}
                    </Grid>
                    {
                      //selectedCategory
                    }
                    {gameCategoryList.categoryList.length > 0 && (
                      <Grid
                        xs={4}
                        container
                        marginTop={3}
                        justifyContent={'center'}
                        alignContent={'center'}
                      >
                        <Box>
                          <Typography color={'#8E8E8E'}>Selected Categories</Typography>
                          <Paper elevation={2} style={{ height: 'auto', maxHeight: 360, overflowX: "scroll", width: '330px' }}>
                            {gameCategoryList.categoryList?.map((category: any, index: number) => (
                              <div style={{ display: 'flex' }}>
                                <Box
                                  width={'330px'}
                                  height={'50px'}
                                  padding={1}
                                  margin={1}
                                  marginLeft={'10px'}
                                  sx={{
                                    background: mobixaCategory.some((item: any) => {
                                      if (item.categoryId === category.categoryId) {
                                        return true
                                      } else {
                                        return item.children.some((child: any) => {
                                          if (child.categoryId === category.categoryId) {
                                            return true
                                          } else {
                                            return child.children.some((grandchild: any) => {
                                              return grandchild.categoryId === category.categoryId
                                            })
                                          }
                                        })
                                      }
                                    })
                                      ? '#F7D9E4'
                                      : '#EEF6FF',
                                    borderRadius: '10px',
                                  }}
                                  display={'flex'}
                                  justifyContent={'space-between'}
                                  alignItems={'center'}
                                >
                                  <Typography fontSize={'14px'}>{index + 1}.</Typography>
                                  <Typography fontSize={'14px'} width={'100%'}>
                                    {category.categoryName}
                                  </Typography>
                                  <Box
                                    width={'100px'}
                                    height={'40px'}
                                    marginLeft={'10px'}
                                    sx={{
                                      borderRadius: '10px',
                                    }}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                  >
                                    <TextField
                                      fullWidth
                                      size='small'
                                      type='number'
                                      style={{ background: 'white' }}
                                      label={
                                        category.questionCount <= 999
                                          ? `Max ${category.questionCount}.`
                                          : `Max 999.`
                                      }
                                      value={category.selectedQuestionCount}
                                      onChange={(e) => {
                                        if (
                                          e.target.value <= category.questionCount &&
                                          parseInt(e.target.value) > 0
                                        ) {
                                          if (category.selectedQuestionCount <= 999) {
                                            setGameCategoryList((prevCreateTest: any) => ({
                                              ...prevCreateTest,
                                              categoryList: prevCreateTest.categoryList.map(
                                                (categoryList: any) => {
                                                  if (
                                                    category.categoryId === categoryList.categoryId
                                                  ) {
                                                    return {
                                                      ...categoryList,
                                                      selectedQuestionCount: parseInt(
                                                        e.target.value
                                                      ),
                                                    }
                                                  }
                                                  return categoryList
                                                }
                                              ),
                                            }))
                                          } else {
                                            setGameCategoryList((prevCreateTest: any) => ({
                                              ...prevCreateTest,
                                              categoryList: prevCreateTest.categoryList.map(
                                                (categoryList: any) => {
                                                  if (
                                                    category.categoryId === categoryList.categoryId
                                                  ) {
                                                    return {
                                                      ...categoryList,
                                                      selectedQuestionCount: 999,
                                                    }
                                                  }
                                                  return categoryList
                                                }
                                              ),
                                            }))
                                          }
                                        }
                                      }}
                                      inputProps={{
                                        max:
                                          category.questionCount >= 999
                                            ? 999
                                            : category.questionCount,
                                        min: 1,
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <>
                                  <IconButton
                                    color='error'
                                    onClick={() => (
                                      handleDeleteCategoryGame(category.categoryId),
                                      setSelectCategoryDataGrid(0)
                                    )}
                                  >
                                    <DeleteForeverRoundedIcon />
                                  </IconButton>
                                </>
                              </div>
                            ))}
                          </Paper>
                          <Box marginTop={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                            {/*   <Box marginTop={1} display={'flex'} justifyContent={"space-between"} alignItems={'center'}>
                              <Typography>Unique Questions</Typography>
                              <Typography fontWeight={'bold'} marginRight={2}>
                                {gameCategoryList.categoryList.reduce((total: number, category: any) => {
                                  return total + category.selectedQuestionCount
                                }, 0)}
                              </Typography>
                            </Box> */}
                            {/* <Box marginTop={1} display={'flex'} justifyContent={"space-between"} alignItems={'center'}>
                              <Typography>Repeat</Typography>
                              <TextField
                                id="filled-select-currency"
                                select
                                defaultValue="1"
                                variant="outlined"
                                size='small'
                                onChange={(e) => setRepeatGame(Number(e.target.value))}
                                value={repeatGame.toString()}

                              >
                                <MenuItem key={1} value={1}>
                                  1
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                  2
                                </MenuItem>
                                <MenuItem key={3} value={3}>
                                  3
                                </MenuItem>

                              </TextField>
                            </Box>
                            <Divider variant='fullWidth' sx={{ width: "100%", borderBottom: 1, color: "#BCBCBC", my: 1 }} /> */}
                            <Box marginTop={1} display={'flex'} justifyContent={"space-between"} alignItems={'center'}>
                              <Typography>Total nr. of questions to be asked</Typography>
                              <Typography fontWeight={'bold'} marginRight={2}>
                                {gameCategoryList.categoryList.reduce((total: number, category: any) => {
                                  return total + category.selectedQuestionCount
                                }, 0) /* * repeatGame */}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                )}
                {
                  //activitySelectCategory end
                }
                {
                  //activityGame Icon start
                }
                <Grid container display={stepGame === 2 ? '' : 'none'} alignItems={'center'}>
                  <Grid xs={1}></Grid>
                  <Grid xs={8} container justifyContent={'start'} marginTop={5}>
                    <TextField
                      id='outlined-basic'
                      label='Search'
                      variant='outlined'
                      size='small'
                      placeholder='min 3ch max 50ch'
                      fullWidth
                      value={searchIcon}
                      disabled={loadingIcons}
                      onChange={(e: any) => setSearchIcon(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {searchIcon.length > 2 && (
                              <Tooltip
                                arrow
                                title='Delete'>
                                <IconButton aria-label='delete' onClick={() => setSearchIcon('')}>
                                  <CloseIcon color='error' />
                                </IconButton>
                              </Tooltip>
                            )}
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid xs={3}></Grid>
                  <Grid xs={1}></Grid>
                  <Grid
                    xs={8}
                    container
                    height={500}
                    overflow={'auto'}
                    marginTop={2}
                    border={'1px solid #2f2f2fde'}
                    borderRadius={2}
                  >
                    {loadingIcons ? (
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                      >
                        <CircularProgress />
                      </Box>
                    ) : filterIcons?.length === 0 ? (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                      >
                        <Typography variant='h5' textAlign={'center'} sx={{ opacity: '0.5' }}>
                          Image Not Found
                        </Typography>
                      </Box>
                    ) : (
                      filterIcons?.map((item: any) => {
                        if (item?.mediaName.length > 1) {
                          return (
                            <Grid xs={1.5} paddingRight={'10px'} paddingLeft={'10px'}>
                              <Button
                                onClick={() => {
                                  setSelectedIcon({
                                    mediaUrl: item?.mediaUrl,
                                    mediaName: item?.mediaName,
                                  })
                                }}
                                variant='text'
                                color='primary'
                                style={{
                                  color: selectedIcon.mediaUrl === item.mediaUrl ? 'white' : '',
                                  background:
                                    selectedIcon.mediaUrl === item.mediaUrl ? '#1976D2' : '',
                                  padding: '5px',
                                  display: 'flex',
                                  height: '105px',
                                  width: '89px',
                                  marginTop: '10px',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  textTransform: 'none',
                                }}
                              >
                                <Avatar
                                  src={item?.mediaUrl}
                                  variant='rounded'
                                  sx={{ width: '56px', height: '56px' }}
                                />
                                <Typography /* style={{whiteSpace:"nowrap"}} */>
                                  {item?.mediaName}
                                </Typography>
                              </Button>
                            </Grid>
                          )
                        }
                      })
                    )}
                  </Grid>
                  {selectedIcon?.mediaUrl !== '' &&
                    selectedIcon?.mediaName !== '' &&
                    selectedIcon.mediaUrl &&
                    selectedIcon.mediaName && (
                      <Grid
                        xs={2}
                        display={'flex'}
                        justifyContent={'center'}
                        textAlign={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                      >
                        <div></div>
                        <Box border={'1px solid black'} borderRadius={'15px'} padding={2}>
                          <div>
                            <Avatar
                              src={selectedIcon.mediaUrl}
                              variant='rounded'
                              sx={{ width: '150px', height: '150px' }}
                            />
                            <Typography fontSize={14}>{selectedIcon?.mediaName}</Typography>
                          </div>
                        </Box>
                        {/* <Button
                          variant='text'
                          color='primary'
                          style={{
                            color: 'white',
                            background: '#1976D2',
                            padding: '0px',
                            display: 'flex',
                            marginTop: '10px',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textTransform: 'none',
                          }}
                        >
                          <Avatar
                            src={selectedIcon.mediaUrl}
                            variant='rounded'
                            sx={{width: '128px', height: '128px'}}
                          />
                          <Typography>{selectedIcon?.mediaName}</Typography>
                        </Button> */}
                        {/* <div style={{marginTop:5}}>
                        <Button
                          variant='contained'
                          color='error'
                          onClick={() =>
                            setSelectedIcon({mediaName: '', mediaUrl: ''})
                          }
                        >
                          DELETE
                        </Button>
                      </div> */}
                      </Grid>
                    )}
                </Grid>
                {
                  //activityGame Icon end
                }
                {
                  //activityGame previev start
                }
                <Grid
                  xs={12}
                  marginTop={1}
                  marginBottom={1}
                  display={stepGame === 3 ? '' : 'none'}
                  container
                  justifyContent={'center'}
                >
                  <Grid xs={8} container justifyContent={'center'} alignItems={'center'}>
                    <Box width={600} height={200} display={'flex'} alignItems={'center'}>
                      <Grid container>
                        <Grid
                          xs={6}
                          display={'flex'}
                          paddingRight={3}
                          justifyContent={'space-between'}
                        >
                          <Typography variant='h6' color={'black'}>
                            Game Name
                          </Typography>
                          <Typography variant='h6' color={'black'}>
                            :
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography variant='h6' color={'#6C757D'}>
                            {activityName}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={6}
                          display={'flex'}
                          paddingRight={3}
                          justifyContent={'space-between'}
                        >
                          <Typography variant='h6' color={'black'}>
                            Game Type
                          </Typography>
                          <Typography variant='h6' color={'black'}>
                            :
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography variant='h6' color={'#6C757D'}>
                            {activityTypeName}
                          </Typography>
                        </Grid>
                      
                        {/* <Grid
                          xs={6}
                          display={'flex'}
                          paddingRight={3}
                          justifyContent={'space-between'}
                        >
                          <Typography variant='h6' color={'black'}>
                            Unique Questions
                          </Typography>
                          <Typography variant='h6' color={'black'}>
                            :
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography variant='h6' color={'#6C757D'}>
                            {gameCategoryList.categoryList.reduce((total: number, category: any) => {
                              return total + category.selectedQuestionCount
                            }, 0)}
                          </Typography>
                        </Grid> */}
                        {/* <Grid
                          xs={6}
                          display={'flex'}
                          paddingRight={3}
                          justifyContent={'space-between'}
                        >
                          <Typography variant='h6' color={'black'}>
                            Repeat
                          </Typography>
                          <Typography variant='h6' color={'black'}>
                            :
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography variant='h6' color={'#6C757D'}>
                            {repeatGame}
                          </Typography>
                        </Grid> */}
                        <Grid
                          xs={6}
                          display={'flex'}
                          paddingRight={3}
                          justifyContent={'space-between'}
                        >
                          <Typography variant='h6' color={'black'}>
                            Answer Feedback
                          </Typography>
                          <Typography variant='h6' color={'black'}>
                            :
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography variant='h6' color={'#6C757D'}>
                            Yes
                          </Typography>
                        </Grid>
                        <Grid
                          xs={6}
                          display={'flex'}
                          paddingRight={3}
                          justifyContent={'space-between'}
                        >
                          <Typography variant='h6' color={'black'}>
                            Question Time
                          </Typography>
                          <Typography variant='h6' color={'black'}>
                            :
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography variant='h6' color={'#6C757D'}>
                            Yes
                          </Typography>
                        </Grid>
                        <Grid
                          xs={6}
                          display={'flex'}
                          paddingRight={3}
                          justifyContent={'space-between'}
                        >
                          <Typography variant='h6' color={'black'}>
                            Nr. Of Questions
                          </Typography>
                          <Typography variant='h6' color={'black'}>
                            :
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography variant='h6' color={'#6C757D'}>
                            {gameCategoryList.categoryList.reduce((total: number, category: any) => {
                              return total + category.selectedQuestionCount
                            }, 0)/*  * repeatGame */}
                            {/* {activityTypeName === 'Peak' &&
                              (categoryNumber > 249 ? '250' : categoryNumber)}
                            {activityTypeName === 'DeepLearn' && (categoryNumber > 249 ? '250' : categoryNumber)}
                            {activityTypeName === 'TenTen' && '10'}
                            {activityTypeName === 'Recall' && '25'} */}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={6}
                          display={'flex'}
                          paddingRight={3}
                          justifyContent={'space-between'}
                        >
                          <Typography variant='h6' color={'black'}>
                            Category
                          </Typography>
                          <Typography variant='h6' color={'black'}>
                            :
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          {gameCategoryList?.categoryList?.map((category: any, index) => (
                            <Box key={index} display={'flex'} justifyContent={'space-between'}>
                              <Typography variant='h6' color={'#6C757D'}>
                                {category.categoryName}
                              </Typography>
                              {/*  <Typography variant='h6' color={'#8E8E8E'}>
                                  {category.selectedQuestionCount}
                                </Typography> */}
                            </Box>
                          ))}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid xs={4} container>
                    <Box padding={0.8} sx={{ borderRadius: '10px', background: 'black' }}>
                      <img
                        alt='phoneHeader'
                        src={toAbsoluteUrl('/media/stock/phoneHeader.png')}
                        width={'250px'}
                        height={'30px'}
                      />
                      <Card sx={{ width: 250, height: 400 }}>
                        <Box
                          height={'400px'}
                          width={250}
                          padding={1}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            background: 'black',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              background: 'transparent',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <Avatar
                              src={selectedIcon.mediaUrl}
                              variant='rounded'
                              sx={{ width: '128px', height: '128px' }}
                            />
                            <Typography color={'white'}>{activityName}</Typography>
                          </div>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
                {
                  //activityGame previev end
                }
              </Grid>

              <Grid xs={12}>
                <Divider variant='middle' sx={{ borderTop: '1px solid black' }} />
                <Box
                  display={'flex'}
                  padding={0}
                  marginTop={'10px'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ width: '100%' }}
                >
                  <Button
                    variant='contained'
                    color='inherit'
                    sx={{ marginRight: '20px', marginLeft: '9%' }}
                    onClick={() => setDiscardWarnModal(true)}
                  >
                    DISCARD
                  </Button>
                  <div style={{ marginRight: '6%' }}>
                    <Button
                      variant='contained'
                      color='inherit'
                      sx={{ marginRight: '20px' }}
                      disabled={usedJourney}
                      onClick={() =>
                        stepGame !== 0
                          ? setStepGame(stepGame - 1)
                          : editActivity === 0 && setActivityType(0)
                      }
                    >
                      BACK
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ marginRight: '40px' }}
                      disabled={
                        usedJourney ? true :
                          stepGame === 0
                            ? activityName.length < 2 || nameCheck
                            : stepGame === 1
                              ? gameCategoryList.length < 1
                              : stepGame === 2
                                ? selectedIcon.mediaUrl === '' || !selectedIcon.mediaUrl
                                : gameLoading || false
                      }
                      onClick={() => (
                        stepGame < 3 && setStepGame(stepGame + 1),
                        stepGame === 3 && selectedActivity === 0
                          ? handleSaveGame()
                          : stepGame === 3 && handleSaveEditGame()
                      )}
                    >
                      {selectedActivity === 0
                        ? stepGame === 3
                          ? 'SAVE'
                          : 'NEXT'
                        : stepGame === 3
                          ? 'EDIT'
                          : 'NEXT'}
                      {gameLoading && <CircularProgress size={24} sx={{ marginLeft: '10px' }} />}
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Grid>
            {
              //Quiz
            }
            <Grid container display={activityType === 6 ? 'block' : 'none'}>
              <Grid xs={12} minHeight={'450px'}>
                <Box sx={{ width: '100%', paddingLeft: "5%", paddingRight: "5%" }}>
                  <Stepper activeStep={stepQuiz} /* alternativeLabel */>
                    {stepsQuiz.map((label) => (
                      <Step
                        key={label}
                        sx={{
                          '& .MuiStepIcon-root': {
                            fontSize: '25px',
                            color: '#9E9E9E', // Varsayılan renk
                            fontWeight: 'bold',
                          },
                          '& .Mui-active, & .Mui-completed': {
                            color: '#9c27b0 !important',
                          },
                        }}
                      >
                        <StepLabel
                          sx={{
                            '& .MuiStepLabel-label': {
                              fontSize: '15px', // Label'ların font boyutunu ayarlayın
                            },
                            '& .Mui-active': {
                              fontWeight: 'bold',
                            },
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                {
                  //activityName start
                }
                {stepQuiz === 0 && (
                  <Grid
                    xs={12}
                    container
                    justifyItems={'center'}
                    textAlign={'center'}
                    marginTop={10}
                  /* display={stepQuiz === 0 ? 'block' : 'none'} */
                  >
                    <Box sx={{ width: '100%' }}>
                      <Typography variant='h6' color={'#8E8E8E'}>
                        Give a name for your activity.
                        <br /> You can prefer to choose topic of the activity as name.
                      </Typography>

                      <TextField
                        id='outlined-basic'
                        label='Enter a name'
                        placeholder='Min 2 - Max 24 character'
                        size='small'
                        fullWidth
                        style={{ marginTop: '20px', width: '300px' }}
                        required
                        value={createTest.title}
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          const updatedTitle =
                            e.target.value/* .charAt(0).toUpperCase() + e.target.value.slice(1) */
                          if (updatedTitle.length <= 24) {
                            setCreateTest((prevCreateTest: any) => ({
                              ...prevCreateTest,
                              title: updatedTitle,
                            }))
                            setErrorTestName(false)
                            setHelperTextTestName('')
                            setNameCheck(false)
                            if (timer) clearTimeout(timer)
                            // 2 saniye sonra kontrolü tetikle
                            const newTimer = setTimeout(
                              () => checkActivityName(createTest.title, activityType),
                              2000
                            )
                            setTimer(newTimer)
                          }
                        }}
                        inputProps={{ maxLength: 24 }}
                        error={errorTestName || nameCheck}
                        helperText={
                          helperTextTestName || (nameCheck && 'Name exists. Choose another one')
                        }
                        InputProps={{
                          endAdornment: nameCheckLoading && (
                            <InputAdornment position='end'>
                              <CircularProgress size={20} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                {
                  //activityName end
                }
                {
                  //activitySelectCategory start
                }
                {stepQuiz === 1 && (
                  <Grid
                    xs={12}
                    container
                    justifyItems={'center'}
                    textAlign={'center'}
                    marginTop={1}
                  /* display={stepGame === 1 ? '' : 'none'} */
                  >
                    {
                      //categories
                    }
                    <Grid xs={8}>
                      {loadingCategories && (
                        <Box
                          sx={{ width: '100%' }}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <CircularProgress size={'30px'} color='primary' />
                        </Box>
                      )}
                      {loadingCategories === false && (
                        <Box sx={{ width: '100%' }}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs1
                              value={tabValue}
                              onChange={handleChangeTabMenu}
                              aria-label='basic tabs example'
                              /* centered */
                              sx={{
                                marginTop: 2,
                                minHeight: '40px', // Tabs bileşeninin min yüksekliğini ayarlayın
                                '& .MuiTabs-flexContainer': {
                                  minHeight: '40px',
                                },
                                '& .MuiTab-root': {
                                  minHeight: '30px', // Her bir Tab bileşeninin min yüksekliğini ayarlayın
                                  padding: '3px 6px', // Daha dar bir görünüm için padding'i azaltın
                                  fontSize: '15px', // İsteğe bağlı olarak font boyutunu ayarlayın
                                },
                              }}
                            >
                              <Tab1
                                icon={tabValue === 0 && <CheckCircleIcon />}
                                iconPosition='start'
                                label={customerProfile?.customerName + ' Category '}
                                {...a11yProps(0)}
                              />
                              <Tab1
                                icon={tabValue === 1 && <CheckCircleIcon />}
                                iconPosition='start'
                                sx={{ marginLeft: '5px' }}
                                label=' Mobixa Category '
                                {...a11yProps(1)}
                              />
                            </Tabs1>
                          </Box>
                          <CustomTabPanel value={tabValue} index={0}>
                            <Box width={'100%'} marginTop={-2} marginBottom={1}>
                              {' '}
                              <Typography fontSize={14} fontWeight={'bold'}>
                                Select Max 10 categories
                              </Typography>
                            </Box>
                            <div
                              className=' d-flex card-body pt-0'
                              style={{
                                overflowY: 'scroll',
                                overflowX: 'scroll',
                                minHeight: '500px',
                                height: '500px',
                              }}
                            >
                              {customerCategory?.map((data: any) => (
                                <>
                                  <div>
                                    {
                                      //1.katman
                                    }
                                    <AccordionMui
                                      style={{
                                        marginRight: 20,
                                        borderRadius: '10px 10px 5px 5px',
                                        border: '1px solid #d5d5d5',
                                        minWidth: '300px',
                                      }}
                                      defaultExpanded={true}
                                    >
                                      <AccordionSummary
                                        /* expandIcon={
                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                } */
                                        style={{
                                          background: '#D5D5D5',
                                          borderRadius: '10px 10px 0px 0px',
                                          padding: '0px 10px 0px 0px',
                                          minHeight: '60px',
                                        }}
                                      >
                                        <div
                                          className='d-flex '
                                          style={{ alignItems: 'center', height: 20 }}
                                        >
                                          <div>
                                            <FormControlLabel
                                              control={
                                                <Android12Switch
                                                  defaultChecked
                                                  color='secondary'
                                                  size='medium'
                                                  value={data.categoryId}
                                                  disabled={
                                                    data.questionCount === 0
                                                      ? true
                                                      : createTest.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                        ? false
                                                        : createTest.categoryList.length <= 9
                                                          ? false
                                                          : true
                                                  }
                                                  checked={createTest.categoryList?.some(
                                                    (category: any) =>
                                                      category.categoryId === data.categoryId
                                                  )}
                                                  onClick={(e) => {
                                                    e.stopPropagation()

                                                    if (
                                                      !createTest.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                    ) {
                                                      if (createTest.categoryList.length <= 9) {
                                                        handleAddCategory(
                                                          data.categoryId,
                                                          data.name,
                                                          data.questionCount
                                                        )
                                                      }
                                                    } else {
                                                      handleDeleteCategory(data.categoryId)
                                                    }
                                                    e.preventDefault()
                                                  }}
                                                />
                                              }
                                              label=''
                                            />
                                            {/*  <Checkbox
                                              style={{accentColor: '#74992e'}}
                                              value={data.categoryId}
                                              color='secondary'
                                              size='small'
                                              disabled={
                                                data.questionCount === 0
                                                  ? true
                                                  : createTest.categoryList?.some(
                                                      (category: any) =>
                                                        category.categoryId === data.categoryId
                                                    )
                                                  ? false
                                                  : createTest.categoryList.length <= 4
                                                  ? false
                                                  : true
                                              }
                                              checked={createTest.categoryList?.some(
                                                (category: any) =>
                                                  category.categoryId === data.categoryId
                                              )}
                                              onClick={(e) => {
                                                e.stopPropagation()

                                                if (
                                                  !createTest.categoryList?.some(
                                                    (category: any) =>
                                                      category.categoryId === data.categoryId
                                                  )
                                                ) {
                                                  if (createTest.categoryList.length <= 4) {
                                                    handleAddCategory(
                                                      data.categoryId,
                                                      data.name,
                                                      data.questionCount
                                                    )
                                                  }
                                                } else {
                                                  handleDeleteCategory(data.categoryId)
                                                }
                                                e.preventDefault()
                                              }}
                                            /> */}
                                          </div>

                                          <div
                                            style={{
                                              fontWeight: 'bold',
                                              cursor: 'pointer',
                                              fontSize: '12px',
                                              marginLeft: '-4px',
                                              textAlign: "start"
                                            }}
                                          >
                                            {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                              data.name.slice(1)}
                                          </div>
                                          <span
                                            title='Number of awailable question'
                                            className='text-dark  ms-2'
                                            style={{
                                              fontSize: '10px',
                                              color: '#8e8e8e',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            ({data.questionCount})
                                          </span>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails style={{ padding: '3px' }}>
                                        {
                                          //2. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? (
                                            <>
                                              <AccordionMui
                                                style={{
                                                  marginTop: '5px',
                                                  marginBottom: '5px',
                                                  boxShadow: 'none',
                                                  borderRadius: '10px 10px 0px 0px',
                                                }}
                                                defaultExpanded={true}
                                              >
                                                <AccordionSummary
                                                  sx={{
                                                    margin: '0px!important',
                                                    '.MuiAccordionSummary-content': {
                                                      // AccordionSummary'nin içeriği için stil
                                                      marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                                    },
                                                    '&:hover': {
                                                      backgroundColor: '#e7e7e7', // Hover durumu için arka plan rengi
                                                    },
                                                  }}
                                                  style={{
                                                    borderRadius: '10px 10px 0px 0px',
                                                    padding: '0px 5px 0px 0px',
                                                    minHeight: '35px',
                                                    marginLeft: '0px!important',
                                                  }}
                                                >
                                                  <div
                                                    className='d-flex'
                                                    style={{
                                                      alignItems: 'center',
                                                      justifyItems: 'start',
                                                      height: 20,
                                                    }}
                                                  >
                                                    <div>
                                                      <FormControlLabel
                                                        control={
                                                          <Android12Switch
                                                            defaultChecked
                                                            color='secondary'
                                                            size='medium'
                                                            value={child.categoryId}
                                                            disabled={
                                                              child.questionCount === 0
                                                                ? true
                                                                : createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                                  ? false
                                                                  : createTest.categoryList.length <= 9
                                                                    ? false
                                                                    : true
                                                            }
                                                            checked={createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )}
                                                            onClick={(e) => {
                                                              if (
                                                                !createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                              ) {
                                                                if (
                                                                  createTest.categoryList.length <= 9
                                                                ) {
                                                                  handleAddCategory(
                                                                    child.categoryId,
                                                                    child.name,
                                                                    child.questionCount
                                                                  )
                                                                }
                                                              } else {
                                                                handleDeleteCategory(
                                                                  child.categoryId
                                                                )
                                                              }
                                                              e.stopPropagation()
                                                            }}
                                                          />
                                                        }
                                                        label=''
                                                      />
                                                      {/*  <Checkbox
                                                        style={{accentColor: '#74992e'}}
                                                        value={child.categoryId}
                                                        color='secondary'
                                                        size='small'
                                                        disabled={
                                                          child.questionCount === 0
                                                            ? true
                                                            : createTest.categoryList?.some(
                                                                (category: any) =>
                                                                  category.categoryId ===
                                                                  child.categoryId
                                                              )
                                                            ? false
                                                            : createTest.categoryList.length <= 4
                                                            ? false
                                                            : true
                                                        }
                                                        checked={createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )}
                                                        onClick={(e) => {
                                                          if (
                                                            !createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                          ) {
                                                            if (
                                                              createTest.categoryList.length <= 4
                                                            ) {
                                                              handleAddCategory(
                                                                child.categoryId,
                                                                child.name,
                                                                child.questionCount
                                                              )
                                                            }
                                                          } else {
                                                            handleDeleteCategory(child.categoryId)
                                                          }
                                                          e.stopPropagation()
                                                        }}
                                                      /> */}
                                                    </div>
                                                    <span
                                                      className={'text-gray-800 d-flex'}
                                                      style={{
                                                        fontWeight: 'bold',
                                                        cursor: 'pointer',
                                                        fontSize: '12px',
                                                        marginLeft: '-4px',
                                                        textAlign: "start"
                                                      }}
                                                    >
                                                      {child.name.charAt(0).toUpperCase() +
                                                        child.name.slice(1)}
                                                    </span>
                                                    <span
                                                      title='Number of awailable question'
                                                      className='text-dark text-muted ms-2'
                                                      style={{
                                                        fontSize: '10px',
                                                        color: '#8e8e8e',
                                                        fontWeight: 'normal',
                                                      }}
                                                    >
                                                      ({child.questionCount})
                                                    </span>
                                                  </div>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ padding: '3px' }}>
                                                  {child?.children?.map((child: any) => (
                                                    <div
                                                      id={child.categoryId}
                                                      className='ms-0 mt-3 '
                                                    >
                                                      <div
                                                        className='d-flex div-element-class'
                                                        style={{
                                                          alignItems: 'center',
                                                          height: 35,
                                                          paddingLeft: 32,
                                                        }}
                                                      >
                                                        <div>
                                                          <FormControlLabel
                                                            control={
                                                              <Android12Switch
                                                                defaultChecked
                                                                color='secondary'
                                                                size='medium'
                                                                value={child.categoryId}
                                                                disabled={
                                                                  child.questionCount === 0
                                                                    ? true
                                                                    : createTest.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                      ? false
                                                                      : createTest.categoryList.length <= 9
                                                                        ? false
                                                                        : true
                                                                }
                                                                checked={createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )}
                                                                onClick={(e) => {
                                                                  if (
                                                                    !createTest.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                  ) {
                                                                    if (
                                                                      createTest.categoryList
                                                                        .length <= 9
                                                                    ) {
                                                                      handleAddCategory(
                                                                        child.categoryId,
                                                                        child.name,
                                                                        child.questionCount
                                                                      )
                                                                    }
                                                                  } else {
                                                                    handleDeleteCategory(
                                                                      child.categoryId
                                                                    )
                                                                  }
                                                                  e.stopPropagation()
                                                                }}
                                                              />
                                                            }
                                                            label=''
                                                          />
                                                          {/* <Checkbox
                                                            style={{accentColor: '#74992e'}}
                                                            value={child.categoryId}
                                                            color='secondary'
                                                            size='small'
                                                            disabled={
                                                              child.questionCount === 0
                                                                ? true
                                                                : createTest.categoryList?.some(
                                                                    (category: any) =>
                                                                      category.categoryId ===
                                                                      child.categoryId
                                                                  )
                                                                ? false
                                                                : createTest.categoryList.length <=
                                                                  4
                                                                ? false
                                                                : true
                                                            }
                                                            checked={createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )}
                                                            onClick={(e) => {
                                                              if (
                                                                !createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                              ) {
                                                                if (
                                                                  createTest.categoryList.length <=
                                                                  4
                                                                ) {
                                                                  handleAddCategory(
                                                                    child.categoryId,
                                                                    child.name,
                                                                    child.questionCount
                                                                  )
                                                                }
                                                              } else {
                                                                handleDeleteCategory(
                                                                  child.categoryId
                                                                )
                                                              }
                                                              e.stopPropagation()
                                                            }}
                                                          /> */}
                                                        </div>
                                                        <a
                                                          className={'text-gray-800 d-flex '}
                                                          style={{
                                                            cursor: 'pointer',
                                                            fontSize: '12px',
                                                            alignItems: 'center',
                                                            maxWidth: '210px',
                                                            marginLeft: '-4px',
                                                            textAlign: "start"
                                                          }}
                                                        >
                                                          {child.name
                                                            .charAt(0)
                                                            .toUpperCase()
                                                            .replace('🅜', '') + child.name.slice(1)}
                                                        </a>

                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '20px',
                                                          }}
                                                        >
                                                          <span
                                                            title='Number of awailable question'
                                                            className='ms-2'
                                                            style={{
                                                              color: '#8e8e8e',
                                                              fontSize: '10px',
                                                            }}
                                                          >
                                                            {`(${child.questionCount})`}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </AccordionDetails>
                                              </AccordionMui>
                                            </>
                                          ) : null
                                        )}
                                        {
                                          // 3. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? null : (
                                            <div id={child.categoryId} className='ms-0 mt-3 '>
                                              <div
                                                className='d-flex border-1 div-element-class'
                                                style={{
                                                  alignItems: 'center',
                                                  height: 35,
                                                  paddingLeft: 4,
                                                }}
                                              >
                                                <div>
                                                  <FormControlLabel
                                                    control={
                                                      <Android12Switch
                                                        defaultChecked
                                                        checked={createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )}
                                                        disabled={
                                                          child.questionCount === 0
                                                            ? true
                                                            : createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                              ? false
                                                              : createTest.categoryList.length <= 9
                                                                ? false
                                                                : true
                                                        }
                                                        value={child.categoryId}
                                                        color='secondary'
                                                        onClick={(e) => {
                                                          if (
                                                            !createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                          ) {
                                                            if (
                                                              createTest.categoryList.length <= 9
                                                            ) {
                                                              handleAddCategory(
                                                                child.categoryId,
                                                                child.name,
                                                                child.questionCount
                                                              )
                                                            }
                                                          } else {
                                                            handleDeleteCategory(child.categoryId)
                                                          }
                                                          e.stopPropagation()
                                                        }}
                                                        size='medium'
                                                      />
                                                    }
                                                    label=''
                                                  />
                                                  {/* <Checkbox
                                                    style={{accentColor: '#74992e'}}
                                                    value={child.categoryId}
                                                    color='secondary'
                                                    size='small'
                                                    disabled={
                                                      child.questionCount === 0
                                                        ? true
                                                        : createTest.categoryList?.some(
                                                            (category: any) =>
                                                              category.categoryId ===
                                                              child.categoryId
                                                          )
                                                        ? false
                                                        : createTest.categoryList.length <= 4
                                                        ? false
                                                        : true
                                                    }
                                                    checked={createTest.categoryList?.some(
                                                      (category: any) =>
                                                        category.categoryId === child.categoryId
                                                    )}
                                                    onClick={(e) => {
                                                      if (
                                                        !createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )
                                                      ) {
                                                        if (createTest.categoryList.length <= 4) {
                                                          handleAddCategory(
                                                            child.categoryId,
                                                            child.name,
                                                            child.questionCount
                                                          )
                                                        }
                                                      } else {
                                                        handleDeleteCategory(child.categoryId)
                                                      }
                                                      e.stopPropagation()
                                                    }}
                                                  /> */}
                                                </div>
                                                <a
                                                  className={'text-gray-800 d-flex'}
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: '12px',
                                                    maxWidth: '210px',
                                                    marginLeft: '-4px',
                                                    textAlign: "start"
                                                  }}
                                                >
                                                  {child.name.charAt(0).toUpperCase() +
                                                    child.name.slice(1)}
                                                </a>

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '20px',
                                                  }}
                                                >
                                                  <span
                                                    title='Number of awailable question'
                                                    className='ms-2'
                                                    style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                  >
                                                    {`(${child.questionCount})`}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </AccordionDetails>
                                    </AccordionMui>
                                  </div>
                                </>
                              ))}
                            </div>
                          </CustomTabPanel>
                          <CustomTabPanel value={tabValue} index={1}>
                            <Box width={'100%'} marginTop={-2} marginBottom={1}>
                              {' '}
                              <Typography fontSize={14} fontWeight={'bold'}>
                                Select Max 10 categories
                              </Typography>
                            </Box>
                            <div
                              className=' d-flex card-body pt-0'
                              style={{
                                overflowY: 'scroll',
                                overflowX: 'scroll',
                                minHeight: '500px',
                                height: '500px',
                              }}
                            >
                              {mobixaCategory?.map((data: any) => (
                                <>
                                  <div>
                                    {
                                      //1.katman
                                    }
                                    <AccordionMui
                                      style={{
                                        marginRight: 20,
                                        borderRadius: '10px 10px 5px 5px',
                                        border: '1px solid #d5d5d5',
                                        minWidth: '300px',
                                      }}
                                      defaultExpanded={true}
                                    >
                                      <AccordionSummary
                                        /* expandIcon={
                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                } */
                                        style={{
                                          background: '#D5D5D5',
                                          borderRadius: '10px 10px 0px 0px',
                                          padding: '0px 10px 0px 0px',
                                          minHeight: '60px',
                                        }}
                                      >
                                        <div
                                          className='d-flex '
                                          style={{ alignItems: 'center', height: 20 }}
                                        >
                                          <div>
                                            <FormControlLabel
                                              control={
                                                <Android12Switch
                                                  defaultChecked
                                                  color='secondary'
                                                  size='medium'
                                                  value={data.categoryId}
                                                  disabled={
                                                    data.questionCount === 0
                                                      ? true
                                                      : createTest.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                        ? false
                                                        : createTest.categoryList.length <= 9
                                                          ? false
                                                          : true
                                                  }
                                                  checked={createTest.categoryList?.some(
                                                    (category: any) =>
                                                      category.categoryId === data.categoryId
                                                  )}
                                                  onClick={(e) => {
                                                    e.stopPropagation()

                                                    if (
                                                      !createTest.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                    ) {
                                                      if (createTest.categoryList.length <= 9) {
                                                        handleAddCategory(
                                                          data.categoryId,
                                                          data.name,
                                                          data.questionCount
                                                        )
                                                      }
                                                    } else {
                                                      handleDeleteCategory(data.categoryId)
                                                    }
                                                    e.preventDefault()
                                                  }}
                                                />
                                              }
                                              label=''
                                            />
                                            {/*  <Checkbox
                                              style={{accentColor: '#74992e'}}
                                              value={data.categoryId}
                                              color='secondary'
                                              size='small'
                                              disabled={
                                                data.questionCount === 0
                                                  ? true
                                                  : createTest.categoryList?.some(
                                                      (category: any) =>
                                                        category.categoryId === data.categoryId
                                                    )
                                                  ? false
                                                  : createTest.categoryList.length <= 4
                                                  ? false
                                                  : true
                                              }
                                              checked={createTest.categoryList?.some(
                                                (category: any) =>
                                                  category.categoryId === data.categoryId
                                              )}
                                              onClick={(e) => {
                                                e.stopPropagation()

                                                if (
                                                  !createTest.categoryList?.some(
                                                    (category: any) =>
                                                      category.categoryId === data.categoryId
                                                  )
                                                ) {
                                                  if (createTest.categoryList.length <= 4) {
                                                    handleAddCategory(
                                                      data.categoryId,
                                                      data.name,
                                                      data.questionCount
                                                    )
                                                  }
                                                } else {
                                                  handleDeleteCategory(data.categoryId)
                                                }
                                                e.preventDefault()
                                              }}
                                            /> */}
                                          </div>

                                          <div
                                            style={{
                                              fontWeight: 'bold',
                                              cursor: 'pointer',
                                              fontSize: '12px',
                                              marginLeft: '-4px',
                                              textAlign: "start"
                                            }}
                                          >
                                            {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                              data.name.slice(1)}
                                          </div>
                                          <span
                                            title='Number of awailable question'
                                            className='text-dark  ms-2'
                                            style={{
                                              fontSize: '10px',
                                              color: '#8e8e8e',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            ({data.questionCount})
                                          </span>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails style={{ padding: '3px' }}>
                                        {
                                          //2. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? (
                                            <>
                                              <AccordionMui
                                                style={{
                                                  marginTop: '5px',
                                                  marginBottom: '5px',
                                                  boxShadow: 'none',
                                                  borderRadius: '10px 10px 0px 0px',
                                                }}
                                                defaultExpanded={true}
                                              >
                                                <AccordionSummary
                                                  sx={{
                                                    margin: '0px!important',
                                                    '.MuiAccordionSummary-content': {
                                                      // AccordionSummary'nin içeriği için stil
                                                      marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                                    },
                                                    '&:hover': {
                                                      backgroundColor: '#e7e7e7', // Hover durumu için arka plan rengi
                                                    },
                                                  }}
                                                  style={{
                                                    borderRadius: '10px 10px 0px 0px',
                                                    padding: '0px 5px 0px 0px',
                                                    minHeight: '35px',
                                                    marginLeft: '0px!important',
                                                  }}
                                                >
                                                  <div
                                                    className='d-flex'
                                                    style={{
                                                      alignItems: 'center',
                                                      justifyItems: 'start',
                                                      height: 20,
                                                    }}
                                                  >
                                                    <div>
                                                      <FormControlLabel
                                                        control={
                                                          <Android12Switch
                                                            defaultChecked
                                                            color='secondary'
                                                            size='medium'
                                                            value={child.categoryId}
                                                            disabled={
                                                              child.questionCount === 0
                                                                ? true
                                                                : createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                                  ? false
                                                                  : createTest.categoryList.length <= 9
                                                                    ? false
                                                                    : true
                                                            }
                                                            checked={createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )}
                                                            onClick={(e) => {
                                                              if (
                                                                !createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                              ) {
                                                                if (
                                                                  createTest.categoryList.length <= 9
                                                                ) {
                                                                  handleAddCategory(
                                                                    child.categoryId,
                                                                    child.name,
                                                                    child.questionCount
                                                                  )
                                                                }
                                                              } else {
                                                                handleDeleteCategory(
                                                                  child.categoryId
                                                                )
                                                              }
                                                              e.stopPropagation()
                                                            }}
                                                          />
                                                        }
                                                        label=''
                                                      />
                                                      {/*  <Checkbox
                                                        style={{accentColor: '#74992e'}}
                                                        value={child.categoryId}
                                                        color='secondary'
                                                        size='small'
                                                        disabled={
                                                          child.questionCount === 0
                                                            ? true
                                                            : createTest.categoryList?.some(
                                                                (category: any) =>
                                                                  category.categoryId ===
                                                                  child.categoryId
                                                              )
                                                            ? false
                                                            : createTest.categoryList.length <= 4
                                                            ? false
                                                            : true
                                                        }
                                                        checked={createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )}
                                                        onClick={(e) => {
                                                          if (
                                                            !createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                          ) {
                                                            if (
                                                              createTest.categoryList.length <= 4
                                                            ) {
                                                              handleAddCategory(
                                                                child.categoryId,
                                                                child.name,
                                                                child.questionCount
                                                              )
                                                            }
                                                          } else {
                                                            handleDeleteCategory(child.categoryId)
                                                          }
                                                          e.stopPropagation()
                                                        }}
                                                      /> */}
                                                    </div>
                                                    <span
                                                      className={'text-gray-800 d-flex'}
                                                      style={{
                                                        fontWeight: 'bold',
                                                        cursor: 'pointer',
                                                        fontSize: '12px',
                                                        marginLeft: '-4px',
                                                        textAlign: "start"
                                                      }}
                                                    >
                                                      {child.name.charAt(0).toUpperCase() +
                                                        child.name.slice(1)}
                                                    </span>
                                                    <span
                                                      title='Number of awailable question'
                                                      className='text-dark text-muted ms-2'
                                                      style={{
                                                        fontSize: '10px',
                                                        color: '#8e8e8e',
                                                        fontWeight: 'normal',
                                                      }}
                                                    >
                                                      ({child.questionCount})
                                                    </span>
                                                  </div>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ padding: '3px' }}>
                                                  {child?.children?.map((child: any) => (
                                                    <div
                                                      id={child.categoryId}
                                                      className='ms-0 mt-3 '
                                                    >
                                                      <div
                                                        className='d-flex div-element-class'
                                                        style={{
                                                          alignItems: 'center',
                                                          height: 35,
                                                          paddingLeft: 32,
                                                        }}
                                                      >
                                                        <div>
                                                          <FormControlLabel
                                                            control={
                                                              <Android12Switch
                                                                defaultChecked
                                                                color='secondary'
                                                                size='medium'
                                                                value={child.categoryId}
                                                                disabled={
                                                                  child.questionCount === 0
                                                                    ? true
                                                                    : createTest.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                      ? false
                                                                      : createTest.categoryList.length <= 9
                                                                        ? false
                                                                        : true
                                                                }
                                                                checked={createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )}
                                                                onClick={(e) => {
                                                                  if (
                                                                    !createTest.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                  ) {
                                                                    if (
                                                                      createTest.categoryList
                                                                        .length <= 9
                                                                    ) {
                                                                      handleAddCategory(
                                                                        child.categoryId,
                                                                        child.name,
                                                                        child.questionCount
                                                                      )
                                                                    }
                                                                  } else {
                                                                    handleDeleteCategory(
                                                                      child.categoryId
                                                                    )
                                                                  }
                                                                  e.stopPropagation()
                                                                }}
                                                              />
                                                            }
                                                            label=''
                                                          />
                                                          {/* <Checkbox
                                                            style={{accentColor: '#74992e'}}
                                                            value={child.categoryId}
                                                            color='secondary'
                                                            size='small'
                                                            disabled={
                                                              child.questionCount === 0
                                                                ? true
                                                                : createTest.categoryList?.some(
                                                                    (category: any) =>
                                                                      category.categoryId ===
                                                                      child.categoryId
                                                                  )
                                                                ? false
                                                                : createTest.categoryList.length <=
                                                                  4
                                                                ? false
                                                                : true
                                                            }
                                                            checked={createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )}
                                                            onClick={(e) => {
                                                              if (
                                                                !createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                              ) {
                                                                if (
                                                                  createTest.categoryList.length <=
                                                                  4
                                                                ) {
                                                                  handleAddCategory(
                                                                    child.categoryId,
                                                                    child.name,
                                                                    child.questionCount
                                                                  )
                                                                }
                                                              } else {
                                                                handleDeleteCategory(
                                                                  child.categoryId
                                                                )
                                                              }
                                                              e.stopPropagation()
                                                            }}
                                                          /> */}
                                                        </div>
                                                        <a
                                                          className={'text-gray-800 d-flex '}
                                                          style={{
                                                            cursor: 'pointer',
                                                            fontSize: '12px',
                                                            alignItems: 'center',
                                                            maxWidth: '210px',
                                                            marginLeft: '-4px',
                                                            textAlign: "start"
                                                          }}
                                                        >
                                                          {child.name
                                                            .charAt(0)
                                                            .toUpperCase()
                                                            .replace('🅜', '') + child.name.slice(1)}
                                                        </a>

                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '20px',
                                                          }}
                                                        >
                                                          <span
                                                            title='Number of awailable question'
                                                            className='ms-2'
                                                            style={{
                                                              color: '#8e8e8e',
                                                              fontSize: '10px',
                                                            }}
                                                          >
                                                            {`(${child.questionCount})`}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </AccordionDetails>
                                              </AccordionMui>
                                            </>
                                          ) : null
                                        )}
                                        {
                                          // 3. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? null : (
                                            <div id={child.categoryId} className='ms-0 mt-3 '>
                                              <div
                                                className='d-flex border-1 div-element-class'
                                                style={{
                                                  alignItems: 'center',
                                                  height: 35,
                                                  paddingLeft: 4,
                                                }}
                                              >
                                                <div>
                                                  <FormControlLabel
                                                    control={
                                                      <Android12Switch
                                                        defaultChecked
                                                        checked={createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )}
                                                        disabled={
                                                          child.questionCount === 0
                                                            ? true
                                                            : createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                              ? false
                                                              : createTest.categoryList.length <= 9
                                                                ? false
                                                                : true
                                                        }
                                                        value={child.categoryId}
                                                        color='secondary'
                                                        onClick={(e) => {
                                                          if (
                                                            !createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                          ) {
                                                            if (
                                                              createTest.categoryList.length <= 9
                                                            ) {
                                                              handleAddCategory(
                                                                child.categoryId,
                                                                child.name,
                                                                child.questionCount
                                                              )
                                                            }
                                                          } else {
                                                            handleDeleteCategory(child.categoryId)
                                                          }
                                                          e.stopPropagation()
                                                        }}
                                                        size='medium'
                                                      />
                                                    }
                                                    label=''
                                                  />
                                                  {/* <Checkbox
                                                    style={{accentColor: '#74992e'}}
                                                    value={child.categoryId}
                                                    color='secondary'
                                                    size='small'
                                                    disabled={
                                                      child.questionCount === 0
                                                        ? true
                                                        : createTest.categoryList?.some(
                                                            (category: any) =>
                                                              category.categoryId ===
                                                              child.categoryId
                                                          )
                                                        ? false
                                                        : createTest.categoryList.length <= 4
                                                        ? false
                                                        : true
                                                    }
                                                    checked={createTest.categoryList?.some(
                                                      (category: any) =>
                                                        category.categoryId === child.categoryId
                                                    )}
                                                    onClick={(e) => {
                                                      if (
                                                        !createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )
                                                      ) {
                                                        if (createTest.categoryList.length <= 4) {
                                                          handleAddCategory(
                                                            child.categoryId,
                                                            child.name,
                                                            child.questionCount
                                                          )
                                                        }
                                                      } else {
                                                        handleDeleteCategory(child.categoryId)
                                                      }
                                                      e.stopPropagation()
                                                    }}
                                                  /> */}
                                                </div>
                                                <a
                                                  className={'text-gray-800 d-flex'}
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: '12px',
                                                    maxWidth: '210px',
                                                    marginLeft: '-4px',
                                                    textAlign: "start"
                                                  }}
                                                >
                                                  {child.name.charAt(0).toUpperCase() +
                                                    child.name.slice(1)}
                                                </a>

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '20px',
                                                  }}
                                                >
                                                  <span
                                                    title='Number of awailable question'
                                                    className='ms-2'
                                                    style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                  >
                                                    {`(${child.questionCount})`}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </AccordionDetails>
                                    </AccordionMui>
                                  </div>
                                </>
                              ))}
                            </div>
                          </CustomTabPanel>
                        </Box>
                      )}
                    </Grid>
                    {
                      //selectedCategory
                    }
                    {createTest.categoryList.length > 0 && (
                      <Grid
                        xs={4}
                        container
                        marginTop={3}
                        justifyContent={'center'}
                        alignContent={'center'}
                      >
                        <Box>
                          <Typography color={'#8E8E8E'}>Selected Categories</Typography>
                          <Paper elevation={2} style={{ height: 'auto', maxHeight: 360, overflowX: "scroll", width: '330px' }}>
                            {createTest.categoryList?.map((category: any, index: number) => (
                              <div style={{ display: 'flex' }}>
                                <Box
                                  width={'330px'}
                                  height={'50px'}
                                  padding={1}
                                  margin={1}
                                  marginLeft={'10px'}
                                  sx={{
                                    background: mobixaCategory.some((item: any) => {
                                      if (item.categoryId === category.categoryId) {
                                        return true
                                      } else {
                                        return item.children.some((child: any) => {
                                          if (child.categoryId === category.categoryId) {
                                            return true
                                          } else {
                                            return child.children.some((grandchild: any) => {
                                              return grandchild.categoryId === category.categoryId
                                            })
                                          }
                                        })
                                      }
                                    })
                                      ? '#F7D9E4'
                                      : '#EEF6FF',
                                    borderRadius: '10px',
                                  }}
                                  display={'flex'}
                                  justifyContent={'space-between'}
                                  alignItems={'center'}
                                >
                                  <Typography fontSize={'14px'}>{index + 1}.</Typography>
                                  <Typography fontSize={'14px'} width={'100%'}>
                                    {category.categoryName}
                                  </Typography>
                                  <Box
                                    width={'100px'}
                                    height={'40px'}
                                    marginLeft={'10px'}
                                    sx={{
                                      borderRadius: '10px',
                                    }}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                  >
                                    <TextField
                                      fullWidth
                                      size='small'
                                      type='number'
                                      style={{ background: 'white' }}
                                      label={
                                        category.questionCount <= 999
                                          ? `Max ${category.questionCount}.`
                                          : `Max 999.`
                                      }
                                      value={category.selectedQuestionCount}
                                      onChange={(e) => {
                                        if (
                                          e.target.value <= category.questionCount &&
                                          parseInt(e.target.value) > 0
                                        ) {
                                          if (category.selectedQuestionCount <= 999) {
                                            setCreateTest((prevCreateTest: any) => ({
                                              ...prevCreateTest,
                                              categoryList: prevCreateTest.categoryList.map(
                                                (categoryList: any) => {
                                                  if (
                                                    category.categoryId === categoryList.categoryId
                                                  ) {
                                                    return {
                                                      ...categoryList,
                                                      selectedQuestionCount: parseInt(
                                                        e.target.value
                                                      ),
                                                    }
                                                  }
                                                  return categoryList
                                                }
                                              ),
                                            }))
                                          } else {
                                            setCreateTest((prevCreateTest: any) => ({
                                              ...prevCreateTest,
                                              categoryList: prevCreateTest.categoryList.map(
                                                (categoryList: any) => {
                                                  if (
                                                    category.categoryId === categoryList.categoryId
                                                  ) {
                                                    return {
                                                      ...categoryList,
                                                      selectedQuestionCount: 999,
                                                    }
                                                  }
                                                  return categoryList
                                                }
                                              ),
                                            }))
                                          }
                                        }
                                      }}
                                      inputProps={{
                                        max:
                                          category.questionCount >= 999
                                            ? 999
                                            : category.questionCount,
                                        min: 1,
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <>
                                  <IconButton
                                    color='error'
                                    onClick={() => (
                                      handleDeleteCategory(category.categoryId),
                                      setSelectCategoryDataGrid(0)
                                    )}
                                  >
                                    <DeleteForeverRoundedIcon />
                                  </IconButton>
                                </>
                              </div>
                            ))}
                          </Paper>
                          <Box marginTop={1} sx={{ display: 'flex', direction: 'column' }}>
                            <Divider variant='fullWidth' />
                            <Box marginTop={1} display={'flex'} alignItems={'center'}>
                              <Typography>Total nr. of questions to be asked</Typography>
                              <Typography fontWeight={'bold'} marginLeft={5}>
                                {createTest.categoryList.reduce((total: number, category: any) => {
                                  return total + category.selectedQuestionCount
                                }, 0)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                )}
                {
                  //activitySelectCategory end
                }
                {
                  //quiz duration start
                }
                {stepQuiz === 2 && (
                  <Grid
                    xs={12}
                    container
                    justifyContent={'center'}
                    textAlign={'center'}
                    alignContent={'center'}
                    height={'400px'}
                    marginTop={1}
                  /* display={stepGame === 1 ? '' : 'none'} */
                  >
                    {loadingSuggestion ? (
                      <CircularProgress size={'17px'} style={{ marginLeft: '5px' }} color='inherit' />
                    ) : (
                      <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                        <Typography color={'#3F4254'} fontWeight={'bold'} fontSize={'20px'}>
                          Time Limit
                        </Typography>
                        <Typography
                          color={'#3F4254'}
                          fontSize={'16px'}
                          marginTop={2}
                          display={'flex'}
                        >
                          Mobixa suggests{' '}
                          <Typography
                            color={'#3F4254'}
                            fontWeight={createTest.timeLimit === false ? '' : 'bold'}
                            fontSize={'16px'}
                            marginLeft={1}
                            marginRight={1}
                          >
                            {typeof timeSuggestion === 'object' ? timeSuggestion.suggestedTime : timeSuggestion} Minutes
                          </Typography>{' '}
                          for this test.
                        </Typography>
                        <Box display={'flex'} alignItems={'center'} marginTop={2}>
                          <Checkbox
                            onClick={() => (
                              setTimeLimitSuggestion(!timeLimitSuggestion),
                              setCreateTest((prevCreateTest: any) => ({
                                ...prevCreateTest,
                                timeLimit: timeSuggestion,
                              }))
                            )}
                            checked={timeLimitSuggestion}
                            color='success'
                          />
                          <Typography color={'#3F4254'} fontSize={'16px'}>
                            Use the suggestion
                          </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} marginTop={2}>
                          {timeLimitSuggestion === false && (
                            <TextField
                              label='Minutes'
                              placeholder='mmm'
                              size='small'
                              value={createTest.timeLimit}
                              onChange={(e: any) => {
                                if (e.target.value <= 9999) {
                                  setTimeLimitSuggestionHelperText(false)
                                  setCreateTest((prevCreateTest: any) => ({
                                    ...prevCreateTest,
                                    timeLimit: e.target.value,
                                  }))
                                } else {
                                  setTimeLimitSuggestionHelperText(true)
                                }
                              }}
                              helperText={
                                timeLimitSuggestionHelperText ? 'Maximum 9999 minutes' : ''
                              }
                              error={timeLimitSuggestionHelperText}
                              type='number'
                              sx={{ width: '100px', marginRight: '2px' }}
                              inputProps={{ max: 1440, min: 0 }}
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                  </Grid>
                )}
                {
                  //quiz duration end
                }
                {
                  //quiz preview start
                }
                {stepQuiz === 3 && (
                  <Grid xs={12} marginTop={1} marginBottom={1} container justifyContent={'center'}>
                    <Grid
                      xs={8}
                      container
                      justifyContent={'center'}
                      alignItems={'center'}
                      marginTop={2}
                    >
                      <Box
                        width={600}
                        height={200}
                        paddingLeft={4}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        <Grid container>
                          <Grid
                            xs={6}
                            display={'flex'}
                            paddingRight={3}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Quiz Name
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6}>
                            <Typography variant='h6' color={'#6C757D'}>
                              {createTest?.title}
                            </Typography>
                          </Grid>
                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Answer Feedback
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2} display={'flex'} justifyContent={'start'}>
                            <Typography variant='h6' color={'#8E8E8E'}>
                              No
                            </Typography>
                          </Grid>
                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Question Time
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2} display={'flex'} justifyContent={'start'}>
                            <Typography variant='h6' color={'#8E8E8E'}>
                              No
                            </Typography>
                          </Grid>
                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Time Limit
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2} display={'flex'} justifyContent={'start'}>
                            <Typography variant='h6' color={'#8E8E8E'}>
                              {createTest?.timeLimit + ' min'}
                            </Typography>
                          </Grid>
                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Nr. Of Questions
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2} display={'flex'} justifyContent={'start'}>
                            <Typography variant='h6' color={'#8E8E8E'}>
                              {createTest?.categoryList?.reduce((total: number, category: any) => {
                                return total + category.selectedQuestionCount
                              }, 0)}
                            </Typography>
                          </Grid>
                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Categories
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2}>
                            {createTest?.categoryList?.map((category: any, index) => (
                              <Box key={index} display={'flex'} justifyContent={'space-between'}>
                                <Typography variant='h6' color={'#6C757D'}>
                                  {category.categoryName}
                                </Typography>
                                {/*  <Typography variant='h6' color={'#8E8E8E'}>
                                  {category.selectedQuestionCount}
                                </Typography> */}
                              </Box>
                            ))}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid xs={4} container justifyContent={'start'} marginTop={2}>
                      <Box padding={0.8} sx={{ borderRadius: '10px', background: 'black' }}>
                        <img
                          alt='phoneHeader'
                          src={toAbsoluteUrl('/media/stock/phoneHeader.png')}
                          width={'250px'}
                          height={'30px'}
                        />
                        <Card sx={{ width: 250, height: 400 }}>
                          <Box
                            height={'400px'}
                            width={250}
                            padding={1}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              background: 'black',
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                background: 'transparent',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <Avatar
                                src={`https://s3.mobixa.net/activityIcons/quiz.png`}
                                variant='rounded'
                                sx={{ width: '128px', height: '128px' }}
                              />
                              <Typography color={'white'}>{createTest?.title}</Typography>
                            </div>
                          </Box>
                        </Card>
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {
                  //quiz preview end
                }
              </Grid>
              <Grid xs={12}>
                <Divider variant='middle' sx={{ borderTop: '1px solid black' }} />
                <Box
                  display={'flex'}
                  padding={0}
                  marginTop={'10px'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ width: '100%' }}
                >
                  <Button
                    variant='contained'
                    color='inherit'
                    sx={{ marginRight: '20px', marginLeft: '9%' }}
                    onClick={() => setDiscardWarnModal(true)}
                  >
                    DISCARD
                  </Button>
                  <div style={{ marginRight: '6%' }}>
                    <Button
                      variant='contained'
                      color='inherit'
                      sx={{ marginRight: '20px' }}
                      disabled={usedJourney}
                      onClick={() =>
                        stepQuiz !== 0
                          ? setStepQuiz(stepQuiz - 1)
                          : editActivity === 0 && setActivityType(0)
                      }
                    >
                      BACK
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ marginRight: '40px' }}
                      disabled={
                        usedJourney ? true :
                          stepQuiz === 0
                            ? createTest?.title?.length < 2 || nameCheck
                            : stepQuiz === 1
                              ? createTest?.categoryList?.length < 1
                              : createTest?.timeLimit > 0
                                ? false
                                : true
                      }
                      onClick={() => (
                        stepQuiz < 3 && setStepQuiz(stepQuiz + 1),
                        selectedActivity === 0 && stepQuiz === 3
                          ? handleSaveCreateTest()
                          : stepQuiz === 3 && handleEditCreateTest()
                      )}
                    >
                      {selectedActivity === 0
                        ? stepQuiz === 3
                          ? 'SAVE'
                          : 'NEXT'
                        : stepQuiz === 3
                          ? 'EDIT'
                          : 'NEXT'}
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Grid>
            {
              //Exam
            }
            <Grid container display={activityType === 7 ? 'block' : 'none'}>
              <Grid xs={12} minHeight={'450px'}>
                <Box sx={{ width: '100%', paddingLeft: "5%", paddingRight: "5%" }}>
                  <Stepper activeStep={stepExam} /* alternativeLabel */>
                    {stepsQuiz.map((label) => (
                      <Step
                        key={label}
                        sx={{
                          '& .MuiStepIcon-root': {
                            fontSize: '25px',
                            color: '#9E9E9E', // Varsayılan renk
                            fontWeight: 'bold',
                          },
                          '& .Mui-active, & .Mui-completed': {
                            color: '#9c27b0 !important',
                          },
                        }}
                      >
                        <StepLabel
                          sx={{
                            '& .MuiStepLabel-label': {
                              fontSize: '15px', // Label'ların font boyutunu ayarlayın
                            },
                            '& .Mui-active': {
                              fontWeight: 'bold',
                            },
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                {
                  //activityName start
                }
                {stepExam === 0 && (
                  <Grid
                    xs={12}
                    container
                    justifyItems={'center'}
                    textAlign={'center'}
                    marginTop={10}
                  /* display={stepQuiz === 0 ? 'block' : 'none'} */
                  >
                    <Box sx={{ width: '100%' }}>
                      <Typography variant='h6' color={'#8E8E8E'}>
                        Give a name for your activity.
                        <br /> You can prefer to choose topic of the activity as name.
                      </Typography>
                      <TextField
                        id='outlined-basic'
                        label='Enter a name'
                        placeholder='Min 2 - Max 24 character'
                        size='small'
                        fullWidth
                        style={{ marginTop: '20px', width: '300px' }}
                        required
                        value={createTest.title}
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          const updatedTitle =
                            e.target.value/* .charAt(0).toUpperCase() + e.target.value.slice(1) */
                          if (updatedTitle.length <= 24) {
                            setCreateTest((prevCreateTest: any) => ({
                              ...prevCreateTest,
                              title: updatedTitle,
                            }))
                            setErrorTestName(false)
                            setHelperTextTestName('')
                            setNameCheck(false)
                            if (timer) clearTimeout(timer)
                            // 2 saniye sonra kontrolü tetikle
                            const newTimer = setTimeout(
                              () => checkActivityName(createTest.title, activityType),
                              2000
                            )
                            setTimer(newTimer)
                          }
                        }}
                        inputProps={{ maxLength: 24 }}
                        error={errorTestName || nameCheck}
                        helperText={
                          helperTextTestName || (nameCheck && 'Name exists. Choose another one')
                        }
                        InputProps={{
                          endAdornment: nameCheckLoading && (
                            <InputAdornment position='end'>
                              <CircularProgress size={20} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/*   <TextField
                        id='outlined-basic'
                        label='Enter a name'
                        placeholder='Min 2 - Max 24 character'
                        size='small'
                        fullWidth
                        style={{marginTop: '20px', width: '300px'}}
                        required
                        value={createTest.title}
                        onBlur={handleBlur}
                        onChange={(e: any) =>
                          e.target.value.length <= 24 &&
                          (setCreateTest((prevCreateTest: any) => ({
                            ...prevCreateTest,
                            title: e.target.value,
                          })),
                          setErrorTestName(false),
                          setHelperTextTestName(''))
                        }
                        inputProps={{maxLength: 24}}
                        error={errorTestName}
                        helperText={helperTextTestName}
                      /> */}
                    </Box>
                  </Grid>
                )}
                {
                  //activityName end
                }
                {
                  //activitySelectCategory start
                }
                {stepExam === 1 && (
                  <Grid
                    xs={12}
                    container
                    justifyItems={'center'}
                    textAlign={'center'}
                    marginTop={1}
                  /* display={stepGame === 1 ? '' : 'none'} */
                  >
                    {
                      //categories
                    }
                    <Grid xs={8}>
                      {loadingCategories && (
                        <Box
                          sx={{ width: '100%' }}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <CircularProgress size={'30px'} color='primary' />
                        </Box>
                      )}
                      {loadingCategories === false && (
                        <Box sx={{ width: '100%' }}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs1
                              value={tabValue}
                              onChange={handleChangeTabMenu}
                              aria-label='basic tabs example'
                              /* centered */
                              sx={{
                                marginTop: 2,
                                minHeight: '40px', // Tabs bileşeninin min yüksekliğini ayarlayın
                                '& .MuiTabs-flexContainer': {
                                  minHeight: '40px',
                                },
                                '& .MuiTab-root': {
                                  minHeight: '30px', // Her bir Tab bileşeninin min yüksekliğini ayarlayın
                                  padding: '3px 6px', // Daha dar bir görünüm için padding'i azaltın
                                  fontSize: '15px', // İsteğe bağlı olarak font boyutunu ayarlayın
                                },
                              }}
                            >
                              <Tab1
                                icon={tabValue === 0 && <CheckCircleIcon />}
                                iconPosition='start'
                                label={customerProfile?.customerName + ' Category '}
                                {...a11yProps(0)}
                              />
                              <Tab1
                                icon={tabValue === 1 && <CheckCircleIcon />}
                                iconPosition='start'
                                sx={{ marginLeft: '5px' }}
                                label=' Mobixa Category '
                                {...a11yProps(1)}
                              />
                            </Tabs1>
                          </Box>
                          <CustomTabPanel value={tabValue} index={0}>
                            <Box width={'100%'} marginTop={-2} marginBottom={1}>
                              {' '}
                              <Typography fontSize={14} fontWeight={'bold'}>
                                Select Max 10 categories
                              </Typography>
                            </Box>
                            <div
                              className=' d-flex card-body pt-0'
                              style={{
                                overflowY: 'auto',
                                overflowX: 'auto',
                                height: '500px',
                              }}
                            >
                              {customerCategory?.map((data: any) => (
                                <>
                                  <div>
                                    {
                                      //1.katman
                                    }
                                    <AccordionMui
                                      style={{
                                        marginRight: 20,
                                        borderRadius: '10px 10px 5px 5px',
                                        border: '1px solid #d5d5d5',
                                        minWidth: '300px',
                                      }}
                                      defaultExpanded={true}
                                    >
                                      <AccordionSummary
                                        /* expandIcon={
                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                } */
                                        style={{
                                          background: '#D5D5D5',
                                          borderRadius: '10px 10px 0px 0px',
                                          padding: '0px 10px 0px 0px',
                                          minHeight: '60px',
                                        }}
                                      >
                                        <div
                                          className='d-flex '
                                          style={{ alignItems: 'center', height: 20 }}
                                        >
                                          <div>
                                            <FormControlLabel
                                              control={
                                                <Android12Switch
                                                  defaultChecked
                                                  color='secondary'
                                                  size='medium'
                                                  value={data.categoryId}
                                                  disabled={
                                                    data.questionCount === 0
                                                      ? true
                                                      : createTest.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                        ? false
                                                        : createTest.categoryList.length <= 9
                                                          ? false
                                                          : true
                                                  }
                                                  checked={createTest.categoryList?.some(
                                                    (category: any) =>
                                                      category.categoryId === data.categoryId
                                                  )}
                                                  onClick={(e) => {
                                                    e.stopPropagation()

                                                    if (
                                                      !createTest.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                    ) {
                                                      if (createTest.categoryList.length <= 9) {
                                                        handleAddCategory(
                                                          data.categoryId,
                                                          data.name,
                                                          data.questionCount
                                                        )
                                                      }
                                                    } else {
                                                      handleDeleteCategory(data.categoryId)
                                                    }
                                                    e.preventDefault()
                                                  }}
                                                />
                                              }
                                              label=''
                                            />
                                            {/*  <Checkbox
                                              style={{accentColor: '#74992e'}}
                                              value={data.categoryId}
                                              color='secondary'
                                              size='small'
                                              disabled={
                                                data.questionCount === 0
                                                  ? true
                                                  : createTest.categoryList?.some(
                                                      (category: any) =>
                                                        category.categoryId === data.categoryId
                                                    )
                                                  ? false
                                                  : createTest.categoryList.length <= 4
                                                  ? false
                                                  : true
                                              }
                                              checked={createTest.categoryList?.some(
                                                (category: any) =>
                                                  category.categoryId === data.categoryId
                                              )}
                                              onClick={(e) => {
                                                e.stopPropagation()

                                                if (
                                                  !createTest.categoryList?.some(
                                                    (category: any) =>
                                                      category.categoryId === data.categoryId
                                                  )
                                                ) {
                                                  if (createTest.categoryList.length <= 4) {
                                                    handleAddCategory(
                                                      data.categoryId,
                                                      data.name,
                                                      data.questionCount
                                                    )
                                                  }
                                                } else {
                                                  handleDeleteCategory(data.categoryId)
                                                }
                                                e.preventDefault()
                                              }}
                                            /> */}
                                          </div>

                                          <div
                                            style={{
                                              fontWeight: 'bold',
                                              cursor: 'pointer',
                                              fontSize: '12px',
                                              marginLeft: '-4px',
                                              textAlign: "start"
                                            }}
                                          >
                                            {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                              data.name.slice(1)}
                                          </div>
                                          <span
                                            title='Number of awailable question'
                                            className='text-dark  ms-2'
                                            style={{
                                              fontSize: '10px',
                                              color: '#8e8e8e',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            ({data.questionCount})
                                          </span>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails style={{ padding: '3px' }}>
                                        {
                                          //2. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? (
                                            <>
                                              <AccordionMui
                                                style={{
                                                  marginTop: '5px',
                                                  marginBottom: '5px',
                                                  boxShadow: 'none',
                                                  borderRadius: '10px 10px 0px 0px',
                                                }}
                                                defaultExpanded={true}
                                              >
                                                <AccordionSummary
                                                  sx={{
                                                    margin: '0px!important',
                                                    '.MuiAccordionSummary-content': {
                                                      // AccordionSummary'nin içeriği için stil
                                                      marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                                    },
                                                    '&:hover': {
                                                      backgroundColor: '#e7e7e7', // Hover durumu için arka plan rengi
                                                    },
                                                  }}
                                                  style={{
                                                    borderRadius: '10px 10px 0px 0px',
                                                    padding: '0px 5px 0px 0px',
                                                    minHeight: '35px',
                                                    marginLeft: '0px!important',
                                                  }}
                                                >
                                                  <div
                                                    className='d-flex'
                                                    style={{
                                                      alignItems: 'center',
                                                      justifyItems: 'start',
                                                      height: 20,
                                                    }}
                                                  /*   onMouseEnter={(e: any) => {
                                                    setHover(child.categoryId)
                                                  }}
                                                  onMouseLeave={(e: any) => {
                                                    setHover('')
                                                  }} */
                                                  >
                                                    <div>
                                                      <FormControlLabel
                                                        control={
                                                          <Android12Switch
                                                            defaultChecked
                                                            color='secondary'
                                                            size='medium'
                                                            value={child.categoryId}
                                                            disabled={
                                                              child.questionCount === 0
                                                                ? true
                                                                : createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                                  ? false
                                                                  : createTest.categoryList.length <=
                                                                    9
                                                                    ? false
                                                                    : true
                                                            }
                                                            checked={createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )}
                                                            onClick={(e) => {
                                                              if (
                                                                !createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                              ) {
                                                                if (
                                                                  createTest.categoryList.length <=
                                                                  9
                                                                ) {
                                                                  handleAddCategory(
                                                                    child.categoryId,
                                                                    child.name,
                                                                    child.questionCount
                                                                  )
                                                                }
                                                              } else {
                                                                handleDeleteCategory(
                                                                  child.categoryId
                                                                )
                                                              }
                                                              e.stopPropagation()
                                                            }}
                                                          />
                                                        }
                                                        label=''
                                                      />
                                                      {/*  <Checkbox
                                                        style={{accentColor: '#74992e'}}
                                                        value={child.categoryId}
                                                        color='secondary'
                                                        size='small'
                                                        disabled={
                                                          child.questionCount === 0
                                                            ? true
                                                            : createTest.categoryList?.some(
                                                                (category: any) =>
                                                                  category.categoryId ===
                                                                  child.categoryId
                                                              )
                                                            ? false
                                                            : createTest.categoryList.length <= 4
                                                            ? false
                                                            : true
                                                        }
                                                        checked={createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )}
                                                        onClick={(e) => {
                                                          if (
                                                            !createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                          ) {
                                                            if (
                                                              createTest.categoryList.length <= 4
                                                            ) {
                                                              handleAddCategory(
                                                                child.categoryId,
                                                                child.name,
                                                                child.questionCount
                                                              )
                                                            }
                                                          } else {
                                                            handleDeleteCategory(child.categoryId)
                                                          }
                                                          e.stopPropagation()
                                                        }}
                                                      /> */}
                                                    </div>
                                                    <span
                                                      className={'text-gray-800 d-flex'}
                                                      style={{
                                                        fontWeight: 'bold',
                                                        cursor: 'pointer',
                                                        fontSize: '12px',
                                                        marginLeft: '-4px',
                                                        textAlign: "start"
                                                      }}
                                                    >
                                                      {child.name.charAt(0).toUpperCase() +
                                                        child.name.slice(1)}
                                                    </span>
                                                    <span
                                                      title='Number of awailable question'
                                                      className='text-dark text-muted ms-2'
                                                      style={{
                                                        fontSize: '10px',
                                                        color: '#8e8e8e',
                                                        fontWeight: 'normal',
                                                      }}
                                                    >
                                                      ({child.questionCount})
                                                    </span>
                                                  </div>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ padding: '3px' }}>
                                                  {child?.children?.map((child: any) => (
                                                    <div
                                                      id={child.categoryId}
                                                      className='ms-0 mt-3 '
                                                    >
                                                      <div
                                                        className='d-flex div-element-class'
                                                        style={{
                                                          alignItems: 'center',
                                                          height: 35,
                                                          paddingLeft: 32,
                                                          /* background:
                                                            hover === child.categoryId
                                                              ? '#e7e7e7'
                                                              : '', */
                                                        }}
                                                      /*  onMouseEnter={(e: any) => {
                                                        setHover(child.categoryId)
                                                      }}
                                                      onMouseLeave={(e: any) => {
                                                        setHover('')
                                                      }} */
                                                      >
                                                        <div>
                                                          <FormControlLabel
                                                            control={
                                                              <Android12Switch
                                                                defaultChecked
                                                                color='secondary'
                                                                size='medium'
                                                                value={child.categoryId}
                                                                disabled={
                                                                  child.questionCount === 0
                                                                    ? true
                                                                    : createTest.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                      ? false
                                                                      : createTest.categoryList.length <= 9
                                                                        ? false
                                                                        : true
                                                                }
                                                                checked={createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )}
                                                                onClick={(e) => {
                                                                  if (
                                                                    !createTest.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                  ) {
                                                                    if (
                                                                      createTest.categoryList
                                                                        .length <= 9
                                                                    ) {
                                                                      handleAddCategory(
                                                                        child.categoryId,
                                                                        child.name,
                                                                        child.questionCount
                                                                      )
                                                                    }
                                                                  } else {
                                                                    handleDeleteCategory(
                                                                      child.categoryId
                                                                    )
                                                                  }
                                                                  e.stopPropagation()
                                                                }}
                                                              />
                                                            }
                                                            label=''
                                                          />
                                                          {/* <Checkbox
                                                            style={{accentColor: '#74992e'}}
                                                            value={child.categoryId}
                                                            color='secondary'
                                                            size='small'
                                                            disabled={
                                                              child.questionCount === 0
                                                                ? true
                                                                : createTest.categoryList?.some(
                                                                    (category: any) =>
                                                                      category.categoryId ===
                                                                      child.categoryId
                                                                  )
                                                                ? false
                                                                : createTest.categoryList.length <=
                                                                  4
                                                                ? false
                                                                : true
                                                            }
                                                            checked={createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )}
                                                            onClick={(e) => {
                                                              if (
                                                                !createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                              ) {
                                                                if (
                                                                  createTest.categoryList.length <=
                                                                  4
                                                                ) {
                                                                  handleAddCategory(
                                                                    child.categoryId,
                                                                    child.name,
                                                                    child.questionCount
                                                                  )
                                                                }
                                                              } else {
                                                                handleDeleteCategory(
                                                                  child.categoryId
                                                                )
                                                              }
                                                              e.stopPropagation()
                                                            }}
                                                          /> */}
                                                        </div>
                                                        <a
                                                          className={'text-gray-800 d-flex '}
                                                          style={{
                                                            cursor: 'pointer',
                                                            fontSize: '12px',
                                                            alignItems: 'center',
                                                            maxWidth: '210px',
                                                            marginLeft: '-4px',
                                                            textAlign: "start"
                                                          }}
                                                        >
                                                          {child.name
                                                            .charAt(0)
                                                            .toUpperCase()
                                                            .replace('🅜', '') + child.name.slice(1)}
                                                        </a>

                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '20px',
                                                          }}
                                                        >
                                                          <span
                                                            title='Number of awailable question'
                                                            className='ms-2'
                                                            style={{
                                                              color: '#8e8e8e',
                                                              fontSize: '10px',
                                                            }}
                                                          >
                                                            {`(${child.questionCount})`}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </AccordionDetails>
                                              </AccordionMui>
                                            </>
                                          ) : null
                                        )}
                                        {
                                          // 3. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? null : (
                                            <div id={child.categoryId} className='ms-0 mt-3 '>
                                              <div
                                                className='d-flex border-1 div-element-class'
                                                style={{
                                                  alignItems: 'center',
                                                  height: 35,
                                                  paddingLeft: 4,
                                                  /* background:
                                                    hover === child.categoryId ? '#e7e7e7' : '', */
                                                }}
                                              /* onMouseEnter={(e: any) => {
                                                setHover(child.categoryId)
                                              }}
                                              onMouseLeave={(e: any) => {
                                                setHover('')
                                              }} */
                                              >
                                                <div>
                                                  <FormControlLabel
                                                    control={
                                                      <Android12Switch
                                                        defaultChecked
                                                        checked={createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )}
                                                        disabled={
                                                          child.questionCount === 0
                                                            ? true
                                                            : createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                              ? false
                                                              : createTest.categoryList.length <= 9
                                                                ? false
                                                                : true
                                                        }
                                                        value={child.categoryId}
                                                        color='secondary'
                                                        onClick={(e) => {
                                                          if (
                                                            !createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                          ) {
                                                            if (
                                                              createTest.categoryList.length <= 9
                                                            ) {
                                                              handleAddCategory(
                                                                child.categoryId,
                                                                child.name,
                                                                child.questionCount
                                                              )
                                                            }
                                                          } else {
                                                            handleDeleteCategory(child.categoryId)
                                                          }
                                                          e.stopPropagation()
                                                        }}
                                                        size='medium'
                                                      />
                                                    }
                                                    label=''
                                                  />
                                                  {/* <Checkbox
                                                    style={{accentColor: '#74992e'}}
                                                    value={child.categoryId}
                                                    color='secondary'
                                                    size='small'
                                                    disabled={
                                                      child.questionCount === 0
                                                        ? true
                                                        : createTest.categoryList?.some(
                                                            (category: any) =>
                                                              category.categoryId ===
                                                              child.categoryId
                                                          )
                                                        ? false
                                                        : createTest.categoryList.length <= 4
                                                        ? false
                                                        : true
                                                    }
                                                    checked={createTest.categoryList?.some(
                                                      (category: any) =>
                                                        category.categoryId === child.categoryId
                                                    )}
                                                    onClick={(e) => {
                                                      if (
                                                        !createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )
                                                      ) {
                                                        if (createTest.categoryList.length <= 4) {
                                                          handleAddCategory(
                                                            child.categoryId,
                                                            child.name,
                                                            child.questionCount
                                                          )
                                                        }
                                                      } else {
                                                        handleDeleteCategory(child.categoryId)
                                                      }
                                                      e.stopPropagation()
                                                    }}
                                                  /> */}
                                                </div>
                                                <a
                                                  className={'text-gray-800 d-flex'}
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: '12px',
                                                    maxWidth: '210px',
                                                    marginLeft: '-4px',
                                                    textAlign: "start"
                                                  }}
                                                >
                                                  {child.name.charAt(0).toUpperCase() +
                                                    child.name.slice(1)}
                                                </a>

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '20px',
                                                  }}
                                                >
                                                  <span
                                                    title='Number of awailable question'
                                                    className='ms-2'
                                                    style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                  >
                                                    {`(${child.questionCount})`}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </AccordionDetails>
                                    </AccordionMui>
                                  </div>
                                </>
                              ))}
                            </div>
                          </CustomTabPanel>
                          <CustomTabPanel value={tabValue} index={1}>
                            <Box width={'100%'} marginTop={-2} marginBottom={1}>
                              {' '}
                              <Typography fontSize={14} fontWeight={'bold'}>
                                Select Max 10 categories
                              </Typography>
                            </Box>
                            <div
                              className=' d-flex card-body pt-0'
                              style={{
                                overflowY: 'scroll',
                                overflowX: 'scroll',
                                minHeight: '500px',
                                height: '500px',
                              }}
                            >
                              {mobixaCategory?.map((data: any) => (
                                <>
                                  <div>
                                    {
                                      //1.katman
                                    }
                                    <AccordionMui
                                      style={{
                                        marginRight: 20,
                                        borderRadius: '10px 10px 5px 5px',
                                        border: '1px solid #d5d5d5',
                                        minWidth: '300px',
                                      }}
                                      defaultExpanded={true}
                                    >
                                      <AccordionSummary
                                        /* expandIcon={
                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                } */
                                        style={{
                                          background: '#D5D5D5',
                                          borderRadius: '10px 10px 0px 0px',
                                          padding: '0px 10px 0px 0px',
                                          minHeight: '60px',
                                        }}
                                      >
                                        <div
                                          className='d-flex '
                                          style={{ alignItems: 'center', height: 20 }}
                                        >
                                          <div>
                                            <FormControlLabel
                                              control={
                                                <Android12Switch
                                                  defaultChecked
                                                  color='secondary'
                                                  size='medium'
                                                  value={data.categoryId}
                                                  disabled={
                                                    data.questionCount === 0
                                                      ? true
                                                      : createTest.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                        ? false
                                                        : createTest.categoryList.length <= 9
                                                          ? false
                                                          : true
                                                  }
                                                  checked={createTest.categoryList?.some(
                                                    (category: any) =>
                                                      category.categoryId === data.categoryId
                                                  )}
                                                  onClick={(e) => {
                                                    e.stopPropagation()

                                                    if (
                                                      !createTest.categoryList?.some(
                                                        (category: any) =>
                                                          category.categoryId === data.categoryId
                                                      )
                                                    ) {
                                                      if (createTest.categoryList.length <= 9) {
                                                        handleAddCategory(
                                                          data.categoryId,
                                                          data.name,
                                                          data.questionCount
                                                        )
                                                      }
                                                    } else {
                                                      handleDeleteCategory(data.categoryId)
                                                    }
                                                    e.preventDefault()
                                                  }}
                                                />
                                              }
                                              label=''
                                            />
                                            {/*  <Checkbox
                                              style={{accentColor: '#74992e'}}
                                              value={data.categoryId}
                                              color='secondary'
                                              size='small'
                                              disabled={
                                                data.questionCount === 0
                                                  ? true
                                                  : createTest.categoryList?.some(
                                                      (category: any) =>
                                                        category.categoryId === data.categoryId
                                                    )
                                                  ? false
                                                  : createTest.categoryList.length <= 4
                                                  ? false
                                                  : true
                                              }
                                              checked={createTest.categoryList?.some(
                                                (category: any) =>
                                                  category.categoryId === data.categoryId
                                              )}
                                              onClick={(e) => {
                                                e.stopPropagation()

                                                if (
                                                  !createTest.categoryList?.some(
                                                    (category: any) =>
                                                      category.categoryId === data.categoryId
                                                  )
                                                ) {
                                                  if (createTest.categoryList.length <= 4) {
                                                    handleAddCategory(
                                                      data.categoryId,
                                                      data.name,
                                                      data.questionCount
                                                    )
                                                  }
                                                } else {
                                                  handleDeleteCategory(data.categoryId)
                                                }
                                                e.preventDefault()
                                              }}
                                            /> */}
                                          </div>

                                          <div
                                            style={{
                                              fontWeight: 'bold',
                                              cursor: 'pointer',
                                              fontSize: '12px',
                                              marginLeft: '-4px',
                                              textAlign: "start"
                                            }}
                                          >
                                            {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                              data.name.slice(1)}
                                          </div>
                                          <span
                                            title='Number of awailable question'
                                            className='text-dark  ms-2'
                                            style={{
                                              fontSize: '10px',
                                              color: '#8e8e8e',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            ({data.questionCount})
                                          </span>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails style={{ padding: '3px' }}>
                                        {
                                          //2. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? (
                                            <>
                                              <AccordionMui
                                                style={{
                                                  marginTop: '5px',
                                                  marginBottom: '5px',
                                                  boxShadow: 'none',
                                                  borderRadius: '10px 10px 0px 0px',
                                                }}
                                                defaultExpanded={true}
                                              >
                                                <AccordionSummary
                                                  sx={{
                                                    margin: '0px!important',
                                                    '.MuiAccordionSummary-content': {
                                                      // AccordionSummary'nin içeriği için stil
                                                      marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                                    },
                                                    '&:hover': {
                                                      backgroundColor: '#e7e7e7', // Hover durumu için arka plan rengi
                                                    },
                                                  }}
                                                  style={{
                                                    borderRadius: '10px 10px 0px 0px',
                                                    padding: '0px 5px 0px 0px',
                                                    minHeight: '35px',
                                                    marginLeft: '0px!important',
                                                  }}
                                                >
                                                  <div
                                                    className='d-flex'
                                                    style={{
                                                      alignItems: 'center',
                                                      justifyItems: 'start',
                                                      height: 20,
                                                    }}
                                                  >
                                                    <div>
                                                      <FormControlLabel
                                                        control={
                                                          <Android12Switch
                                                            defaultChecked
                                                            color='secondary'
                                                            size='medium'
                                                            value={child.categoryId}
                                                            disabled={
                                                              child.questionCount === 0
                                                                ? true
                                                                : createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                                  ? false
                                                                  : createTest.categoryList.length <= 9
                                                                    ? false
                                                                    : true
                                                            }
                                                            checked={createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )}
                                                            onClick={(e) => {
                                                              if (
                                                                !createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                              ) {
                                                                if (
                                                                  createTest.categoryList.length <= 9
                                                                ) {
                                                                  handleAddCategory(
                                                                    child.categoryId,
                                                                    child.name,
                                                                    child.questionCount
                                                                  )
                                                                }
                                                              } else {
                                                                handleDeleteCategory(
                                                                  child.categoryId
                                                                )
                                                              }
                                                              e.stopPropagation()
                                                            }}
                                                          />
                                                        }
                                                        label=''
                                                      />
                                                      {/*  <Checkbox
                                                        style={{accentColor: '#74992e'}}
                                                        value={child.categoryId}
                                                        color='secondary'
                                                        size='small'
                                                        disabled={
                                                          child.questionCount === 0
                                                            ? true
                                                            : createTest.categoryList?.some(
                                                                (category: any) =>
                                                                  category.categoryId ===
                                                                  child.categoryId
                                                              )
                                                            ? false
                                                            : createTest.categoryList.length <= 4
                                                            ? false
                                                            : true
                                                        }
                                                        checked={createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )}
                                                        onClick={(e) => {
                                                          if (
                                                            !createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                          ) {
                                                            if (
                                                              createTest.categoryList.length <= 4
                                                            ) {
                                                              handleAddCategory(
                                                                child.categoryId,
                                                                child.name,
                                                                child.questionCount
                                                              )
                                                            }
                                                          } else {
                                                            handleDeleteCategory(child.categoryId)
                                                          }
                                                          e.stopPropagation()
                                                        }}
                                                      /> */}
                                                    </div>
                                                    <span
                                                      className={'text-gray-800 d-flex'}
                                                      style={{
                                                        fontWeight: 'bold',
                                                        cursor: 'pointer',
                                                        fontSize: '12px',
                                                        marginLeft: '-4px',
                                                        textAlign: "start"
                                                      }}
                                                    >
                                                      {child.name.charAt(0).toUpperCase() +
                                                        child.name.slice(1)}
                                                    </span>
                                                    <span
                                                      title='Number of awailable question'
                                                      className='text-dark text-muted ms-2'
                                                      style={{
                                                        fontSize: '10px',
                                                        color: '#8e8e8e',
                                                        fontWeight: 'normal',
                                                      }}
                                                    >
                                                      ({child.questionCount})
                                                    </span>
                                                  </div>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ padding: '3px' }}>
                                                  {child?.children?.map((child: any) => (
                                                    <div
                                                      id={child.categoryId}
                                                      className='ms-0 mt-3 '
                                                    >
                                                      <div
                                                        className='d-flex div-element-class'
                                                        style={{
                                                          alignItems: 'center',
                                                          height: 35,
                                                          paddingLeft: 32,
                                                        }}
                                                      >
                                                        <div>
                                                          <FormControlLabel
                                                            control={
                                                              <Android12Switch
                                                                defaultChecked
                                                                color='secondary'
                                                                size='medium'
                                                                value={child.categoryId}
                                                                disabled={
                                                                  child.questionCount === 0
                                                                    ? true
                                                                    : createTest.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                      ? false
                                                                      : createTest.categoryList.length <= 9
                                                                        ? false
                                                                        : true
                                                                }
                                                                checked={createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )}
                                                                onClick={(e) => {
                                                                  if (
                                                                    !createTest.categoryList?.some(
                                                                      (category: any) =>
                                                                        category.categoryId ===
                                                                        child.categoryId
                                                                    )
                                                                  ) {
                                                                    if (
                                                                      createTest.categoryList
                                                                        .length <= 9
                                                                    ) {
                                                                      handleAddCategory(
                                                                        child.categoryId,
                                                                        child.name,
                                                                        child.questionCount
                                                                      )
                                                                    }
                                                                  } else {
                                                                    handleDeleteCategory(
                                                                      child.categoryId
                                                                    )
                                                                  }
                                                                  e.stopPropagation()
                                                                }}
                                                              />
                                                            }
                                                            label=''
                                                          />
                                                          {/* <Checkbox
                                                            style={{accentColor: '#74992e'}}
                                                            value={child.categoryId}
                                                            color='secondary'
                                                            size='small'
                                                            disabled={
                                                              child.questionCount === 0
                                                                ? true
                                                                : createTest.categoryList?.some(
                                                                    (category: any) =>
                                                                      category.categoryId ===
                                                                      child.categoryId
                                                                  )
                                                                ? false
                                                                : createTest.categoryList.length <=
                                                                  4
                                                                ? false
                                                                : true
                                                            }
                                                            checked={createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )}
                                                            onClick={(e) => {
                                                              if (
                                                                !createTest.categoryList?.some(
                                                                  (category: any) =>
                                                                    category.categoryId ===
                                                                    child.categoryId
                                                                )
                                                              ) {
                                                                if (
                                                                  createTest.categoryList.length <=
                                                                  4
                                                                ) {
                                                                  handleAddCategory(
                                                                    child.categoryId,
                                                                    child.name,
                                                                    child.questionCount
                                                                  )
                                                                }
                                                              } else {
                                                                handleDeleteCategory(
                                                                  child.categoryId
                                                                )
                                                              }
                                                              e.stopPropagation()
                                                            }}
                                                          /> */}
                                                        </div>
                                                        <a
                                                          className={'text-gray-800 d-flex '}
                                                          style={{
                                                            cursor: 'pointer',
                                                            fontSize: '12px',
                                                            alignItems: 'center',
                                                            maxWidth: '210px',
                                                            marginLeft: '-4px',
                                                            textAlign: "start"
                                                          }}
                                                        >
                                                          {child.name
                                                            .charAt(0)
                                                            .toUpperCase()
                                                            .replace('🅜', '') + child.name.slice(1)}
                                                        </a>

                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '20px',
                                                          }}
                                                        >
                                                          <span
                                                            title='Number of awailable question'
                                                            className='ms-2'
                                                            style={{
                                                              color: '#8e8e8e',
                                                              fontSize: '10px',
                                                            }}
                                                          >
                                                            {`(${child.questionCount})`}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </AccordionDetails>
                                              </AccordionMui>
                                            </>
                                          ) : null
                                        )}
                                        {
                                          // 3. katman
                                        }

                                        {data?.children?.map((child: any) =>
                                          child.children.length > 0 ? null : (
                                            <div id={child.categoryId} className='ms-0 mt-3 '>
                                              <div
                                                className='d-flex border-1 div-element-class'
                                                style={{
                                                  alignItems: 'center',
                                                  height: 35,
                                                  paddingLeft: 4,
                                                }}
                                              >
                                                <div>
                                                  <FormControlLabel
                                                    control={
                                                      <Android12Switch
                                                        defaultChecked
                                                        checked={createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )}
                                                        disabled={
                                                          child.questionCount === 0
                                                            ? true
                                                            : createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                              ? false
                                                              : createTest.categoryList.length <= 9
                                                                ? false
                                                                : true
                                                        }
                                                        value={child.categoryId}
                                                        color='secondary'
                                                        onClick={(e) => {
                                                          if (
                                                            !createTest.categoryList?.some(
                                                              (category: any) =>
                                                                category.categoryId ===
                                                                child.categoryId
                                                            )
                                                          ) {
                                                            if (
                                                              createTest.categoryList.length <= 9
                                                            ) {
                                                              handleAddCategory(
                                                                child.categoryId,
                                                                child.name,
                                                                child.questionCount
                                                              )
                                                            }
                                                          } else {
                                                            handleDeleteCategory(child.categoryId)
                                                          }
                                                          e.stopPropagation()
                                                        }}
                                                        size='medium'
                                                      />
                                                    }
                                                    label=''
                                                  />
                                                  {/* <Checkbox
                                                    style={{accentColor: '#74992e'}}
                                                    value={child.categoryId}
                                                    color='secondary'
                                                    size='small'
                                                    disabled={
                                                      child.questionCount === 0
                                                        ? true
                                                        : createTest.categoryList?.some(
                                                            (category: any) =>
                                                              category.categoryId ===
                                                              child.categoryId
                                                          )
                                                        ? false
                                                        : createTest.categoryList.length <= 4
                                                        ? false
                                                        : true
                                                    }
                                                    checked={createTest.categoryList?.some(
                                                      (category: any) =>
                                                        category.categoryId === child.categoryId
                                                    )}
                                                    onClick={(e) => {
                                                      if (
                                                        !createTest.categoryList?.some(
                                                          (category: any) =>
                                                            category.categoryId === child.categoryId
                                                        )
                                                      ) {
                                                        if (createTest.categoryList.length <= 4) {
                                                          handleAddCategory(
                                                            child.categoryId,
                                                            child.name,
                                                            child.questionCount
                                                          )
                                                        }
                                                      } else {
                                                        handleDeleteCategory(child.categoryId)
                                                      }
                                                      e.stopPropagation()
                                                    }}
                                                  /> */}
                                                </div>
                                                <a
                                                  className={'text-gray-800 d-flex'}
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: '12px',
                                                    maxWidth: '210px',
                                                    marginLeft: '-4px',
                                                    textAlign: "start"
                                                  }}
                                                >
                                                  {child.name.charAt(0).toUpperCase() +
                                                    child.name.slice(1)}
                                                </a>

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '20px',
                                                  }}
                                                >
                                                  <span
                                                    title='Number of awailable question'
                                                    className='ms-2'
                                                    style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                  >
                                                    {`(${child.questionCount})`}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </AccordionDetails>
                                    </AccordionMui>
                                  </div>
                                </>
                              ))}
                            </div>
                          </CustomTabPanel>
                        </Box>
                      )}
                    </Grid>
                    {
                      //selectedCategory
                    }
                    {createTest.categoryList.length > 0 && (
                      <Grid
                        xs={4}
                        container
                        marginTop={3}
                        justifyContent={'center'}
                        alignContent={'center'}
                      >
                        <Box>
                          <Typography color={'#8E8E8E'}>Selected Categories</Typography>
                          <Paper elevation={2} style={{ height: 'auto', maxHeight: 360, overflowX: "scroll", width: '330px' }}>
                            {createTest.categoryList?.map((category: any, index: number) => (
                              <div style={{ display: 'flex' }}>
                                <Box
                                  width={'330px'}
                                  height={'50px'}
                                  padding={1}
                                  margin={1}
                                  marginLeft={'10px'}
                                  sx={{
                                    background: mobixaCategory.some((item: any) => {
                                      if (item.categoryId === category.categoryId) {
                                        return true
                                      } else {
                                        return item.children.some((child: any) => {
                                          if (child.categoryId === category.categoryId) {
                                            return true
                                          } else {
                                            return child.children.some((grandchild: any) => {
                                              return grandchild.categoryId === category.categoryId
                                            })
                                          }
                                        })
                                      }
                                    })
                                      ? '#F7D9E4'
                                      : '#EEF6FF',
                                    borderRadius: '10px',
                                  }}
                                  display={'flex'}
                                  justifyContent={'space-between'}
                                  alignItems={'center'}
                                >
                                  <Typography fontSize={'14px'}>{index + 1}.</Typography>
                                  <Typography fontSize={'14px'} width={'100%'}>
                                    {category.categoryName}
                                  </Typography>
                                  <Box
                                    width={'80px'}
                                    height={'40px'}
                                    marginLeft={'10px'}
                                    sx={{
                                      background:
                                        selectCategoryDataGrid === category.categoryId
                                          ? '#6eb0ff'
                                          : 'white',
                                      borderRadius: '10px',
                                    }}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                  >
                                    <Button
                                      size='small'
                                      onClick={() => (
                                        handleOpenDataGridModal(),
                                        setSelectCategoryDataGrid(category.categoryId),
                                        category.questionIdList.length > 0
                                          ? setSelectedQuestion(category.questionIdList)
                                          : setSelectedQuestion([])
                                      )}
                                      style={{
                                        color:
                                          selectCategoryDataGrid === category.categoryId
                                            ? 'white'
                                            : '',
                                      }}
                                    >
                                      {category.questionIdList?.length === 0
                                        ? 'Select Questions'
                                        : `Selected (${category.questionIdList?.length})`}
                                    </Button>
                                  </Box>
                                </Box>
                                <>
                                  <IconButton
                                    color='error'
                                    onClick={() => (
                                      handleDeleteCategory(category.categoryId),
                                      setSelectCategoryDataGrid(0)
                                    )}
                                  >
                                    <DeleteForeverRoundedIcon />
                                  </IconButton>
                                </>
                              </div>
                            ))}
                          </Paper>
                          <Box marginTop={1} sx={{ display: 'flex', direction: 'column' }}>
                            <Divider variant='fullWidth' />
                            <Box marginTop={1} display={'flex'} alignItems={'center'}>
                              <Typography>Total nr. of questions to be asked</Typography>
                              <Typography fontWeight={'bold'} marginLeft={5}>
                                {createTest.categoryList.reduce((total: number, category: any) => {
                                  return total + category.questionIdList.length
                                }, 0)}
                              </Typography>
                              {createTest.categoryList.some(
                                (category: any) => category.questionIdList.length < 1
                              ) ? null : (
                                <Tooltip arrow title='List' style={{ marginLeft: 30 }}>
                                  <IconButton
                                    size='small'
                                    onClick={() => setFinalTestModalOpen(true)}
                                  >
                                    <ListIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {
                      //#region DataGrid start
                    }
                    <Dialog
                      fullWidth
                      maxWidth='md'
                      open={dataGridModalOpen}
                      onClose={handleCloseDataGridModal}
                      aria-labelledby='responsive-dialog-title'
                    >
                      <DialogTitle id='responsive-dialog-title' display={'flex'}>
                        <Typography fontSize={'26px'} fontWeight={'bold'} marginRight={1}>
                          Select Questions
                        </Typography>{' '}
                        <Typography fontSize={'26px'}>
                          {' - ' +
                            createTest.categoryList?.find(
                              (item: any) => item.categoryId === selectCategoryDataGrid
                            )?.categoryName}
                        </Typography>
                      </DialogTitle>
                      <DialogContent dividers sx={{ paddingLeft: 0, paddingRight: 0 }}>
                        {
                          //DataGrid start
                        }
                        {selectCategoryDataGrid !== 0 && (
                          <Box sx={{ height: 500, width: '98%', marginLeft: 1 }}>
                            {/*  <ReloadableDataGrid rows={rows} columns={columns} /> */}
                            <StyledDataGrid
                              rows={rows || []}
                              columns={columns || []}
                              rowHeight={50}
                              checkboxSelection
                              keepNonExistentRowsSelected
                              disableMultipleRowSelection={false}
                              onCellClick={handleCellClick}
                              rowSelectionModel={selectedQuestion}
                              getRowId={(row) => row.questionId}
                              disableRowSelectionOnClick
                              sortingMode='server'
                              onSortModelChange={handleSortModelChange}
                              onRowSelectionModelChange={handleSelectionModelChange}
                              columnVisibilityModel={columnVisibilityModel}
                              onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                              filterMode='server'
                              onFilterModelChange={onFilterChange}
                              rowCount={rowCount}
                              loading={isLoadingData}
                              paginationModel={paginationModel}
                              onPaginationModelChange={setPaginationModel}
                              paginationMode='server'
                              pagination={true}
                              getRowClassName={(params) =>
                                `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
                              }
                              slots={{
                                /*   toolbar: CustomToolbar, */
                                pagination: CustomPagination,
                              }}
                              sx={{
                                boxShadow: 3,
                                '& .MuiDataGrid-cell:hover': {
                                  cursor: 'pointer',
                                },
                                '&	.MuiDataGrid-columnHeaderTitle': {
                                  fontWeight: 'bold',
                                  color: '#A1A5B7',
                                  fontSize: 17,
                                },
                                '& .MuiDataGrid-cell:focus': {
                                  outline: 'none',
                                },
                              }}
                            />
                          </Box>
                        )}

                        {
                          //dataGrid end
                        }
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant='contained'
                          color='inherit'
                          onClick={handleCloseDataGridModal}
                        >
                          DISCARD
                        </Button>
                        <Button
                          variant='contained'
                          color='primary'
                          disabled={selectedQuestion.length === 0}
                          onClick={() => (
                            handleAddQuestion(
                              createTest.categoryList?.find(
                                (item: any) => item.categoryId === selectCategoryDataGrid
                              )?.categoryId,
                              selectedQuestion
                            ),
                            handleAddQuestionDetails(
                              createTest.categoryList?.find(
                                (item: any) => item.categoryId === selectCategoryDataGrid
                              )?.categoryName,
                              createTest.categoryList?.find(
                                (item: any) => item.categoryId === selectCategoryDataGrid
                              )?.categoryId,
                              rows.filter((row: any) => selectedQuestion.includes(row.questionId)),
                              createTest.categoryList?.find(
                                (item: any) => item.categoryId === selectCategoryDataGrid
                              )?.mediaUrl,
                              createTest.categoryList?.find(
                                (item: any) => item.categoryId === selectCategoryDataGrid
                              )?.mediaType
                            ),
                            handleCloseDataGridModal()
                          )}
                          autoFocus
                        >
                          SAVE
                        </Button>
                      </DialogActions>
                    </Dialog>
                    {
                      //#endregion DataGrid end
                    }
                    {
                      //#region Final Test  start
                    }

                    {
                      //#endregion
                    }
                  </Grid>
                )}
                {
                  //activitySelectCategory end
                }
                {
                  //exam duration start
                }
                {stepExam === 2 && (
                  <Grid
                    xs={12}
                    container
                    justifyContent={'center'}
                    textAlign={'center'}
                    alignContent={'center'}
                    height={'400px'}
                    marginTop={1}
                  /* display={stepGame === 1 ? '' : 'none'} */
                  >
                    {loadingSuggestion ? (
                      <CircularProgress size={'17px'} style={{ marginLeft: '5px' }} color='inherit' />
                    ) : (
                      <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                        <Typography color={'#3F4254'} fontWeight={'bold'} fontSize={'20px'}>
                          Time Limit
                        </Typography>
                        <Typography
                          color={'#3F4254'}
                          fontSize={'16px'}
                          marginTop={2}
                          display={'flex'}
                        >
                          Mobixa suggests{' '}
                          <Typography
                            color={'#3F4254'}
                            fontWeight={createTest.timeLimit === false ? '' : 'bold'}
                            fontSize={'16px'}
                            marginLeft={1}
                            marginRight={1}
                          >
                            {typeof timeSuggestion === 'object' ? timeSuggestion.suggestedTime : timeSuggestion} Minutes
                          </Typography>{' '}
                          for this test.
                        </Typography>
                        <Box display={'flex'} alignItems={'center'} marginTop={2}>
                          <Checkbox
                            onClick={() => (
                              setTimeLimitSuggestion(!timeLimitSuggestion),
                              setCreateTest((prevCreateTest: any) => ({
                                ...prevCreateTest,
                                timeLimit: timeSuggestion,
                              }))
                            )}
                            checked={timeLimitSuggestion}
                            color='success'
                          />
                          <Typography color={'#3F4254'} fontSize={'16px'}>
                            Use the suggestion
                          </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} marginTop={2}>
                          {timeLimitSuggestion === false && (
                            <TextField
                              label='Minutes'
                              placeholder='mmm'
                              size='small'
                              value={createTest.timeLimit}
                              onChange={(e: any) => {
                                if (e.target.value <= 9999) {
                                  setTimeLimitSuggestionHelperText(false)
                                  setCreateTest((prevCreateTest: any) => ({
                                    ...prevCreateTest,
                                    timeLimit: e.target.value,
                                  }))
                                } else {
                                  setTimeLimitSuggestionHelperText(true)
                                }
                              }}
                              helperText={
                                timeLimitSuggestionHelperText ? 'Maximum 9999 minutes' : ''
                              }
                              error={timeLimitSuggestionHelperText}
                              type='number'
                              sx={{ width: '100px', marginRight: '2px' }}
                              inputProps={{ max: 1440, min: 0 }}
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                  </Grid>
                )}
                {
                  //exam duration end
                }
                {
                  //exam preview start
                }
                {stepExam === 3 && (
                  <Grid xs={12} marginTop={1} marginBottom={1} container justifyContent={'center'}>
                    <Grid
                      xs={8}
                      container
                      justifyContent={'center'}
                      alignItems={'center'}
                      marginTop={2}
                    >
                      <Box
                        width={600}
                        height={200}
                        display={'flex'}
                        marginLeft={4}
                        alignItems={'center'}
                      >
                        <Grid container>
                          <Grid
                            xs={6}
                            display={'flex'}
                            paddingRight={3}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Exam Name
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6}>
                            <Typography variant='h6' color={'#6C757D'}>
                              {createTest?.title}
                            </Typography>
                          </Grid>

                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Answer Feedback
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2} display={'flex'} justifyContent={'start'}>
                            <Typography variant='h6' color={'#8E8E8E'}>
                              No
                            </Typography>
                          </Grid>
                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Question Time
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2} display={'flex'} justifyContent={'start'}>
                            <Typography variant='h6' color={'#8E8E8E'}>
                              No
                            </Typography>
                          </Grid>
                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Time Limit
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2} display={'flex'} justifyContent={'start'}>
                            <Typography variant='h6' color={'#8E8E8E'}>
                              {createTest?.timeLimit + ' min'}
                            </Typography>
                          </Grid>
                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Nr. Of Questions
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2} display={'flex'} justifyContent={'start'}>
                            <Typography variant='h6' color={'#6C757D'}>
                              {createTest?.categoryList?.reduce((total: number, category: any) => {
                                return total + category.selectedQuestionCount
                              }, 0)}
                            </Typography>
                          </Grid>
                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Question List
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2} paddingRight={3}>
                            {createTest?.categoryList.length > 0 && (
                              <Box display={'flex'} justifyContent={'start'}>
                                {createTest.categoryList.some(
                                  (category: any) => category.questionIdList.length < 1
                                ) ? null : (
                                  <Tooltip arrow title='List' /* style={{marginLeft: 30}} */>
                                    <IconButton
                                      size='small'
                                      onClick={() => setFinalTestModalOpen(true)}
                                    >
                                      <ListIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                            )}
                          </Grid>
                          <Grid
                            xs={6}
                            marginTop={2}
                            paddingRight={3}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Typography variant='h6' color={'black'}>
                              Categories
                            </Typography>
                            <Typography variant='h6' color={'black'}>
                              :
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={2}>
                            {createTest?.categoryList?.map((category: any, index) => (
                              <Box key={index} display={'flex'} justifyContent={'space-between'}>
                                <Typography variant='h6' color={'#6C757D'}>
                                  {category.categoryName}
                                </Typography>
                                {/*  <Typography variant='h6' color={'#8E8E8E'}>
                                  {category.selectedQuestionCount}
                                </Typography> */}
                              </Box>
                            ))}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid xs={4} container justifyContent={'start'} marginTop={2}>
                      <Box padding={0.8} sx={{ borderRadius: '10px', background: 'black' }}>
                        <img
                          alt='phoneHeader'
                          src={toAbsoluteUrl('/media/stock/phoneHeader.png')}
                          width={'250px'}
                          height={'30px'}
                        />
                        <Card sx={{ width: 250, height: 400 }}>
                          <Box
                            height={'400px'}
                            width={250}
                            padding={1}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              background: 'black',
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                background: 'transparent',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <Avatar
                                src={`https://s3.mobixa.net/activityIcons/exam.png`}
                                variant='rounded'
                                sx={{ width: '128px', height: '128px' }}
                              />
                              <Typography color={'white'}>{createTest?.title}</Typography>
                            </div>
                          </Box>
                        </Card>
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {
                  //exam preview end
                }
              </Grid>
              {
                //footer
              }
              <Grid xs={12}>
                <Divider variant='middle' sx={{ borderTop: '1px solid black' }} />
                <Box
                  display={'flex'}
                  padding={0}
                  marginTop={'10px'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ width: '100%' }}
                >
                  <Button
                    variant='contained'
                    color='inherit'
                    sx={{ marginRight: '20px', marginLeft: '9%' }}
                    onClick={() => setDiscardWarnModal(true)}
                  >
                    DISCARD
                  </Button>
                  <div style={{ marginRight: '6%' }}>
                    <Button
                      variant='contained'
                      color='inherit'
                      sx={{ marginRight: '20px' }}
                      disabled={stepExam === 0 && editActivity !== 0 || usedJourney}
                      onClick={() =>
                        stepExam !== 0
                          ? setStepExam(stepExam - 1)
                          : editActivity === 0 && setActivityType(0)
                      }
                    >
                      BACK
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ marginRight: '40px' }}
                      disabled={
                        usedJourney
                          ? createTest?.timeLimit <= 0
                          : stepExam === 0
                          ? createTest?.title?.length < 2 || nameCheck
                          : stepExam === 1
                          ? createTest?.categoryList?.length < 1
                          : createTest?.timeLimit <= 0
                      }
                      onClick={() => {
                        if (stepExam < 3) {
                          setStepExam(stepExam + 1)
                        } else if (stepExam === 3) {
                          selectedActivity === 0 ? handleSaveCreateTest() : handleEditCreateTest()
                        }
                      }}
                    >
                      {selectedActivity === 0
                        ? stepExam === 3
                          ? 'SAVE'
                          : 'NEXT'
                        : stepExam === 3
                          ? 'EDIT'
                          : 'NEXT'}
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        {/*       <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions> */}
      </Dialog>

      {
        //#region Discard WarnModal
      }
      <Dialog
        open={discardWarnModal}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={'xs'}
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /* onClose={handleClickWarnModalClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Typography color='white' variant='h6'>
              You have unsaved changes.
              <br />
              Do you want to discard, Anyway?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: 'white' }}
            onClick={() =>
              activityType === 0
                ? (setDiscardWarnModal(false), setSavedChanges(false))
                : (setDiscardWarnModal(false),
                  handleClearLearningCard(),
                  handleClearGame(),
                  handleClearTest(),
                  setSelectedActivity(0))
            }
          >
            Yes
          </Button>
          <Button style={{ color: 'white' }} onClick={() => setDiscardWarnModal(false)}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
      {
        //#endregion
      }
      {
        //#region Discard WarnModal
      }
      <Dialog
        open={journeyWarnModal}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={'xs'}
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /* onClose={handleClickWarnModalClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Typography color='white' variant='h6'>
              This activity can NOT be edited because it is currently used in one or more journeys.{' '}
              <br /> Instead, you can duplicate and then edit the new one. <br /> Would you like me
              to duplicate the activity for you?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: 'white' }}
            onClick={() => HandleSaveDublicate()}
            disabled={duplicateLoading}
          >
            YES, DUPLICATE
          </Button>
          <Button style={{ color: 'white' }} onClick={
            () => {
              setJourneyWarnModal(false)
            }
          }>
            NO, JUST PREVIEW
          </Button>
        </DialogActions>
      </Dialog>
      {
        //#endregion
      }
      <Dialog
        fullWidth
        maxWidth='xs'
        open={finalTestModalOpen}
        onClose={() => setFinalTestModalOpen(false)}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title' display={'flex'}>
          <Typography fontSize={'26px'} fontWeight={'bold'} marginRight={1}>
            Question List
          </Typography>{' '}
          <Typography fontSize={'26px'}>{' - ' + 'Final Test'}</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ paddingLeft: 0, paddingRight: 0 }}>
          {
            loadingFinalTest ? <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}><CircularProgress size={26} /></Box>
              :
              selectQuestionDetails.map((question: any, index: number) => (
                <TableContainer
                  component={Paper}
                  style={{
                    marginTop: index === 0 ? 0 : 20,
                    width: '435px',
                    paddingLeft: '2px',
                  }}
                >
                  <Table sx={{ maxWidth: 435, paddingLeft: '2px' }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                          {question.categoryName}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {question.questionDetails.map((row: any) => (
                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            component='th'
                            scope='row'
                            style={{
                              padding: '5px',
                            }}
                          >
                            {
                              <div
                                style={{
                                  display: 'flex',
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                  alignItems: 'center',
                                }}
                              >
                                {getMedia(question)}
                                <Typography marginLeft={1}>{row.description}</Typography>
                              </div>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ))
          }

        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setFinalTestModalOpen(false)}
            autoFocus
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreateActivity
